import { extractData, http } from '@/http';
import type {
  ConverterFetchRequest,
  ConverterFetchResponse,
  ConverterItemCreateRequest,
  ConverterItemCreateResponse,
  ConverterItemFetchRequest,
  ConverterItemFetchResponse,
  ConverterItemPatchRequest,
  ConverterItemPatchResponse,
} from '@/features/Converter/types/api';

export const converters = (params?: ConverterFetchRequest): Promise<ConverterFetchResponse> =>
  http.get<ConverterFetchResponse>('/converters', { params }).then(extractData);

export const fetchConverter = (params?: ConverterItemFetchRequest): Promise<ConverterItemFetchResponse> =>
  http.get<ConverterItemFetchResponse>(`/converters/${params.id}/language/${params.languageId}`).then(extractData);

export const createConverter = (params?: ConverterItemCreateRequest): Promise<ConverterItemCreateResponse> =>
  http.post<ConverterItemCreateResponse>(`/converters`, params).then(extractData);

export const patchConverter = (params?: ConverterItemPatchRequest): Promise<ConverterItemPatchResponse> =>
  http
    .patch<ConverterItemPatchResponse>(`/converters/${params.id}/language/${params.languageId}`, params.data)
    .then(extractData);
