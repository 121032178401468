import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M4.646 10.128a.5.5 0 0 1 0-.706l.774-.776a.497.497 0 0 1 .704 0L12 14.536l5.876-5.89a.497.497 0 0 1 .704 0l.774.776a.5.5 0 0 1 0 .706L12 17.5z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }