import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M10.128 19.354a.5.5 0 0 1-.706 0l-.776-.774a.497.497 0 0 1 0-.704L14.536 12l-5.89-5.876a.497.497 0 0 1 0-.704l.776-.774a.5.5 0 0 1 .706 0L17.5 12z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }