<template>
  <div class="mb-4">
    <ConvertersTableHeader class="mt-2 mb-4" />
    <div class="relative overflow-x-auto">
      <div
        v-if="convertersStore.isConvertersListLoading"
        class="max-w-[100vw] min-h-[60vh] flex items-center justify-center"
      >
        <div class="h-10 w-10">
          <LoaderIcon class="w-10 h-10" />
        </div>
      </div>
      <div
        v-else
        :class="{
          'rounded-2xl': !isMobile,
        }"
        class="min-w-[926px] relative bg-imperium-bg-sub-base"
      >
        <Table :top-rounded="!isMobile">
          <TableHead>
            <ConverterHeader />
          </TableHead>
          <TableBody>
            <ConverterRow
              v-for="converter in convertersStore.state.converters"
              :key="converter.id"
              :converter="converter"
            />
          </TableBody>
        </Table>

        <div
          v-if="!paginationStore.state.total"
          class="w-full flex align-middle justify-center items-center min-h-[60vh] max-w-[100vw]"
        >
          <div class="font-semibold text-imperium-fg-strong">No converters found</div>
        </div>
        <div
          v-else
          class="py-3 p-4 bg-white rounded-b-2xl"
        >
          <Pagination
            :current-page="paginationStore.state.page"
            :is-mobile="isMobile || isTablet"
            :items-count="paginationStore.state.total || 0"
            :limit="paginationStore.state.limit"
            @change-page="paginationStore.changePage"
            @change-limit="paginationStore.changeLimit"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, inject, onMounted, watch } from 'vue';
import isEqual from 'lodash/isEqual';
import { Table, TableBody, TableHead } from '@/components/Table';
import ConverterRow from '@/features/Converter/components/ConverterTable/ConverterRow.vue';
import ConverterHeader from '@/features/Converter/components/ConverterTable/ConverterHeader.vue';
import Pagination from '@/components/Table/Pagination/Pagination.vue';
import { usePagination } from '@/features/Converter/stores/convertersPagination.store';
import { useConvertersFilters } from '@/features/Converter/stores/convertersFilter.store';
import { useConvertersPage } from '@/features/Converter/stores/converters.store';
import ConvertersTableHeader from '@/features/Converter/components/ConverterTable/ConvertersTableHeader.vue';
import LoaderIcon from '@/assets/icons/spinner.svg?component';

const isTablet = inject('isTablet');
const isMobile = inject('isMobile');

const convertersStore = useConvertersPage();
const paginationStore = usePagination();
const filtersStore = useConvertersFilters();

watch(
  () => [filtersStore.state, paginationStore.state],
  (newValue, oldValue) => {
    const isFiltersEqual = isEqual(newValue, oldValue);
    if (!isFiltersEqual) {
      convertersStore.fetchConvertersList({ paginationStore, filtersStore });
    }
  },
  { deep: true },
);

onMounted(() => convertersStore.fetchConvertersList({ paginationStore, filtersStore }));
</script>
