import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M6.436 12.215a.417.417 0 0 1-.583 0l-.549-.537a.417.417 0 0 1 0-.596l2.91-2.832a.82.82 0 0 1 1.143 0l1.856 1.818 2.351-2.283a.417.417 0 0 1 .583 0l.549.537a.417.417 0 0 1 0 .596l-2.91 2.832a.82.82 0 0 1-1.144 0L8.786 9.932z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M2.5 6.175c0-1.094 0-1.642.168-2.077a2.5 2.5 0 0 1 1.43-1.43c.435-.168.983-.168 2.077-.168h7.65c1.095 0 1.642 0 2.077.168a2.5 2.5 0 0 1 1.43 1.43c.168.435.168.983.168 2.077v7.65c0 1.095 0 1.642-.168 2.077a2.5 2.5 0 0 1-1.43 1.43c-.435.168-.982.168-2.077.168h-7.65c-1.094 0-1.642 0-2.077-.168a2.5 2.5 0 0 1-1.43-1.43c-.168-.435-.168-.982-.168-2.077zm3.675-2.008h7.65c.569 0 .917 0 1.179.017.222.015.29.036.297.039a.83.83 0 0 1 .476.476c-.003-.007.023.048.039.297.016.262.017.61.017 1.179v7.65c0 .569 0 .917-.017 1.179-.014.222-.036.29-.039.297a.83.83 0 0 1-.476.476s-.061.024-.297.039c-.262.016-.61.017-1.179.017h-7.65c-.569 0-.917 0-1.179-.017a1.3 1.3 0 0 1-.297-.039.83.83 0 0 1-.476-.476c.003.007-.023-.049-.039-.297-.017-.262-.017-.61-.017-1.179v-7.65c0-.569 0-.917.017-1.179.016-.249.042-.304.039-.297a.83.83 0 0 1 .476-.476c-.007.003.048-.023.297-.039.262-.017.61-.017 1.179-.017",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }