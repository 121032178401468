import axios from 'axios';
import { extractData } from '@/http';
import { tickerApiGatewayUrl } from '@/config';

import type { CurrenciesResponse } from '@/features/Converter/types/api';
import type { RateIconRequest, RateIconResponse } from '@/features/Converter/types/api';

export const currencies = (): Promise<CurrenciesResponse> =>
  axios.get(`${tickerApiGatewayUrl}/converter/currencies`).then(extractData);

export const rateIcon = (symbol: RateIconRequest): Promise<RateIconResponse> =>
  axios.get(`${tickerApiGatewayUrl}/images/logo/${symbol}.png`, { responseType: 'arraybuffer' }).then((response) => {
    const blob = new Blob([response.data], { type: 'image/png' });
    return URL.createObjectURL(blob);
  });
