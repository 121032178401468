import type { App } from 'vue';
import * as Sentry from '@sentry/vue';

import { filterOldBrowsers, ignoredErrorMessages, denyUrls } from './ignoreConfig';

import CorrelationService from '@/services/correlation';
import { deploymentEnv, sentryDsn, tracePropagationTargets, tracesSampleRate, release } from '@/config';
import { useUserStore } from '@/stores/user.store.ts';

export const initSentry = (app: App) => {
  Sentry.init({
    app,
    release,
    dsn: sentryDsn,
    environment: deploymentEnv,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.httpClientIntegration()],
    ignoreErrors: ignoredErrorMessages,
    denyUrls,
    beforeSend: (event) => filterOldBrowsers(event),
    tracesSampleRate: tracesSampleRate,
    tracePropagationTargets: tracePropagationTargets,
  });

  setTags({
    correlation_id: CorrelationService.getCorrelationId(),
  });

  const userState = useUserStore();

  if (userState.state) {
    Sentry.setContext('user', {
      id: userState.state.id,
      email: userState.state.email,
      name: userState.state.name,
    });
  }
};

export const setTags = (tags: { [key: string]: string }) => {
  Object.entries(tags).forEach(([key, value]) => {
    Sentry.setTag(key, value);
  });
};

export default {
  initSentry,
  setTags,
};
