import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M12.917 12.5H8.75a.417.417 0 0 0-.417.417v.833c0 .23.187.417.417.417h4.166c.23 0 .417-.187.417-.417v-.833a.417.417 0 0 0-.417-.417M8.75 9.167h2.5c.23 0 .416.187.416.417v.833c0 .23-.186.417-.416.417h-2.5a.417.417 0 0 1-.417-.417v-.833c0-.23.187-.417.417-.417m5.833-3.333H8.75a.417.417 0 0 0-.417.416v.834c0 .23.187.416.417.416h5.833c.23 0 .417-.186.417-.416V6.25a.417.417 0 0 0-.417-.416"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M5 10V4.167a2.5 2.5 0 0 1 2.5-2.5h8.333a2.5 2.5 0 0 1 2.5 2.5V15A3.333 3.333 0 0 1 15 18.334H4.167a2.5 2.5 0 0 1-2.5-2.5V12.5a2.5 2.5 0 0 1 2.5-2.5zm0 1.667v4.167a.833.833 0 0 1-1.667 0V12.5c0-.46.373-.833.833-.833zm2.5-8.333a.833.833 0 0 0-.833.833v11.667q-.002.44-.143.833H15c.92 0 1.666-.746 1.666-1.667V4.167a.833.833 0 0 0-.833-.833z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }