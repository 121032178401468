import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "14",
  height: "18",
  fill: "none",
  viewBox: "0 0 14 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M6.142 11.056h-2.39c-.073 0-.112-.08-.065-.132l4.327-4.796c.082-.091.24-.027.226.091l-.382 3.228h2.389c.073 0 .113.08.066.132l-4.328 4.796c-.082.09-.24.027-.226-.091z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M.333 4.293c0-1.048 0-1.571.155-1.99a2.5 2.5 0 0 1 1.48-1.48c.419-.155.942-.155 1.99-.155h4.851c.442 0 .866.176 1.179.488l3.19 3.19c.313.313.488.737.488 1.179v8.185c0 1.047 0 1.571-.154 1.99a2.5 2.5 0 0 1-1.48 1.48c-.42.155-.943.155-1.99.155H3.957c-1.048 0-1.571 0-1.99-.155a2.5 2.5 0 0 1-1.48-1.48c-.155-.419-.155-.943-.155-1.99zM8.25 2.335c.23 0 .416.186.416.416v2.084c0 .46.373.833.834.833h2.083c.23 0 .417.187.417.417v7.625c0 .544-.001.877-.017 1.128a1.3 1.3 0 0 1-.035.285.83.83 0 0 1-.493.494s-.059.02-.285.035c-.251.015-.584.016-1.129.016H3.958c-.544 0-.877 0-1.129-.016a1.4 1.4 0 0 1-.284-.035.83.83 0 0 1-.494-.494c.003.007-.02-.046-.035-.285A21 21 0 0 1 2 13.71V4.293c0-.544 0-.877.016-1.129a1.4 1.4 0 0 1 .035-.284.83.83 0 0 1 .494-.494c-.008.003.046-.02.284-.035.252-.016.585-.016 1.129-.016z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }