// We need it for correct behavior when user press enter
// When he does it in the end of the line and in the ul/ol lists
import { splitListItem } from 'prosemirror-schema-list';
import type { EditorView } from 'prosemirror-view';
import type { NodeType, ResolvedPos } from 'prosemirror-model';

// Check is the courser inside the ol/ul list
const isInListItem = ($from: ResolvedPos, listItemType: NodeType): boolean => {
  for (let depth = $from.depth; depth > 0; depth--) {
    const node = $from.node(depth);
    if (node.type === listItemType) return true;
  }
  return false;
};

// Insert new <p> after current selection empty <p>
const insertNewParagraph = (view: EditorView, $from: unknown) => {
  const { state, dispatch } = view;
  const tr = state.tr.insert($from.end(), state.schema.nodes.paragraph.create());
  const newSelection = state.selection.constructor.near(tr.doc.resolve($from.end() + 1));
  tr.setSelection(newSelection);
  dispatch(tr.scrollIntoView());
};

// Divine of the existing block
const splitSelection = (view: EditorView) => {
  const { state, dispatch } = view;
  const tr = state.tr.split(state.selection.to);
  dispatch(tr.scrollIntoView());
};

export const handleEnterDown = (view: EditorView, event: Event): boolean => {
  const keyEvent = event as KeyboardEvent;
  if (keyEvent.key === 'Enter') {
    const { state, dispatch } = view;
    const { $from } = state.selection;

    if (isInListItem($from, state.schema.nodes.listItem)) {
      // If cursor is inside the ul/ol we need to make a new listItem after pressing Enter
      keyEvent.preventDefault();
      return splitListItem(state.schema.nodes.listItem)(state, dispatch);
    }

    // We have the different behavior for empty paragraph ang for filling one
    const isEmptyParagraph = $from.parent.type.name === 'paragraph' && $from.parent.content.size === 0;

    if (isEmptyParagraph) {
      insertNewParagraph(view, $from);
    } else {
      splitSelection(view);
    }

    keyEvent.preventDefault();
    return true;
  }

  return false;
};
