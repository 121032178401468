<template>
  <form
    class="flex"
    @submit="onSubmit"
  >
    <FormInput
      :model-value="url"
      name="url"
      type="text"
      :is-errored="isURLErrored"
      placeholder="Paste the image link"
      @update:model-value="onUpdate"
    >
      <template #label> Add image from URL </template>
      <template #help> Max size per image: 1 MB. Min width: 600px. Accepted formats: jpeg, jpg, png. </template>
      <template #error>
        {{ errors.url }}
      </template>
    </FormInput>
    <Button
      type="submit"
      :rounded="BUTTON_ROUNDED.BASE"
      :is-disabled="isURLErrored || !uploadMediaEdit"
      class="h-10 mt-7 ml-2"
    >
      Insert image
    </Button>
  </form>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import zod from 'zod';
import Button from '@/components/Button.vue';
import FormInput from '@/components/FormInput.vue';
import { useFormData } from '@/composables/useFormData';
import { toTypedSchema } from '@vee-validate/zod';
import { BUTTON_ROUNDED } from '@/types';
import { useUserPermissions } from '@/stores/user.store';

const emits = defineEmits<{
  (event: 'upload', url: string): void;
}>();

const { defineField, errors, handleSubmit, setValues, validate } = useFormData({
  data: {
    url: '',
  },
  validator: toTypedSchema(
    zod.object({
      url: zod.string().url('Enter a valid url'),
    }),
  ),
});

const { uploadMediaEdit } = useUserPermissions();

const [url] = defineField('url');

const isURLErrored = computed(() => {
  return !!errors.value.url;
});

const onSubmit = handleSubmit((values) => {
  if (!isURLErrored.value) {
    emits('upload', values.url);
  }
});

const onUpdate = (value: string) => {
  setValues({ url: value });
  validate();
};
</script>
