import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M7.277 22a5.277 5.277 0 0 1-3.732-9.007l2.886-2.886a.5.5 0 0 1 .707 0l.785.785a.5.5 0 0 1 0 .707l-2.885 2.885a3.166 3.166 0 1 0 4.478 4.478l2.885-2.885a.5.5 0 0 1 .707 0l.785.786a.5.5 0 0 1 0 .707l-2.885 2.885A5.24 5.24 0 0 1 7.278 22m1.748-5.533H9.02l-1.137-1.136a.5.5 0 0 1 0-.707l6.755-6.756a.5.5 0 0 1 .708 0l.785.785a.5.5 0 0 1 0 .708zm8.56-2.59a.5.5 0 0 1-.706 0l-.786-.785a.5.5 0 0 1 0-.707L18.977 9.5a3.166 3.166 0 1 0-4.476-4.477l-2.886 2.885a.5.5 0 0 1-.707 0l-.785-.786a.5.5 0 0 1 0-.707l2.886-2.886a5.277 5.277 0 0 1 7.462 7.463z"
    }, null, -1)
  ])))
}
export default { render: render }