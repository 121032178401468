import { getActiveSpan, getRootSpan, spanToTraceHeader, getDynamicSamplingContextFromSpan } from '@sentry/core';
import { spanToBaggageHeader } from '@sentry/vue';
import { dynamicSamplingContextToSentryBaggageHeader } from '@sentry/utils';

type TraceHeaders = {
  sentryTraceHeader: string | undefined;
  sentryBaggageHeader: string | undefined;
};

export const getTraceAndBaggage = (): TraceHeaders | null => {
  const activeSpan = getActiveSpan();
  const rootSpan = activeSpan && getRootSpan(activeSpan);

  const sentryTraceHeader = rootSpan ? spanToTraceHeader(rootSpan) : undefined;
  const sentryBaggageHeader = rootSpan ? spanToBaggageHeader(rootSpan) : undefined;

  return {
    sentryTraceHeader,
    sentryBaggageHeader,
  };
};
