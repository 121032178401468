import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M13.52 8.782a.417.417 0 0 1 .116.578l-3.438 5.157a1 1 0 0 1-1.591.096L6.52 12.18a.417.417 0 0 1 .045-.588l.633-.542a.417.417 0 0 1 .587.045L9.3 12.86l2.95-4.424a.417.417 0 0 1 .578-.116z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M3.333 5.292c0-1.048 0-1.572.155-1.99a2.5 2.5 0 0 1 1.48-1.48c.419-.155.942-.155 1.99-.155h4.851c.442 0 .866.175 1.179.488l3.19 3.19c.313.313.488.737.488 1.179v8.184c0 1.048 0 1.572-.154 1.99a2.5 2.5 0 0 1-1.48 1.48c-.42.155-.943.155-1.99.155H6.957c-1.048 0-1.571 0-1.99-.154a2.5 2.5 0 0 1-1.48-1.48c-.155-.42-.155-.943-.155-1.99zm7.917-1.959c.23 0 .416.187.416.417v2.083c0 .46.373.834.834.834h2.083c.23 0 .417.186.417.416v7.625c0 .545-.001.878-.017 1.13a1.3 1.3 0 0 1-.035.284.83.83 0 0 1-.493.493s-.059.022-.285.036c-.251.015-.584.016-1.129.016H6.958c-.544 0-.877-.001-1.129-.016a1.4 1.4 0 0 1-.284-.036.83.83 0 0 1-.494-.493c.003.007-.02-.047-.035-.285A21 21 0 0 1 5 14.708V5.292c0-.545 0-.878.016-1.129.013-.212.033-.278.035-.285a.83.83 0 0 1 .494-.493c-.008.003.046-.02.284-.035a20 20 0 0 1 1.129-.017z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }