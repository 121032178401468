import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M4.611 5.591a.5.5 0 0 1 0-.707l.778-.778a.5.5 0 0 1 .707 0L20.39 18.4a.5.5 0 0 1 0 .707l-.778.778a.5.5 0 0 1-.707 0L16.34 17.32A14 14 0 0 1 12 18c-2.788 0-5.222-.803-6.98-1.902-.878-.549-1.61-1.186-2.134-1.86C2.373 13.58 2 12.806 2 12c0-.834.424-1.618.963-2.265.555-.666 1.323-1.297 2.219-1.84q.53-.322 1.128-.605zM7.83 8.81a11 11 0 0 0-1.612.795c-.754.457-1.336.951-1.719 1.41-.398.478-.499.819-.499.985 0 .195.102.545.464 1.011.352.452.894.94 1.616 1.391C7.522 15.303 9.588 16 12 16c.967 0 1.878-.112 2.716-.304l-1.142-1.142a3 3 0 0 1-4.128-4.128z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M19.536 13.011c-.292.375-.715.774-1.262 1.157l1.431 1.432a7.5 7.5 0 0 0 1.41-1.361c.512-.66.885-1.434.885-2.239 0-.834-.424-1.618-.963-2.265-.555-.666-1.323-1.297-2.219-1.84A13.4 13.4 0 0 0 12 6c-.601 0-1.194.043-1.77.123l1.876 1.878a11.43 11.43 0 0 1 5.676 1.604c.754.457 1.336.951 1.718 1.41.399.478.5.819.5.985 0 .195-.102.545-.464 1.011"
    }, null, -1)
  ])))
}
export default { render: render }