<template>
  <div class="flex gap-2">
    <Button
      v-if="isDraft || isUnpublish"
      @click="onOpenPublishModal"
    >
      Publish Page
    </Button>
    <Button
      v-if="isPublish"
      :visual-type="BUTTON_TYPE.PRIMARY"
      :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
      :color="BUTTON_COLOR.PRIMARY"
      @click="onOpenUnPublishModal"
    >
      Unpublish
    </Button>

    <Teleport
      v-if="isUnPublishModalVisible"
      to="#modals"
    >
      <UnPublishModal
        :is-visible="isUnPublishModalVisible"
        @close="onCloseModals"
        @update="onUnPublish"
      />
    </Teleport>
    <Teleport
      v-if="isPublishModalVisible"
      to="#modals"
    >
      <PublishModal
        :is-visible="isPublishModalVisible"
        @close="onCloseModals"
        @update="onPublish"
      />
    </Teleport>
  </div>
</template>
<script setup lang="ts">
import { BUTTON_COLOR, BUTTON_ON_SURFACE, BUTTON_TYPE } from '@/types';
import Button from '@/components/Button.vue';
import { computed, ref } from 'vue';
import { ConverterStatus } from '@/features/Converter/types';
import { useConverterPage } from '@/features/Converter/stores/converter.store';
import UpdatePublishModal from '@/features/Converter/components/modals/UpdatePublishModal.vue';
import UnPublishModal from '@/features/Converter/components/modals/UnPublishModal.vue';
import PublishModal from '@/features/Converter/components/modals/PublishModal.vue';

const emits = defineEmits<{
  (event: 'update'): void;
  (event: 'publish'): void;
  (event: 'unPublish'): void;
}>();

const converterStore = useConverterPage();

const isUnPublishModalVisible = ref<boolean>(false);
const isPublishModalVisible = ref<boolean>(false);

const isDraft = computed(() => {
  return converterStore.state.converter.status === ConverterStatus.DRAFT || !converterStore.state.converter.status;
});

const isUnpublish = computed(() => {
  return converterStore.state.converter.status === ConverterStatus.UNPUBLISHED;
});

const isPublish = computed(() => {
  return converterStore.state.converter.status === ConverterStatus.PUBLISHED;
});

const onOpenUnPublishModal = () => {
  isUnPublishModalVisible.value = true;
};

const onOpenPublishModal = () => {
  isPublishModalVisible.value = true;
};

const onCloseModals = () => {
  isUnPublishModalVisible.value = false;
  isPublishModalVisible.value = false;
};

const onUpdate = () => {
  onCloseModals();
  emits('update');
};

const onPublish = () => {
  onCloseModals();
  emits('publish');
};

const onUnPublish = () => {
  onCloseModals();
  emits('unPublish');
};
</script>
