import type {
  ConverterFetchPayload,
  ConverterFetchResponse,
  ConverterItemCreateRequest,
  ConverterItemCreateResponse,
  ConverterItemFetchRequest,
  ConverterItemFetchResponse,
  ConverterItemPatchRequest,
  ConverterItemPatchResponse,
} from '@/features/Converter/types/api';
import { converters, createConverter, fetchConverter, patchConverter } from '@/features/Converter/api';

export const ConverterService = {
  converters: (payload: ConverterFetchPayload): Promise<ConverterFetchResponse> => {
    const resultPayload = payload;

    if (payload.searchQuery) {
      resultPayload[`filters[search]`] = payload.searchQuery;
    }

    for (const key in payload.sort) {
      if (payload.sort[key]) {
        resultPayload[`order[${key}]`] = payload.sort[key];
      }
    }

    return converters(resultPayload);
  },
  fetchConverter: (payload: ConverterItemFetchRequest): Promise<ConverterItemFetchResponse> => fetchConverter(payload),
  createConverter: (payload: ConverterItemCreateRequest): Promise<ConverterItemCreateResponse> =>
    createConverter(payload),
  patchConverter: (payload: ConverterItemPatchRequest): Promise<ConverterItemPatchResponse> => patchConverter(payload),
};
