import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "19",
  fill: "none",
  viewBox: "0 0 24 19"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M22 12.5c0 .79-.367 1.496-.94 1.954l1.39 1.39.029.03a4.5 4.5 0 0 0-2.497-7.848A7.495 7.495 0 0 0 12.5 0a7.47 7.47 0 0 0-4.44 1.453L9.497 2.89A5.5 5.5 0 0 1 18 7.5c0 .854-.243 1.675-.438 2.5H19.5a2.5 2.5 0 0 1 2.5 2.5"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M5.748 4.233 3.61 2.096a.5.5 0 0 1 0-.707l.78-.778a.5.5 0 0 1 .707 0L21.39 16.904a.5.5 0 0 1 0 .707l-.778.778a.5.5 0 0 1-.707 0L18.514 17H6a6 6 0 0 1-.583-11.972q.143-.41.33-.795M7.334 5.82l9.18 9.18H6a4 4 0 0 1-4-4c0-2.548 2.467-4.337 4.958-3.97q.148-.626.376-1.21",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }