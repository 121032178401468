/**
 * stolen from
 * https://github.com/angus-c/just/blob/master/packages/string-kebab-case/index.mjs
 *
 * playground
 * https://anguscroll.com/just/just-kebab-case
 */

/*
  kebabCase('the quick brown fox'); // 'the-quick-brown-fox'
  kebabCase('the-quick-brown-fox'); // 'the-quick-brown-fox'
  kebabCase('the_quick_brown_fox'); // 'the-quick-brown-fox'
  kebabCase('theQuickBrownFox'); // 'the-quick-brown-fox'
  kebabCase('theQuickBrown Fox'); // 'the-quick-brown-fox'
  kebabCase('thequickbrownfox'); // 'thequickbrownfox'
  kebabCase('the - quick * brown# fox'); // 'the-quick-brown-fox'
  kebabCase('theQUICKBrownFox'); // 'the-quick-brown-fox'
*/

// any combination of spaces and punctuation characters
// thanks to http://stackoverflow.com/a/25575009
const wordSeparators = /[\s\u2000-\u206F\u2E00-\u2E7F'"!#$%&()*+,\-./:;<=>?@[\\\]^_`{|}~]+/;
const capital_plus_lower = /\b[A-ZÀ-Ý\u00C0-\u00D6\u00D9-\u00DD][a-zà-ÿ]/g;
const capitals = /[A-ZÀ-Ý\u00C0-\u00D6\u00D9-\u00DD]+/g;

export function convertStringToKebabCase(str: string): string {
  let result = str;

  // replace word starts with space + lower case equivalent for later parsing
  // 1) treat cap + lower as start of new word
  result = result.replace(capital_plus_lower, (match) => {
    // match is one caps followed by one non-cap
    return ` ${match[0].toLowerCase() || match[0]}${match[1]}`;
  });

  // 2) treat all remaining capitals as words
  result = result.replace(capitals, (match) => {
    // match is a series of caps
    return ` ${match.toLowerCase()}`;
  });

  return result.trim().split(wordSeparators).join('-').replace(/^-/, '').replace(/-\s*$/, '');
}

export const getFullUrl = (urlOrPath: string): string => {
  // Check if the URL starts with 'http' or 'https'
  if (urlOrPath.startsWith('http')) {
    return urlOrPath;
  } else {
    // If not, construct the URL using the origin and the provided path
    return `${window.location.origin}${urlOrPath}`;
  }
};

export const replaceTagUrl = (url: string, value: string): string => {
  return url.replace(/{tag}/g, value);
};

export const isLocalUrl = (url: string): boolean => {
  if (!url) {
    return false;
  }

  return url.includes('localhost') || url.includes('0.0.0.0') || url.includes('127.0.0.1');
};
