import { useQuery } from '@tanstack/vue-query';
import type { ComputedRef } from 'vue';

import { RATE_ICON_RESOURCE } from '@/features/Converter/constants';
import type { RateIconRequest } from '@/features/Converter/types/api';
import { rateIcon } from '@/features/Converter/api/currencies';

export const useRateIcon = (params: ComputedRef<RateIconRequest>) => {
  const { data, isLoading } = useQuery({
    queryKey: [RATE_ICON_RESOURCE, params],

    queryFn: ({ queryKey }) => {
      const payload: RateIconRequest = queryKey[1] as RateIconRequest;

      return rateIcon(payload);
    },
    staleTime: Infinity,
    refetchInterval: false,
    enabled: !!params,
  });

  return {
    data,
    isLoading,
  };
};
