<template>
  <div class="mb-4">
    <ConverterPageHeader
      v-if="!isLoading"
      @update="onUpdate"
      @un-publish="onUnPublish"
      @publish="onPublish"
    />
    <!--  Blocks wrapper -->
    <div
      v-if="!isLoading"
      class="gap-4 flex flex-col"
    >
      <component
        :is="block.component"
        v-for="block of blocks"
        v-bind="block.attrs"
        :key="block.slug"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted } from 'vue';
import ConverterBasicInformationBlock from '@/features/Converter/components/ConverterPage/ConverterBasicInformationBlock.vue';
import { useConverterPage } from '@/features/Converter/stores/converter.store';
import ConverterPageHeader from '@/features/Converter/components/ConverterPage/ConverterPageHeader.vue';
import { CALCULATOR_SECTIONS_SLUG, ConverterStatus } from '@/features/Converter/types';
import { useToast } from '@/composables';
import SEOSection from '@/components/SEOSection.vue';
import RelatedArticles from '@/components/RelatedArticles.vue';
import FAQDraggableSection from '@/components/FAQ/FAQDraggableSection.vue';
import AboutSection from '@/components/AboutSection.vue';
import { ConverterItemFetchPayload } from '@/features/Converter/types/api';
import { useFetchConverterItem } from '@/features/Converter/queries/converterItem.query';

const props = defineProps<{
  converterId: number;
}>();

const converterStore = useConverterPage();
const toast = useToast();

const blocks = computed(() => {
  return [
    {
      slug: CALCULATOR_SECTIONS_SLUG.BASIC,
      component: ConverterBasicInformationBlock,
      attrs: {
        converterId: props.converterId,
      },
    },
    {
      slug: CALCULATOR_SECTIONS_SLUG.ABOUT,
      component: AboutSection,
      attrs: {
        converterId: props.converterId,
      },
    },

    {
      slug: CALCULATOR_SECTIONS_SLUG.ARTICLES,
      component: RelatedArticles,
      attrs: {
        converterId: props.converterId,
      },
    },
    {
      slug: CALCULATOR_SECTIONS_SLUG.SEO,
      component: SEOSection,
      attrs: {
        converterId: props.converterId,
      },
    },
    {
      slug: CALCULATOR_SECTIONS_SLUG.FAQ,
      component: FAQDraggableSection,
      attrs: {
        converterId: props.converterId,
      },
    },
  ];
});

const fetchPayload = computed<ConverterItemFetchPayload>(() => ({
  languageId: 1,
  id: props.converterId,
}));

const onSuccessConverterData = () => {
  converterStore.updateConverterData(data.value, false);
};

// TODO: Exchange languageId to Language service
const { data, isLoading } = useFetchConverterItem(fetchPayload, onSuccessConverterData);

// Only status change on top buttons click
const onUpdate = async () => {
  converterStore.updateSavingByUser(true);
  toast.success({
    id: 'CONVERTER_UPDATE_SUCCESS',
    message: `Сonverter page was updated successfully.`,
  });
};
const onPublish = () => {
  converterStore.onFieldUpdate(ConverterStatus.PUBLISHED, 'status', props.converterId);
};
const onUnPublish = () => {
  converterStore.onFieldUpdate(ConverterStatus.UNPUBLISHED, 'status', props.converterId);
};

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
});

onBeforeUnmount(() => {
  converterStore.clearStore();
});
</script>
