import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "15",
  height: "16",
  fill: "none",
  viewBox: "0 0 15 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m6.8 9.708 4.45 4.45a.417.417 0 0 0 .588 0l.649-.649a.417.417 0 0 0 0-.589L1.409 1.843a.417.417 0 0 0-.589 0l-.648.648a.417.417 0 0 0 0 .59l4.916 4.916-1.93 7.503h2.151zm1.94-7.541L7.669 6.334 5.957 4.623l.632-2.456H3.5L1.834.5h12.829l-.417 1.667z"
    }, null, -1)
  ])))
}
export default { render: render }