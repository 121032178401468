<template>
  <ModalHolder
    :is-visible="props.isVisible"
    :size="SIZES.SMALL"
    data-testid="converter-publish-modal"
    @close="emits('close')"
    @wheel.prevent
  >
    <div class="flex flex-col items-center justify-center">
      <ExclamationShapes class="text-imperium-bg-5" />
      <div class="mt-4 text-imperium-fg-base font-normal text-center max-w-[280px]">
        Are you sure you want to publish this сonverter page?
      </div>
      <div class="flex gap-2 my-4 items-center justify-center">
        <Button
          :color="BUTTON_COLOR.DEFAULT"
          :size="SIZES.MEDIUM"
          data-testid="converter-publish-modal-button"
          :visual-type="BUTTON_TYPE.TERTIARY"
          @click="emits('close')"
        >
          Cancel
        </Button>
        <Button
          :size="SIZES.MEDIUM"
          :visual-type="BUTTON_TYPE.PRIMARY"
          :color="BUTTON_COLOR.DEFAULT"
          type="submit"
          data-testid="converter-publish-modal-button"
          @click="emits('update')"
        >
          Yes, publish
        </Button>
      </div>
    </div>
  </ModalHolder>
</template>
<script setup lang="ts">
import ExclamationShapes from '@/assets/icons/exclamation-shapes.svg?component';
import ModalHolder from '@/components/ModalHolder.vue';
import { BUTTON_COLOR, BUTTON_TYPE, SIZES } from '@/types';
import Button from '@/components/Button.vue';
import FormInput from '@/components/FormInput.vue';

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'update'): void;
  (event: 'close'): void;
}>();
</script>
