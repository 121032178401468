import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M16.578 8.957a.5.5 0 0 0-.155-.69l-.846-.534a.5.5 0 0 0-.69.155l-3.85 6.086-1.954-2.252a.5.5 0 0 0-.705-.05l-.756.656a.5.5 0 0 0-.05.705l2.66 3.064a1.2 1.2 0 0 0 1.92-.145z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-10 8a8 8 0 1 0 0-16 8 8 0 0 0 0 16",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }