import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { Currency } from '@/features/Converter/types/currencies';
import { CurrencyType } from '@/features/Converter/types/currencies';
import { CurrenciesService } from '@/features/Converter/services/currencies';
import Sentry from '@/plugins/sentry';
import { useToast } from '@/composables';

interface CurrenciesState {
  fiatCurrencies: Currency[] | null;
  cryptoCurrencies: Currency[] | null;
}

export const useConverterCurrencies = defineStore('converterCurrencies', () => {
  const state = ref<CurrenciesState>({
    fiatCurrencies: null,
    cryptoCurrencies: null,
  });

  const toast = useToast();

  const fetchCurrencies = async () => {
    try {
      const { currencies } = await CurrenciesService.currencies();

      state.value.fiatCurrencies = currencies.filter((item: Currency) => item.type === CurrencyType.FIAT);
      state.value.cryptoCurrencies = currencies.filter((item: Currency) => item.type === CurrencyType.CRYPTO);
    } catch (e) {
      toast.errorTemporary({
        id: 'ERROR_LIST_CURRENCIES_LOADED',
        message: 'Requested currencies list cannot be loaded',
      });
    }
  };

  return {
    fetchCurrencies,
    state,
  };
});
