import { extractData, http } from '@/http';
import type {
  CategoriesFetchRequest,
  CategoryFetchParam,
  CategoryTranslate,
  CategoryTranslateFormPayload,
  CategoriesFetchResponse,
} from '@/features/Categories/types';
import { DEFAULT_LANGUAGE_ID } from '@/constants/queries.ts';
import type { AxiosRequestConfig } from 'axios';

export const fetchCategories = (
  params: CategoriesFetchRequest,
  options: Partial<AxiosRequestConfig>,
): Promise<CategoriesFetchResponse> =>
  http.get<CategoriesFetchResponse>('/categories', { params, useAuth: true, ...options }).then(extractData);

export const fetchCategory = ({
  categoryId,
  languageId = DEFAULT_LANGUAGE_ID, // TODO: Change it after update functionality of category languages
}: CategoryFetchParam): Promise<CategoryTranslate> =>
  http
    .get<CategoryTranslate>(`/categories/${categoryId}/language/${languageId}`, {
      useAuth: true,
    })
    .then(extractData);

export const createCategory = (category: CategoryTranslateFormPayload): Promise<CategoryTranslate> =>
  http
    .post<CategoryTranslate>(`/categories`, category, {
      useAuth: true,
    })
    .then(extractData);

export const updateCategory = (
  categoryId: string | number,
  category: CategoryTranslateFormPayload,
): Promise<CategoryTranslate> =>
  http
    .patch<CategoryTranslate>(
      `/categories/${categoryId}/language/${category?.languageId ?? DEFAULT_LANGUAGE_ID}`,
      category,
      {
        useAuth: true,
      },
    )
    .then(extractData);
