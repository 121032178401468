export enum ConverterStatus {
  DRAFT = 0,
  PUBLISHED = 1,
  UNPUBLISHED = 2,
}

export interface ConverterTag {
  id: number;
  title: string;
}

export interface ConverterTableItem {
  id: number;
  cryptoSymbol: string;
  fiatSymbol: string;
  isAboutPublished: boolean;
  isFaqPublished: boolean;
  status: ConverterStatus;
  websitePage: string;
}

export interface Converter {
  title: string;
  cryptoSymbol: string;
  fiatSymbol: string;
  description?: string;
  status: string;
  aboutDescription?: string;
  isAboutPublished?: boolean;
  tags?: ConverterTag[];
  seoTitle?: string;
  seoDescription?: string;
  websiteLink?: string;
}

export const enum CALCULATOR_SECTIONS_SLUG {
  BASIC = 'basic-information',
  ABOUT = 'basic-information-about',
  FAQ = 'basic-information-faq',
  ARTICLES = 'basic-information-articles',
  SEO = 'basic-information-seo',
}
