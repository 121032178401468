<template>
  <div :data-anchor="props.dataAnchor">
    <div
      class="flex items-center justify-center mt-4"
      :class="media?.url ? '' : 'border border-dashed border-imperium-border-base'"
    >
      <div
        v-if="media?.url"
        class="relative w-full aspect-ratio-content"
      >
        <div
          class="absolute inset-0 flex cursor-pointer items-center justify-center bg-center bg-cover media-image"
          @click="isInsertImageModalVisible = true"
        >
          <div class="absolute right-0 bottom-0">
            <Button
              class="mb-2 mr-2"
              :size="SIZES.MEDIUM"
              :color="BUTTON_COLOR.RED"
              :visual-type="BUTTON_TYPE.SECONDARY"
              @click.stop.prevent="deleteImage"
            >
              <template #leftIcon>
                <DeleteIcon class="h-4 w-4" />
              </template>
            </Button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="relative w-full aspect-ratio-box flex justify-center"
      >
        <LoaderIcon
          v-if="isLoading || isPending"
          class="h-10 w-10"
        />
        <Button
          v-else
          class="w-[184px]"
          :visual-type="BUTTON_TYPE.SECONDARY"
          :color="BUTTON_COLOR.PRIMARY"
          :is-disabled="props.isDisabled"
          :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
          :size="SIZES.SMALL"
          @click="isInsertImageModalVisible = true"
        >
          <template #leftIcon>
            <PictureIcon class="w-4 h-4 text-imperium-fg-base" />
          </template>

          Insert image as cover
        </Button>
      </div>
    </div>
  </div>
  <div
    v-if="props.isErrored && isError"
    :data-testid="`cover-${props.modelValue}-error`"
    class="mt-1 input-meta-text-errored"
  >
    <slot name="error" />
  </div>
  <Teleport to="#modals">
    <InsertImageModal
      :is-visible="isInsertImageModalVisible"
      @insert="onInsertImage"
      @close="onClose"
    />
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, useSlots } from 'vue';

import Button from '@/components/Button.vue';
import InsertImageModal from '@/features/UploadImage/modals/InsertImageModal.vue';

import PictureIcon from '@/assets/icons/picture.svg?component';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import DeleteIcon from '@/assets/icons/trash.svg?component';
import { BUTTON_COLOR, BUTTON_ON_SURFACE, BUTTON_TYPE, SIZES } from '@/types';
import { useFetchImage } from '@/features/Media/queries/useFetchImage';

const props = defineProps<{
  modelValue: number;
  isErrored: boolean;
  isLoading: boolean;
  isDisabled?: boolean;
  dataAnchor?: string;
}>();

const emits = defineEmits<{
  (event: 'update:modelValue', value: number | null): void;
}>();

const slots = useSlots();

const isInsertImageModalVisible = ref<boolean>(false);
const isError = computed(() => !!slots.error);

const image = computed(() => {
  if (media.value?.url) {
    return `url(${media.value.url})`;
  }

  return '';
});

const imageId = computed(() => {
  if (props.modelValue) {
    return props.modelValue;
  }

  return null;
});

const { data: media, isPending } = useFetchImage({
  imageId,
});

const onClose = () => {
  isInsertImageModalVisible.value = false;
};

const onInsertImage = ({ id }: { id: number; url: string }) => {
  emits('update:modelValue', id);
};

const deleteImage = () => {
  emits('update:modelValue', null);
};
</script>

<style lang="scss" scoped>
.media-image {
  background-image: v-bind(image);
}

.aspect-ratio-content {
  position: relative;
  width: 100%;
  padding-top: calc(100% * (2 / 3));
}
.aspect-ratio-box {
  position: relative;
  width: 100%;
  padding-top: calc(100% * (1 / 3));
  padding-bottom: calc(100% * (1 / 3));
}
</style>
