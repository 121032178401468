import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M3.871 11.337a.42.42 0 0 1 0-.59l.646-.648a.413.413 0 0 1 .586 0l3.466 3.477.681 1.216-.08-1.442V2.917c0-.23.186-.417.415-.417h.83c.229 0 .414.187.414.417V13.35l-.079 1.442.68-1.216 3.467-3.477a.413.413 0 0 1 .586 0l.645.649a.42.42 0 0 1 0 .59L10 17.5z"
    }, null, -1)
  ])))
}
export default { render: render }