<template>
  <nav
    class="w-full mx-auto px-4 py-2 sm:px-6 sm:py-3 flex"
    data-testid="breadcrumbs"
  >
    <template
      v-for="({ title, to, active, name }, index) in props.items"
      :key="name || index"
    >
      <div
        v-if="title"
        class="inline-flex items-center"
      >
        <RouterLink
          v-if="to"
          :to="to"
          class="ms-1 text-sm truncate pointer-events-none"
          :class="linkClass(index, active)"
          :data-testid="`breadcrumbs-${name}`"
        >
          <span>{{ title }}</span>
        </RouterLink>
        <span
          v-if="!to"
          class="ms-1 text-sm truncate pointer-events-none"
          :class="linkClass(index, active)"
          :data-testid="`breadcrumbs-${name}`"
        >
          <span>{{ title }}</span>
        </span>

        <CaretRight
          v-if="!isLast(index)"
          class="rtl:rotate-180 w-2 h-2 text-imperium-fg-subtle ml-2 mr-1 mt-0.5"
        />
      </div>
    </template>
  </nav>
</template>
<script setup lang="ts">
import CaretRight from '@/assets/icons/caret-right.svg?component';
import type { BreadcrumbItem } from '@/features/Breadcrumbs/types';

const props = defineProps<{
  items: BreadcrumbItem[];
}>();
const isLast = (index: number) => index === props.items.length - 1;

const linkClass = (index: number, active: boolean = false) => ({
  'ms-1 text-sm truncate pointer-events-none': true,
  'text-imperium-fg-base': active,
  'text-imperium-fg-muted': !active,
  'ms-1 text-base font-semibold text-imperium-fg-base': isLast(index),
  'hover:underline pointer-events-auto': !isLast(index),
});
</script>
