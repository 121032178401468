import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "80",
  height: "80",
  fill: "none",
  viewBox: "0 0 80 80"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M73.333 40C73.333 21.59 58.41 6.667 40 6.667 21.59 6.666 6.667 21.59 6.667 40S21.59 73.333 40 73.333 73.333 58.41 73.333 40M37.565 23.333c-.945 0-1.702.786-1.665 1.73l.705 18.334c.034.895.77 1.603 1.665 1.603h3.46c.895 0 1.63-.708 1.665-1.603l.705-18.333a1.667 1.667 0 0 0-1.666-1.73zm6.602 30a4.167 4.167 0 1 1-8.334 0 4.167 4.167 0 0 1 8.334 0",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }