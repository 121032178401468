<template>
  <ModalHolder
    :is-visible="isVisible"
    :size="SIZES.MEDIUM"
    data-testid="edit-link-data-modal"
    :is-click-outside-close-modal="false"
    @close="onClose"
  >
    <template #title> Insert link</template>
    <div class="flex flex-col">
      <form
        class="flex flex-col mt-2"
        @submit="onSubmit"
      >
        <FormInput
          v-model="textField"
          :attrs="textAttrs"
          :is-errored="isTextErrored"
          class="w-full"
          is-disabled
          name="imageAlt"
          type="text"
        >
          <template #label>
            <span class="font-semibold">Text</span>
          </template>
          <template #error>
            {{ errors.textField }}
          </template>
        </FormInput>
        <div class="flex space-x-2 items-end">
          <FormComboSelectBox
            ref="inputElement"
            v-model="linkValue"
            :attrs="linkAttrs"
            :is-errored="isLinkErrored"
            :is-dropdown-icon-visible="false"
            :is-return-full-value="true"
            :search-value="searchValue"
            :autofocus="autofocus"
            :size="SIZES.MEDIUM"
            :values="selectValues"
            class="w-full mt-2 border-none p-0"
            name="link"
            placeholder="Start typing the article title or paste the link"
            type="text"
            @search="onInputSearch"
            @update:model-value="onUpdateLinkInput"
          >
            <template #label>
              <span class="font-semibold">Link</span>
            </template>
            <template #suffix>
              <div v-if="isLoading">
                <LoaderIcon class="h-5 w-5" />
              </div>
            </template>
            <template #dropdownItem="{ item }">
              <div
                v-if="searchValue?.includes('https://')"
                class="flex items-center space-x-2 py-2"
              >
                <WorldIcon class="w-5 h-5 text-imperium-fg-base" />
                <div class="flex-col">
                  <div class="text-imperium-fg-strong font-semibold truncate max-w-[400px]">
                    {{ item }}
                  </div>
                  <div class="text-imperium-fg-strong text-xs">Link to web page</div>
                </div>
              </div>
              <div
                v-else
                class="w-full py-2 ms-2 text-sm truncate font-medium text-gray-900 rounded dark:text-gray-300"
              >
                {{ item }}
              </div>
            </template>
            <template #error>
              {{ errors.link }}
            </template>
          </FormComboSelectBox>
          <a
            v-if="result?.id?.includes('https://')"
            :href="result?.id"
            target="_blank"
          >
            <Button
              :color="BUTTON_COLOR.PRIMARY"
              :size="SIZES.MEDIUM"
              :visual-type="BUTTON_TYPE.GHOST"
            >
              <template #leftIcon>
                <LinkIcon class="w-6 h-6 p-0.5 text-imperium-fg-base" />
              </template>
            </Button>
          </a>
        </div>
        <FormCheckbox
          :model-value="openInNewPage"
          class="mb-2.5 mt-2"
          name="show-markets"
          :size="SIZES.SMALL"
          :is-disabled="isCheckboxesDisabled"
          @update:model-value="(value) => (openInNewPage = value)"
        >
          <span class="text-sm">Open link in a new tab</span>
        </FormCheckbox>
        <FormCheckbox
          :model-value="isNofollowLink"
          class="mb-2.5"
          name="show-markets"
          :size="SIZES.SMALL"
          :is-disabled="isCheckboxesDisabled"
          @update:model-value="(value) => (isNofollowLink = value)"
        >
          <span class="text-sm">Nofollow link</span>
        </FormCheckbox>

        <Toast
          id="publish-modal-embargo"
          message="Every non-Cointelegraph link is recommended to be opened in a new tab with a nofollow checkbox."
          :theme="THEMES.WARNING"
          :is-closable="false"
          is-full-width
        />
        <div class="flex gap-2">
          <Button
            v-if="isEdit"
            :size="SIZES.MEDIUM"
            class="mt-4"
            :visual-type="BUTTON_TYPE.TERTIARY"
            is-full-width
            @click="onRemoveLink"
          >
            Remove link </Button
          ><Button
            :size="SIZES.MEDIUM"
            class="mt-4"
            is-full-width
            type="submit"
            :is-disabled="isSubmitButtonDisabled"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  </ModalHolder>
</template>
<script lang="ts" setup>
import { computed, nextTick, ref, watch, watchEffect } from 'vue';
import zod from 'zod';

import { toTypedSchema } from '@vee-validate/zod';
import { useFormData } from '@/composables/useFormData';
import { fetchPosts } from '@/features/Posts/api';

import Button from '@/components/Button.vue';
import FormInput from '@/components/FormInput.vue';
import ModalHolder from '@/components/ModalHolder.vue';
import FormComboSelectBox, { type SelectItem } from '@/components/FormComboSelectBox.vue';

import { BUTTON_COLOR, BUTTON_TYPE, SIZES, THEMES, SortStatus } from '@/types';
import { type ArticlesFetchRequest } from '@/features/Articles/types';

import WorldIcon from '@/assets/icons/world.svg?component';
import LinkIcon from '@/assets/icons/open-link.svg?component';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import { debounce } from 'lodash';
import FormCheckbox from '@/components/FormCheckbox.vue';
import Toast from '@/components/Toast.vue';
import { useLinkTipTapStore } from '@/features/TipTapEditor/stores/link.store';

const emits = defineEmits<{
  (event: 'close'): void;
  (event: 'reset', value: { link: string; isNofollow?: boolean; isInNewTab?: boolean }): void;
  (event: 'insert', value: { link: string; isNofollow?: boolean; isInNewTab?: boolean; title?: string }): void;
}>();

const linkStore = useLinkTipTapStore();

const isVisible = computed(() => linkStore.state.isModalVisible);

const isEdit = ref<boolean>(false);
const openInNewPage = ref<boolean>(false);
const isNofollowLink = ref<boolean>(false);

const autofocus = ref(false);

const isCheckboxesDisabled = ref<boolean>(false);
const searchValue = ref<string>('');
const selectValues = ref<SelectItem[]>([]);
const result = ref<SelectItem | null>(null);
const isLoading = ref<boolean>(false);

const { defineField, errors, values, setValues, meta, resetForm } = useFormData({
  data: {
    textField: linkStore.state.selection?.selectedText || '',
    link: linkStore.state.modalLinkAttributes?.link || '',
  },
  validator: toTypedSchema(
    zod.object({
      textField: zod.string(),
      link: zod.string(),
    }),
  ),
});

const [textField, textAttrs] = defineField('textField');
const [linkValue, linkAttrs] = defineField('link');

const fetchSearchItems = async () => {
  try {
    isLoading.value = true;

    const requestParams: ArticlesFetchRequest = {
      pagination: true,
      page: 1,
      itemsPerPage: 100,
      'order[id]': SortStatus.DESC,
      languageId: 1,
    };

    if (searchValue.value) {
      requestParams['filter[search]'] = searchValue.value;
    }

    const data = await fetchPosts(requestParams);

    selectValues.value = data.items.map((item) => ({
      id: item.url,
      label: item.title,
    }));
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
  }
};

const debouncedFetchSearchItems = debounce(fetchSearchItems, 300);

const isTextErrored = computed(() => meta.value.touched && !!errors.value.textField);
const isLinkErrored = computed(() => meta.value.touched && !!errors.value.link);

const onSubmit = (event: Event) => {
  event.preventDefault();
  if (errors.value.link) {
    return;
  }

  if (!values.link.includes('https://')) {
    return;
  }

  emits('insert', {
    link: values.link,
    isInNewTab: openInNewPage.value,
    isNofollow: isNofollowLink.value,
    title: result.value?.label,
  });
};

const onValidateLink = (link: string) => {
  if (link?.length && !link?.includes('cointelegraph.com')) {
    isNofollowLink.value = true;
    openInNewPage.value = true;
    isCheckboxesDisabled.value = true;
  } else if (link?.length || link?.includes('cointelegraph.com')) {
    isCheckboxesDisabled.value = false;
  }
};
const onRemoveLink = () => {
  resetForm();
  emits('reset', { link: values.link });
};

const onInputSearch = async (value: string) => {
  searchValue.value = value;

  if (!searchValue.value) {
    selectValues.value = [];
    return;
  }

  if (value.includes('https://')) {
    selectValues.value = [
      {
        id: value,
        label: value,
      },
    ];
  } else {
    selectValues.value = [];
    await debouncedFetchSearchItems();
  }
};

const onUpdateLinkInput = async (value: SelectItem) => {
  setValues({ link: value.id });
  result.value = value;
};

const isSubmitButtonDisabled = computed(() => {
  return !values.link.includes('https://');
});

const onClose = () => {
  resetForm();
  selectValues.value = [];
  searchValue.value = '';
  autofocus.value = false;
  emits('close');
};

watch(
  () => linkStore.state.isModalVisible,
  () => {
    if (linkStore.state.isModalVisible === true) {
      // There are we set values when we open the modal
      nextTick(() => (autofocus.value = true));

      // Set values in the form
      setValues({
        textField: linkStore.state.selection?.selectedText || '',
        link: linkStore.state.modalLinkAttributes?.link || '',
      });

      // We always need to set this state (edit or create) when we open the modal
      isEdit.value = !!linkStore.state.modalLinkAttributes?.link;

      // If we edit the link, we need to set all the data in the form abd dropdown
      if (isEdit.value) {
        // If we don't have link title, we need to use href as title
        const linkLabel: string =
          (linkStore.state.modalLinkAttributes?.title
            ? linkStore.state.modalLinkAttributes?.title
            : linkStore.state.modalLinkAttributes?.link) || '';

        searchValue.value = linkLabel;

        if (linkStore.state.modalLinkAttributes?.link) {
          selectValues.value = [
            {
              id: linkStore.state.modalLinkAttributes?.link,
              label: linkLabel,
            },
          ];

          // Set the value in the dropdown if we edit the link
          result.value = {
            id: linkStore.state.modalLinkAttributes?.link,
            label: linkLabel,
          };
        }

        // Set values of checkboxes
        openInNewPage.value = linkStore.state.modalLinkAttributes?.isInNewTab;
        isNofollowLink.value = linkStore.state.modalLinkAttributes?.isNofollow;

        onValidateLink(linkStore.state.modalLinkAttributes?.link);
      }
    } else {
      // We need to clean all values when we close the modal
      resetForm();
      selectValues.value = [];
      searchValue.value = '';
      autofocus.value = false;
      result.value = null;
    }
  },
  { immediate: true, deep: true },
);

watch([linkValue], () => {
  onValidateLink(linkValue.value);
});
</script>
