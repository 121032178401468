import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18",
  height: "18",
  fill: "none",
  viewBox: "0 0 18 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M9.224 10.263c.285 0 .509-.21.578-.487.118-.467.258-.641.865-1.08.607-.438 1.208-.931 1.208-1.903 0-1.509-1.216-2.243-2.587-2.304-.905-.04-1.788.197-2.394.907-.26.306-.454.67-.55 1.05-.048.195.084.38.277.434l.632.176c.213.06.432-.062.524-.263.1-.22.221-.402.38-.54.387-.335 1-.372 1.461-.195.348.133.607.406.574.844-.038.52-.29.681-.79.998q-.154.097-.34.22c-.568.38-.821.9-.904 1.56-.04.32.228.583.55.583zm.818 2.072a1.042 1.042 0 1 1-2.083 0 1.042 1.042 0 0 1 2.083 0"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M17.334 9.001a8.333 8.333 0 1 1-16.667 0 8.333 8.333 0 0 1 16.667 0m-1.667 0a6.667 6.667 0 1 1-13.333 0 6.667 6.667 0 0 1 13.333 0",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }