import { ref } from 'vue';
import { sseGatewayUrl } from '@/config';
import { fetchMercureToken } from '../api/sse';

import { useCurrentDocument } from './useCurrentDocument';
import { getFullUrl } from '@/utils/url';
import * as Sentry from '@sentry/browser';

export { fetchMercureToken } from '../api';

export const useServerSideEvents = <T>(onMessage: (data: T) => void) => {
  const eventSource = ref();
  const { documentId, languageId, documentPromise } = useCurrentDocument();

  const init = async () => {
    try {
      await documentPromise;

      const { token } = await fetchMercureToken();

      if (!token) {
        throw new Error();
      }

      const sseGatewayUrlWithTopic = new URL(getFullUrl(sseGatewayUrl));

      sseGatewayUrlWithTopic.searchParams.append(
        'topic',
        `/api/v1/documents/${documentId.value}/language/${languageId.value}`,
      );

      eventSource.value = new window.EventSourcePolyfill(sseGatewayUrlWithTopic.toString(), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      eventSource.value.onerror = (error: Error) => {
        Sentry.captureMessage(`${error}`);
      };

      eventSource.value.onmessage = (event) => {
        if (!event?.data) {
          return;
        }

        if (typeof onMessage !== 'function') {
          return;
        }

        try {
          const parsedData = JSON.parse(event.data);

          onMessage(parsedData);
        } catch (e) {
          throw new Error('Unable to parse stream data');
        }
      };
    } catch (e) {
      console.error(e);
    }
  };

  const disconnect = () => {
    eventSource.value?.close();
  };

  return {
    init,
    disconnect,
  };
};
