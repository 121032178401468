import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M16.128 8.663a.42.42 0 0 1 0 .59l-.645.648a.413.413 0 0 1-.586 0L11.43 6.424l-.681-1.216.08 1.442v10.433c0 .23-.186.417-.415.417h-.83a.416.416 0 0 1-.414-.417V6.65l.079-1.442-.68 1.216-3.467 3.477a.413.413 0 0 1-.586 0l-.646-.649a.42.42 0 0 1 0-.59L10 2.5z"
    }, null, -1)
  ])))
}
export default { render: render }