<script setup lang="ts">
import { BUTTON_TYPE, SIZES, TABLE_BORDERED, TABLE_ON_SURFACE } from '@/types';
import Button from '@/components/Button.vue';
import Plus from '@/assets/icons/plus.svg?component';
import { computed, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    title: string;
    subtitle?: string;
    buttonLabel: string;
    contentExists: boolean;
    showButtonAfterContentExists?: boolean;
  }>(),
  {
    showButtonAfterContentExists: false,
  },
);

const emits = defineEmits<{
  (event: 'expand'): void;
}>();

const buttonVisible = computed(() => {
  if (props.showButtonAfterContentExists && props.contentExists) return true;
  return !props.contentExists;
});
</script>

<template>
  <section class="rounded-2xl bg-white p-4 shadow-imperium-border flex flex-col gap-4">
    <div>
      <h3 class="text-base font-semibold text-imperium-fg-base">{{ props.title }}</h3>
      <p
        v-if="props.subtitle"
        class="text-imperium-fg-muted text-xs"
      >
        {{ props.subtitle }}
      </p>
    </div>
    <slot name="toast" />
    <transition name="slide">
      <div v-if="props.contentExists">
        <slot name="content" />
      </div>
    </transition>
    <div
      v-if="buttonVisible"
      class="flex justify-between"
    >
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        @click="emits('expand')"
      >
        {{ props.buttonLabel }}
        <template #leftIcon>
          <Plus class="h-5 w-5" />
        </template>
      </Button>
      <slot name="footer" />
    </div>
  </section>
</template>
