import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M3.333 5.292c0-1.048 0-1.572.155-1.99a2.5 2.5 0 0 1 1.48-1.48c.419-.155.942-.155 1.99-.155h4.851c.442 0 .866.175 1.179.488l3.19 3.19c.313.313.488.737.488 1.179v1.393c0 .23-.186.416-.416.416h-.834A.417.417 0 0 1 15 7.917v-.834a.417.417 0 0 0-.417-.416H12.5a.833.833 0 0 1-.834-.834V3.75a.417.417 0 0 0-.416-.417H6.958c-.544 0-.877.001-1.129.017-.238.014-.292.038-.284.035a.83.83 0 0 0-.494.493 1.3 1.3 0 0 0-.035.285C5 4.414 5 4.747 5 5.292v9.416c0 .545 0 .878.016 1.13.014.237.038.291.035.284a.83.83 0 0 0 .494.493c.006.003.072.023.284.036.252.015.585.016 1.129.016h.958c.23 0 .417.187.417.417v.833a.417.417 0 0 1-.4.416h-.975c-1.048 0-1.571 0-1.99-.154a2.5 2.5 0 0 1-1.48-1.48c-.155-.42-.155-.943-.155-1.99zm4.601 13.042h-.008zm-.008 0H7.91z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M7.916 12.917a4.167 4.167 0 0 1 8.334 0c0 .932-.33 1.74-.834 2.5l.584.238 2.211 2.211a.417.417 0 0 1 0 .59l-.59.589a.417.417 0 0 1-.588 0l-2.208-2.208-.242-.587c-.76.504-1.568.833-2.5.833a4.167 4.167 0 0 1-4.167-4.166m4.167-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }