import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M5 3.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v9.67a3.001 3.001 0 0 1 0 5.66v1.67a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1.67a3.001 3.001 0 0 1 0-5.66zM5 16a1 1 0 1 1 2 0 1 1 0 0 1-2 0M18.5 3a.5.5 0 0 1 .5.5v7.67a3.001 3.001 0 0 1 0 5.66v3.67a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-3.67a3.001 3.001 0 0 1 0-5.66V3.5a.5.5 0 0 1 .5-.5zM17 14a1 1 0 1 1 2 0 1 1 0 0 1-2 0m-4 6.5v-9.67a3.001 3.001 0 0 0 0-5.66V3.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.67a3.001 3.001 0 0 0 0 5.66v9.67a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5M12 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }