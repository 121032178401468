import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M0 10.06c.001 4.95 3.578 9.164 8.437 9.94v-7.032H5.9V10.06h2.54V7.847a3.56 3.56 0 0 1 .971-2.853 3.52 3.52 0 0 1 2.802-1.071q1.127.019 2.24.2v2.475h-1.264a1.44 1.44 0 0 0-1.19.393 1.46 1.46 0 0 0-.438 1.18v1.89h2.77l-.442 2.908H11.56V20c5.254-.835 8.938-5.664 8.384-10.987S14.79-.265 9.478.013C4.166.294.001 4.709 0 10.06"
    }, null, -1)
  ])))
}
export default { render: render }