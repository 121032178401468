<template>
  <div
    :class="
      tableContainerClass({
        rounded: props.isRounded,
        topRounded: props.topRounded,
      })
    "
  >
    <table
      :class="
        tableClass({
          rounded: props.isRounded,
          topRounded: props.topRounded,
        })
      "
    >
      <slot />
    </table>
  </div>
</template>

<script setup lang="ts">
import { tv } from 'tailwind-variants';

const props = withDefaults(
  defineProps<{
    isSticky?: boolean;
    isRounded?: boolean;
    topRounded?: boolean;
  }>(),
  {
    isSticky: false,
    isRounded: false,
    topRounded: false,
  },
);

const tableClass = tv({
  base: 'table-fixed w-full max-w-full text-base text-left overflow-hidden tracking-[-0.1px]',
  variants: {
    rounded: {
      true: 'rounded-2xl',
    },
    topRounded: {
      true: 'rounded-t-2xl',
    },
  },
});

const tableContainerClass = tv({
  base: '',
  variants: {
    sticky: {
      true: 'overflow-x-scroll',
    },
    rounded: {
      true: 'rounded-2xl',
    },
    topRounded: {
      true: 'rounded-t-2xl',
    },
  },
});
</script>
