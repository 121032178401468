import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M15.295 8.419a.42.42 0 0 0 0-.59L10 2.5 4.705 7.83a.42.42 0 0 0 0 .589l.645.649a.413.413 0 0 0 .586 0L8.57 6.424l.681-1.216-.08 1.442v7.933c0 .23.186.417.415.417h.83a.416.416 0 0 0 .414-.417V6.65l-.079-1.442.68 1.216 2.634 2.644a.413.413 0 0 0 .586 0z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M2.5 14.658v-.712c0-.183 0-.274.028-.346a.42.42 0 0 1 .238-.239c.073-.028.164-.028.346-.028h.442c.183 0 .274 0 .346.028.11.043.196.13.239.239.028.072.028.163.028.346v.712c0 .569 0 .917.017 1.18.016.248.042.303.039.296a.83.83 0 0 0 .476.476c.007.003.075.025.297.039.262.017.61.018 1.179.018h7.65c.569 0 .917-.001 1.179-.018.248-.016.304-.041.297-.039a.83.83 0 0 0 .476-.476c.003-.007.025-.075.039-.297.016-.262.017-.61.017-1.179v-.712c0-.183 0-.274.028-.346a.42.42 0 0 1 .239-.239c.072-.028.163-.028.346-.028h.441c.183 0 .274 0 .347.028.11.043.196.13.238.239.028.072.028.163.028.346v.712c0 1.095 0 1.642-.168 2.077a2.5 2.5 0 0 1-1.43 1.43c-.435.168-.982.168-2.077.168h-7.65c-1.094 0-1.642 0-2.077-.168a2.5 2.5 0 0 1-1.43-1.43C2.5 16.3 2.5 15.753 2.5 14.658"
    }, null, -1)
  ])))
}
export default { render: render }