import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M1.468 3.945c-.135.348-.135.786-.135 1.661v4.787c0 .876 0 1.314.135 1.662a2 2 0 0 0 1.144 1.143c.348.135.786.135 1.661.135h7.454c.875 0 1.313 0 1.661-.135a2 2 0 0 0 1.144-1.143c.135-.348.135-.786.135-1.662V5.606c0-.875 0-1.313-.135-1.661A2 2 0 0 0 13.388 2.8c-.348-.135-.786-.135-1.661-.135H4.273c-.875 0-1.313 0-1.661.135a2 2 0 0 0-1.144 1.144M11.727 4H4.273c-.455 0-.733 0-.943.014-.199.013-.243.033-.237.03a.67.67 0 0 0-.381.382c.002-.006-.018.038-.031.237-.014.21-.014.489-.014.943v4.787c0 .278 0 .49.003.66l2.86-2.857c.26-.26.682-.26.942 0l.862.862 2.529-2.529c.26-.26.682-.26.943 0l2.528 2.529v-3.45c0-.455 0-.734-.014-.944-.013-.198-.033-.243-.03-.237a.67.67 0 0 0-.382-.381 1 1 0 0 0-.237-.031A17 17 0 0 0 11.727 4m-7.454 8c-.277 0-.49 0-.66-.004L6 9.61l.862.862c.26.26.682.26.943 0l2.528-2.528 2.999 2.998a8 8 0 0 1-.013.395 1 1 0 0 1-.03.238.67.67 0 0 1-.382.38c.006-.001-.038.019-.237.032-.21.013-.488.014-.943.014z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }