import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M3.479 2.667c.074 0 .133.06.133.133v2.4c0 .074-.06.133-.133.133h-.377A.133.133 0 0 1 2.97 5.2V3.28a.012.012 0 0 0-.018-.01l-.391.239a.133.133 0 0 1-.203-.114V3.15c0-.046.024-.09.063-.114l.562-.35a.13.13 0 0 1 .07-.02zM2.355 9.333A.133.133 0 0 1 2.22 9.2v-.27c0-.038.017-.075.046-.1l.927-.812a3 3 0 0 0 .186-.18.7.7 0 0 0 .117-.167.42.42 0 0 0-.007-.373.3.3 0 0 0-.133-.122.4.4 0 0 0-.194-.043.4.4 0 0 0-.194.045.32.32 0 0 0-.16.203c-.02.071-.076.132-.15.132h-.343c-.074 0-.135-.06-.126-.133a.8.8 0 0 1 .458-.642 1.2 1.2 0 0 1 .517-.108q.304 0 .528.102t.346.284q.124.18.124.42 0 .153-.061.302-.062.15-.22.331a5 5 0 0 1-.448.434l-.316.29a.012.012 0 0 0 .008.02h.936c.074 0 .133.06.133.134V9.2c0 .074-.06.133-.133.133zm.827 4.037q-.304 0-.54-.104a.9.9 0 0 1-.37-.29.7.7 0 0 1-.126-.292c-.013-.073.048-.134.123-.134h.386c.071 0 .126.059.161.121l.022.034a.35.35 0 0 0 .142.104q.09.037.205.037a.5.5 0 0 0 .201-.04.34.34 0 0 0 .138-.113.3.3 0 0 0 .049-.167.26.26 0 0 0-.054-.166.35.35 0 0 0-.155-.114.6.6 0 0 0-.234-.04h-.126a.133.133 0 0 1-.133-.133v-.192c0-.074.06-.133.133-.133h.126a.5.5 0 0 0 .208-.04.33.33 0 0 0 .142-.109.27.27 0 0 0 .05-.164.28.28 0 0 0-.164-.264.48.48 0 0 0-.372 0 .33.33 0 0 0-.16.142c-.033.064-.088.125-.16.125h-.352c-.074 0-.136-.062-.122-.135a.7.7 0 0 1 .12-.285.87.87 0 0 1 .354-.285q.225-.102.513-.103.287 0 .504.1a.8.8 0 0 1 .339.274.66.66 0 0 1 .12.39q.003.225-.146.37a.65.65 0 0 1-.37.179.01.01 0 0 0-.01.011q.001.01.011.012.303.039.457.2.16.163.158.41a.66.66 0 0 1-.137.411.9.9 0 0 1-.378.281q-.24.102-.553.102m2.152-9.703c0-.184.15-.334.333-.334h8c.184 0 .334.15.334.334v.666c0 .185-.15.334-.334.334h-8a.333.333 0 0 1-.333-.334zm0 4c0-.184.15-.334.333-.334h8c.184 0 .334.15.334.334v.666c0 .185-.15.334-.334.334h-8a.333.333 0 0 1-.333-.334zm8.333 3.666c.184 0 .334.15.334.334v.666c0 .184-.15.334-.334.334h-8a.333.333 0 0 1-.333-.334v-.666c0-.184.15-.334.333-.334z"
    }, null, -1)
  ])))
}
export default { render: render }