import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10M6.663 8.43c-1.028.455-2.083.922-3.049 1.461-.504.375.166.64.795.888h.001l.29.117.236.076c.7.225 1.478.477 2.157.098 1.115-.649 2.167-1.4 3.218-2.15l.012-.009q.51-.365 1.023-.725l.04-.026.014-.01c.295-.194.959-.63.713-.029-.58.644-1.2 1.213-1.825 1.786l-.002.001c-.421.386-.844.774-1.257 1.187-.36.296-.733.892-.33 1.307.924.656 1.864 1.297 2.802 1.937l.008.005.918.628c.518.42 1.328.08 1.441-.576l.15-.892.003-.012c.28-1.666.562-3.333.81-5.005q.052-.392.11-.784V7.7c.093-.636.186-1.272.215-1.912-.075-.638-.837-.497-1.262-.354a195 195 0 0 0-6.492 2.667q-.365.164-.74.329",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }