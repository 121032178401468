import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m3.334 4.584-.417 1.351s.597-1.174.783-1.389a8.333 8.333 0 1 1-1.866 7.12h1.71a6.667 6.667 0 1 0 1.114-5.655l-.737.766.663-.11H6.25c.23 0 .417.187.417.417v.833c0 .23-.187.417-.417.417H1.667V2.916c0-.23.187-.417.417-.417h.833c.23 0 .417.187.417.417z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M10 9.899c0 .11-.044.217-.122.295l-2.88 2.88a.417.417 0 0 0 0 .59l.589.589a.417.417 0 0 0 .589 0l3.369-3.369a.42.42 0 0 0 .122-.295V5.833a.417.417 0 0 0-.417-.416h-.833a.417.417 0 0 0-.417.416z"
    }, null, -1)
  ])))
}
export default { render: render }