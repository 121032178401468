import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M8 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M6.5 13a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M6.41 3c-1.313 0-1.97 0-2.492.202a3 3 0 0 0-1.716 1.716C2 5.44 2 6.097 2 7.41v9.18c0 1.313 0 1.97.202 2.492a3 3 0 0 0 1.716 1.716C4.44 21 5.097 21 6.41 21h11.18c1.313 0 1.97 0 2.492-.202a3 3 0 0 0 1.716-1.716C22 18.56 22 17.903 22 16.59V7.41c0-1.313 0-1.97-.202-2.492a3 3 0 0 0-1.716-1.716C19.56 3 18.903 3 17.59 3zM11 5h6.59c.682 0 1.1.001 1.415.021.266.017.348.043.356.046a1 1 0 0 1 .572.572c-.004-.008.027.058.046.356.02.315.021.733.021 1.415v9.18c0 .682-.001 1.1-.021 1.415-.017.266-.043.348-.046.356a1 1 0 0 1-.572.572c.008-.004-.058.027-.356.046-.315.02-.733.021-1.415.021H11zM9 5v14H6.41c-.682 0-1.1-.001-1.415-.021-.266-.017-.348-.043-.356-.046a1 1 0 0 1-.572-.572c.004.008-.027-.058-.046-.356A25 25 0 0 1 4 16.59V7.41c0-.682.001-1.1.021-1.415.02-.298.05-.364.046-.356a1 1 0 0 1 .572-.572c-.008.004.058-.027.356-.046C5.31 5.001 5.728 5 6.41 5z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }