import { EditorView } from 'prosemirror-view';
import type { LinkRange } from '@/features/TipTapEditor/types/link';

export const getLinkMark = (view: EditorView, pos: number): any | null => {
  const resolvedPos = view.state.doc.resolve(pos);
  return resolvedPos.marks().find((mark) => mark.type.name === 'link') || null;
};

export const getLinkRange = (view: EditorView, pos: number): LinkRange => {
  let linkStart = pos;
  let linkEnd = pos;

  while (linkStart > 0 && view.state.doc.nodeAt(linkStart - 1)?.marks.some((mark) => mark.type.name === 'link')) {
    linkStart -= 1;
  }

  while (
    linkEnd < view.state.doc.content.size &&
    view.state.doc.nodeAt(linkEnd)?.marks.some((mark) => mark.type.name === 'link')
  ) {
    linkEnd += 1;
  }

  const linkText = view.state.doc.textBetween(linkStart, linkEnd);
  return { linkStart, linkEnd, linkText };
};
