import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M8 2.5a.5.5 0 0 0-.5-.5H6.41c-1.313 0-1.97 0-2.492.202a3 3 0 0 0-1.716 1.716C2 4.44 2 5.097 2 6.41V7.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V6.41c0-.682.001-1.1.021-1.415.02-.298.05-.364.046-.356a1 1 0 0 1 .572-.572c-.008.004.058-.027.356-.046C5.31 4.001 5.728 4 6.41 4H7.5a.5.5 0 0 0 .5-.5zM16.5 4h1.09c.682 0 1.1.001 1.415.021.266.017.348.043.356.046a1 1 0 0 1 .572.572c-.004-.008.027.058.046.356.02.315.021.733.021 1.415V7.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V6.41c0-1.313 0-1.97-.202-2.492a3 3 0 0 0-1.716-1.716C19.56 2 18.903 2 17.59 2H16.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5M2 16.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1.09c0 .682.001 1.1.021 1.415.02.298.05.364.046.356a1 1 0 0 0 .572.572c.008.003.09.029.356.046.315.02.733.021 1.415.021H7.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H6.41c-1.313 0-1.97 0-2.492-.202a3 3 0 0 1-1.716-1.716C2 19.56 2 18.903 2 17.59z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12 13a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m0-2a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M16.93 18c.25 0 .439-.227.372-.468a5.502 5.502 0 0 0-10.604 0c-.066.24.123.468.373.468h1.272c.305 0 .568-.201.704-.474a3.3 3.3 0 0 1 5.907 0c.136.273.4.474.704.474zM20 16.5v1.09c0 .682-.001 1.1-.021 1.415-.017.266-.043.348-.046.356a1 1 0 0 1-.572.572c.008-.004-.058.027-.356.046-.315.02-.733.021-1.415.021H16.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1.09c1.313 0 1.97 0 2.492-.202a3 3 0 0 0 1.716-1.716C22 19.56 22 18.903 22 17.59V16.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5"
    }, null, -1)
  ])))
}
export default { render: render }