<template>
  <div class="flex flex-col">
    <div class="flex mb-4 mx-2 justify-between full-width">
      <FormInput
        id="categories-search"
        :model-value="searchQuery"
        placeholder="Search for categories"
        @update:modelValue="(value) => updateSearchQuery(value)"
      >
        <template #prefix>
          <Search class="text-imperium-fg-muted" />
        </template>
        <template #suffix> <span class="text-imperium-fg-muted">⌘+K</span> </template>
      </FormInput>
      <div>
        <Button
          :size="SIZES.MEDIUM"
          data-testid="create-new-role-modal-show"
          @click="goToCategoryCreate"
        >
          <template #leftIcon>
            <PlusIcon class="w-5 h-5" />
          </template>

          New category
        </Button>
      </div>
    </div>

    <CategoriesTable
      :categories="categories?.items"
      :is-loading="isLoading"
      :is-fetching="isFetching"
      :total="categories.total"
      @redirect="goToCategoryEdit"
    />
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import Button from '@/components/Button.vue';
import { SIZES } from '@/types';

import PlusIcon from '@/assets/icons/plus.svg?component';
import Search from '@/assets/icons/search.svg?component';

import FormInput from '@/components/FormInput.vue';
import CategoriesTable from '@/features/Categories/components/CategoriesTable.vue';
import { useCategoryTranslatesFilters } from '@/features/Categories/stores/filters.store';
import { useCategoryTranslatesPagePagination } from '@/features/Categories/stores/pagination.store';
import { computed, ComputedRef } from 'vue';
import type { CategoriesFetchRequest, CategoriesFetchResponse } from '@/features/Categories/types';
import { useFetchCategories } from '@/features/Categories/queries';
import { DEFAULT_LANGUAGE_ID } from '@/constants/queries';
import { OnSuccessCallback } from '@/features/Categories/queries/useFetchCategories';
import { debounce } from 'lodash';

const filtersStore = useCategoryTranslatesFilters();
const paginationStore = useCategoryTranslatesPagePagination();
const router = useRouter();

const categoriesSort = computed(() => {
  const sortFields = filtersStore.state.sort;

  if (!Object.keys(sortFields)[0]) {
    return;
  }

  if (!Object.values(sortFields)[0]) {
    return;
  }

  return {
    value: Object.keys(sortFields)[0],
    direction: Object.values(sortFields)[0],
  };
});

const searchQuery = computed(() => filtersStore.state?.searchQuery || '');
const updateSearchQuery = debounce((searchQuery: string) => {
  filtersStore.setSearchQuery(searchQuery);
}, 300);

const categoriesPayload: ComputedRef<CategoriesFetchRequest> = computed(() => {
  const payload = {
    languageId: DEFAULT_LANGUAGE_ID,
    page: paginationStore.state.page,
    itemsPerPage: paginationStore.state.limit,
    pagination: true,
  } as CategoriesFetchRequest;

  if (searchQuery.value) {
    payload['filter[search]'] = searchQuery.value;
  }

  if (categoriesSort.value) {
    payload[`order[${categoriesSort.value.value}]`] = categoriesSort.value.direction;
  }

  return payload;
});

const onSuccessFetchCallback: OnSuccessCallback = (result) => {
  const typedResult = result as CategoriesFetchResponse;

  if (!typedResult) {
    return;
  }

  paginationStore.setState({
    page: typedResult.page || 1,
    total: typedResult.total || 10,
  });
};

const { data: categories, isLoading, isFetching } = useFetchCategories(categoriesPayload, onSuccessFetchCallback);

const goToCategoryEdit = (categoryId: number) => {
  router.push({
    name: 'categories-edit',
    params: {
      id: categoryId,
    },
  });
};

const goToCategoryCreate = () => {
  router.push({
    name: 'categories-create',
  });
};
</script>
