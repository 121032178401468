import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M16.224 8.954a.5.5 0 0 1 .14.693l-4.126 6.188a1.2 1.2 0 0 1-1.91.115l-2.503-2.92a.5.5 0 0 1 .055-.705l.759-.65a.5.5 0 0 1 .705.054l1.816 2.118 3.539-5.31a.5.5 0 0 1 .693-.138z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12.5 3a7.5 7.5 0 0 0-7.083 5.028A6 6 0 0 0 6 20h13.5a4.5 4.5 0 0 0 .482-8.974A7.494 7.494 0 0 0 12.5 3m-5.542 7.03C7.605 7.288 9.43 5 12.5 5a5.5 5.5 0 0 1 5.5 5.5c0 .715-.17 1.407-.34 2.097l-.098.403H19.5a2.5 2.5 0 0 1 0 5H6a4 4 0 0 1-4-4c0-2.86 2.513-4.33 4.958-3.97",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }