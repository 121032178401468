<template>
  <div
    :class="!isDraft ? 'justify-between' : 'justify-end'"
    class="flex w-full lg:items-center mb-4 flex-col lg:flex-row px-4"
  >
    <a
      v-if="!isDraft"
      :color="BUTTON_COLOR.BASE"
      :visual-type="BUTTON_TYPE.PLAIN"
      target="_blank"
      :href="converterStore.state?.converter?.websiteLink"
    >
      <div class="flex gap-1 font-semibold text-imperium-ds-secondary-base items-center">
        <span>{{ converterStore.state?.converter?.title }}</span>
        <OpenLinkIcon class="w-4 h-4" />
      </div>
    </a>
    <ConverterPageHeaderButtons
      @update="() => emits('update')"
      @un-publish="() => emits('unPublish')"
      @publish="() => emits('publish')"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useConverterPage } from '@/features/Converter/stores/converter.store';

import OpenLinkIcon from '@/assets/icons/open-link.svg?component';

import { BUTTON_COLOR, BUTTON_TYPE } from '@/types';
import { ConverterStatus } from '@/features/Converter/types';
import Button from '@/components/Button.vue';
import ConverterPageHeaderButtons from '@/features/Converter/components/ConverterPage/ConverterPageHeaderButtons.vue';

const emits = defineEmits<{
  (event: 'update'): void;
  (event: 'publish'): void;
  (event: 'unPublish'): void;
}>();

const converterStore = useConverterPage();

const isDraft = computed(() => {
  return converterStore.state.converter.status === ConverterStatus.DRAFT || !converterStore.state.converter.status;
});
</script>
