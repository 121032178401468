import { useQuery } from '@tanstack/vue-query';
import type { ComputedRef } from 'vue';

import { CONVERTERS_RESOURCE } from '@/features/Converter/constants';
import type { ConverterItemFetchPayload } from '@/features/Converter/types/api';
import { ConverterService } from '@/features/Converter/services';
import { watch } from 'vue';

export const useFetchConverterItem = (params: ComputedRef<ConverterItemFetchPayload>, onSuccess?: () => void) => {
  const { data, isLoading } = useQuery({
    queryKey: [CONVERTERS_RESOURCE, params],

    queryFn: ({ queryKey }) => {
      const payload: ConverterItemFetchPayload = queryKey[1] as ConverterItemFetchPayload;

      return ConverterService.fetchConverter(payload);
    },
    refetchInterval: false,
    enabled: !!params.value.id,
    refetchOnMount: 'always',
    staleTime: 0,
    gcTime: 0,
  });

  watch(
    data,
    (newData) => {
      if (newData && onSuccess) {
        onSuccess();
      }
    },
    { immediate: false },
  );
  return {
    data,
    isLoading,
  };
};
