<template>
  <TableHeadRow
    :size="SIZES.MEDIUM"
    :bordered="TABLE_BORDERED.BOTTOM"
    :is-on-surface="TABLE_ON_SURFACE.PRIMARY"
  >
    <TableHeadCell
      :size="SIZES.SMALL"
      :is-sticky="!isLargeDesktop"
      is-uppercase
    >
      <SortColumnHeader
        id="id"
        label="ID"
        :value="filtersStore.state.sort.id"
        @change-sort="updateFilters({ filterName: 'id', value: $event })"
      />
    </TableHeadCell>

    <TableHeadCell
      :size="!isLargeDesktop ? SIZES.LARGE : SIZES.XLARGE"
      :scale="!isLargeDesktop ? TABLE_SCALE.DOUBLE : TABLE_SCALE.TRIPLE"
      :is-sticky="!isLargeDesktop"
      :sticky-offset-size="SIZES.SMALL"
      is-uppercase
    >
      <SortColumnHeader
        id="cryptoSymbol"
        label="CRYPTO CURRENCY"
        :value="filtersStore.state.sort.cryptoSymbol"
        @change-sort="updateFilters({ filterName: 'cryptoSymbol', value: $event })"
      />
    </TableHeadCell>
    <TableHeadCell
      :size="SIZES.LARGE"
      :scale="TABLE_SCALE.DOUBLE"
      is-uppercase
    >
      <SortColumnHeader
        id="fiatSymbol"
        label="FIAT CURRENCY"
        :value="filtersStore.state.sort.fiatSymbol"
        @change-sort="updateFilters({ filterName: 'fiatSymbol', value: $event })"
      />
    </TableHeadCell>
    <TableHeadCell
      :size="!isLargeDesktop ? SIZES.MEDIUM : SIZES.LARGE"
      :scale="!isLargeDesktop ? TABLE_SCALE.QUADRUPLE : TABLE_SCALE.DOUBLE"
      is-uppercase
    >
      <SortColumnHeader
        id="isPublished"
        label="STATUS"
        :value="filtersStore.state.sort.status"
        @change-sort="updateFilters({ filterName: 'status', value: $event })"
      />
    </TableHeadCell>
    <TableHeadCell
      :size="!isLargeDesktop ? SIZES.MEDIUM : SIZES.LARGE"
      :scale="!isLargeDesktop ? TABLE_SCALE.QUADRUPLE : TABLE_SCALE.DOUBLE"
      is-uppercase
    >
      <SortColumnHeader
        id="isFaqPublished"
        label="FAQ"
        :value="filtersStore.state.sort.isFaqPublished"
        @change-sort="updateFilters({ filterName: 'isFaqPublished', value: $event })"
      />
    </TableHeadCell>
    <TableHeadCell
      :size="!isLargeDesktop ? SIZES.MEDIUM : SIZES.LARGE"
      :scale="!isLargeDesktop ? TABLE_SCALE.QUADRUPLE : TABLE_SCALE.DOUBLE"
      is-uppercase
    >
      <SortColumnHeader
        id="isAboutPublished"
        label="ABOUT"
        :value="filtersStore.state.sort.isAboutPublished"
        @change-sort="updateFilters({ filterName: 'isAboutPublished', value: $event })"
      />
    </TableHeadCell>
    <TableHeadCell
      class="cursor-default"
      is-full-width
      is-uppercase
    >
      WEBSITE LINK
    </TableHeadCell>
  </TableHeadRow>
</template>
<script setup lang="ts">
import { inject } from 'vue';
import { useConvertersFilters } from '@/features/Converter/stores/convertersFilter.store';
import SortColumnHeader from '@/components/Table/Filters/SortColumnHeader.vue';
import { TableHeadRow, TableHeadCell } from '@/components/Table';
import { SIZES, TABLE_ON_SURFACE, type SortStatus, TABLE_BORDERED, TABLE_SCALE } from '@/types';

const isLargeDesktop = inject<boolean>('isLargeDesktop');

const filtersStore = useConvertersFilters();

const updateFilters = ({ filterName, value }: { filterName: string; value: SortStatus }) => {
  filtersStore.setSorts({
    [filterName]: value,
  });
};
</script>
