import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M15.24 16.466a.1.1 0 0 0-.152-.033 7.5 7.5 0 1 1 1.345-1.345.1.1 0 0 0 .033.152l.256.132q.277.143.499.364l3.908 3.908a.5.5 0 0 1 0 .707l-.778.778a.5.5 0 0 1-.707 0l-3.908-3.908a2 2 0 0 1-.364-.499zM16 10.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }