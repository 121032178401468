import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "25",
  height: "24",
  fill: "none",
  viewBox: "0 0 25 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M10.873 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1m4 8a1 1 0 0 0 1-1v-6a1 1 0 1 0-2 0v6a1 1 0 0 0 1 1"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M7.873 6V5a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1h3.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-.5v11a3 3 0 0 1-3 3h-10a3 3 0 0 1-3-3V8h-.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm2.293-1.707A1 1 0 0 1 10.873 4h4a1 1 0 0 1 1 1v1h-6V5a1 1 0 0 1 .293-.707M6.873 8v11a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V8z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }