import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M10 5a11.2 11.2 0 0 0-5.682 1.579c-.746.453-1.386.978-1.848 1.533-.45.54-.803 1.193-.803 1.888 0 .67.31 1.316.738 1.866.436.56 1.047 1.092 1.779 1.549C5.649 14.331 7.677 15 10 15s4.352-.67 5.817-1.585c.732-.457 1.342-.988 1.779-1.55.427-.549.738-1.194.738-1.865 0-.695-.354-1.349-.803-1.888-.463-.555-1.102-1.08-1.849-1.533A11.2 11.2 0 0 0 10 5m-6.666 5c0-.139.084-.422.416-.82.318-.383.804-.795 1.432-1.176A9.5 9.5 0 0 1 10 6.667c1.834 0 3.562.576 4.818 1.337.629.381 1.114.793 1.433 1.175.332.399.416.682.416.821 0 .163-.085.454-.387.842-.293.377-.745.784-1.346 1.16-1.202.75-2.924 1.331-4.934 1.331s-3.732-.58-4.933-1.331c-.602-.376-1.054-.783-1.346-1.16-.302-.388-.387-.68-.387-.842",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }