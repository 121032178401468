<template>
  <div class="tiptap-menu">
    <button
      :disabled="!editor?.can().undo()"
      @click="undo"
    >
      <UndoIcon class="menu-icon" />
    </button>
    <button
      :disabled="!editor?.can().redo()"
      @click="redo"
    >
      <RedoIcon class="menu-icon" />
    </button>
    <button
      :class="{ 'is-active': props.editor.isActive('bold') }"
      @click="props.editor.chain().focus().toggleBold().run()"
    >
      <BoldIcon class="menu-icon" />
    </button>
    <button
      :class="{ 'is-active': props.editor.isActive('italic') }"
      @click="props.editor.chain().focus().toggleItalic().run()"
    >
      <ItalicIcon class="menu-icon" />
    </button>
    <button
      :class="{ 'is-active': props.editor.isActive('underline') }"
      @click="props.editor.chain().focus().toggleUnderline().run()"
    >
      <UnderlineIcon class="menu-icon" />
    </button>
    <button
      :class="{ 'is-active': props.editor.isActive('underline') }"
      @click="editor.chain().focus().clearNodes().unsetAllMarks().run()"
    >
      <ClearFormattingIcon class="menu-icon" />
    </button>
    <button
      :class="{ 'is-active': props.editor.isActive('strike') }"
      @click="props.editor.chain().focus().toggleStrike().run()"
    >
      <StrikeIcon class="menu-icon" />
    </button>
    <button
      :class="{ 'is-active': props.editor.isActive('bulletList') }"
      @click="props.editor.chain().focus().toggleBulletList().run()"
    >
      <ListIcon class="menu-icon" />
    </button>
    <button
      :class="{ 'is-active': props.editor.isActive('orderedList') }"
      @click="props.editor.chain().focus().toggleOrderedList().run()"
    >
      <ListOrderedIcon class="menu-icon" />
    </button>
    <button
      :class="{ 'is-active': props.editor.isActive('link') }"
      @click="addLink"
    >
      <LinkIcon class="menu-icon" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { Editor } from '@tiptap/vue-3';
import ListIcon from '@/assets/icons/editor/list.svg?component';
import ListOrderedIcon from '@/assets/icons/editor/list-ordered.svg?component';
import ItalicIcon from '@/assets/icons/editor/italic.svg?component';
import BoldIcon from '@/assets/icons/editor/bold.svg?component';
import UnderlineIcon from '@/assets/icons/editor/underline.svg?component';
import StrikeIcon from '@/assets/icons/editor/strike.svg?component';
import UndoIcon from '@/assets/icons/editor/undo.svg?component';
import LinkIcon from '@/assets/icons/editor/link.svg?component';
import RedoIcon from '@/assets/icons/editor/redo.svg?component';
import ClearFormattingIcon from '@/assets/icons/editor/clear-formatting.svg?component';

import { getSelectionTextFromEditor } from '@/features/TipTapEditor/utils/selection';
import { useLinkTipTapStore } from '@/features/TipTapEditor/stores/link.store';
import { SelectionDetails } from '@/features/TipTapEditor/types/link';

const props = defineProps<{ editor: Editor }>();

const linkStore = useLinkTipTapStore();

const addLink = () => {
  const selection: SelectionDetails = getSelectionTextFromEditor(props.editor);
  linkStore.setSelection(selection);
  if (selection.selectedText?.length) {
    linkStore.openModal();
  }
};

const undo = () => {
  props.editor?.chain().focus().undo().run();
};

const redo = () => {
  props.editor?.chain().focus().redo().run();
};
</script>

<style scoped>
button {
  margin-right: 5px;
}
.is-active {
  @apply bg-imperium-bg-4;
}

.tiptap-menu {
  @apply flex border border-imperium-border-base bg-imperium-bg-2 px-3 py-2;

  & button {
    @apply cursor-pointer flex items-center justify-center w-8 h-7 text-imperium-fg-base hover:bg-imperium-bg-4 flex gap-2 rounded;
  }

  .menu-icon {
    @apply w-4 h-4;
  }
}
</style>
