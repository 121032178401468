import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M10.626 2.626a1 1 0 0 1 1.414 0l2.667 2.667a1 1 0 0 1 0 1.414L12.04 9.374a1 1 0 1 1-1.414-1.415l.96-.96H5.333a2.333 2.333 0 1 0 0 4.668h6a1 1 0 1 1 0 2h-6a4.334 4.334 0 0 1 0-8.667h6.253l-.96-.96a1 1 0 0 1 0-1.414",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }