import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M10 10.417a3.337 3.337 0 0 0 3.342-3.334A3.337 3.337 0 0 0 10 3.75a3.337 3.337 0 0 0-3.342 3.333A3.337 3.337 0 0 0 10 10.417m1.67-3.334c0 .92-.747 1.667-1.67 1.667s-1.67-.746-1.67-1.667c0-.92.747-1.666 1.67-1.666s1.67.746 1.67 1.666",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M5.843 16.25A4.174 4.174 0 0 1 10 12.5a4.174 4.174 0 0 1 4.156 3.75.45.45 0 0 0 .438.417h.836c.23 0 .42-.187.403-.417a5.8 5.8 0 0 0-.868-2.666c.257-.159.559-.25.883-.25.78 0 1.435.533 1.619 1.254.057.223.238.412.47.412h.834c.231 0 .421-.187.392-.416a3.34 3.34 0 0 0-3.315-2.917 3.33 3.33 0 0 0-1.966.637A5.83 5.83 0 0 0 10 10.834c-1.49 0-2.85.555-3.883 1.47a3.33 3.33 0 0 0-1.965-.637 3.34 3.34 0 0 0-3.316 2.917c-.029.229.161.416.392.416h.835c.23 0 .413-.19.47-.412a1.67 1.67 0 0 1 2.5-1.004 5.8 5.8 0 0 0-.867 2.666.395.395 0 0 0 .403.417h.836c.23 0 .415-.187.438-.416"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M4.152 11.25a2.503 2.503 0 0 0 2.506-2.5c0-1.38-1.122-2.5-2.506-2.5a2.503 2.503 0 0 0-2.506 2.5c0 1.38 1.122 2.5 2.506 2.5m1.044-2.5c0 .575-.467 1.042-1.044 1.042A1.043 1.043 0 0 1 3.108 8.75a1.043 1.043 0 0 1 2.088 0m13.158 0c0 1.38-1.122 2.5-2.506 2.5a2.503 2.503 0 0 1-2.507-2.5c0-1.38 1.122-2.5 2.507-2.5a2.503 2.503 0 0 1 2.506 2.5m-1.462 0a1.043 1.043 0 0 1-2.088 0 1.043 1.043 0 0 1 2.088 0",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }