import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M9 14a3 3 0 1 0 0-6 3 3 0 0 0 0 6m0-1.8a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M14 10a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M2.202 5.918C2 6.44 2 7.097 2 8.41v7.18c0 1.313 0 1.97.202 2.492a3 3 0 0 0 1.716 1.716C4.44 20 5.097 20 6.41 20h11.18c1.313 0 1.97 0 2.492-.202a3 3 0 0 0 1.716-1.716C22 17.56 22 16.903 22 15.59V8.41c0-1.313 0-1.97-.202-2.492a3 3 0 0 0-1.716-1.716C19.56 4 18.903 4 17.59 4H6.41c-1.313 0-1.97 0-2.492.202a3 3 0 0 0-1.716 1.716M17.59 6H6.41c-.682 0-1.1.001-1.415.021-.298.02-.364.05-.356.046a1 1 0 0 0-.572.572c.004-.008-.027.058-.046.356C4.001 7.31 4 7.728 4 8.41v7.18c0 .682.001 1.1.021 1.415.02.298.05.364.046.356a1 1 0 0 0 .572.572c.008.003.09.029.356.046l.135.007a4.002 4.002 0 0 1 7.744.014h4.716c.682 0 1.1-.001 1.415-.021.298-.02.364-.05.356-.046a1 1 0 0 0 .572-.572c.003-.008.029-.09.046-.356.02-.315.021-.733.021-1.415V8.41c0-.682-.001-1.1-.021-1.415-.02-.298-.05-.364-.046-.356a1 1 0 0 0-.572-.572c-.008-.003-.09-.029-.356-.046A25 25 0 0 0 17.59 6",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }