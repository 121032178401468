import type { Editor } from '@tiptap/vue-3';
import type { SelectionDetails } from '@/features/TipTapEditor/types/link';

export const getSelectionTextFromEditor = (editor: Editor): SelectionDetails | null => {
  if (!editor) {
    return null;
  }

  const selection = editor.state.selection;

  const { from, to } = selection;
  const selectedText = editor.state.doc.textBetween(from, to, ' ');

  return {
    selection: { from: selection.from, to: selection.to },
    selectedText,
  };
};
