import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M5.374 2.626a1 1 0 0 1 0 1.414l-.96.96h6.253a4.333 4.333 0 0 1 0 8.667h-6a1 1 0 1 1 0-2h6a2.333 2.333 0 1 0 0-4.667H4.414l.96.96A1 1 0 1 1 3.96 9.373L1.293 6.707a1 1 0 0 1 0-1.414L3.96 2.626a1 1 0 0 1 1.414 0",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }