import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M14.167 3.333h.018c1.047.002 1.788.012 2.37.095a2.5 2.5 0 0 0-2.388-1.761H5.833a2.5 2.5 0 0 0-2.389 1.76c.583-.082 1.324-.092 2.371-.093h8.352M7.5 8.386c0-.386.498-.606.847-.375l4.941 3.28a.438.438 0 0 1 0 .75l-4.94 3.281c-.35.232-.848.011-.848-.375z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M1.835 6.598c-.168.435-.168.983-.168 2.077v5.983c0 1.095 0 1.642.168 2.077a2.5 2.5 0 0 0 1.43 1.43c.435.168.982.168 2.077.168h9.316c1.095 0 1.642 0 2.077-.168a2.5 2.5 0 0 0 1.43-1.43c.168-.435.168-.982.168-2.077V8.675c0-1.094 0-1.642-.168-2.077a2.5 2.5 0 0 0-1.43-1.43C16.3 5 15.753 5 14.658 5H5.342C4.247 5 3.7 5 3.265 5.168a2.5 2.5 0 0 0-1.43 1.43m12.823.069H5.342c-.569 0-.917 0-1.18.017-.248.016-.303.042-.296.039a.83.83 0 0 0-.477.476c.003-.007-.022.048-.038.297-.017.262-.018.61-.018 1.179v5.983c0 .569.001.917.018 1.18.016.248.041.303.038.296a.83.83 0 0 0 .477.476c.007.003.075.025.297.039.262.017.61.018 1.179.018h9.316c.569 0 .917-.001 1.18-.018.235-.015.296-.039.296-.039a.83.83 0 0 0 .476-.476c.003-.007.025-.075.039-.297.017-.262.018-.61.018-1.179V8.675c0-.569-.001-.917-.018-1.179a1.2 1.2 0 0 0-.039-.297.83.83 0 0 0-.476-.476 1.3 1.3 0 0 0-.297-.039c-.262-.017-.61-.017-1.179-.017",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }