import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M7.723 14.658a.5.5 0 0 1-.7 0l-.658-.645a.5.5 0 0 1 0-.714L9.855 9.9a.985.985 0 0 1 1.374 0l2.227 2.181 2.821-2.739a.5.5 0 0 1 .7 0l.658.645a.5.5 0 0 1 0 .714l-3.491 3.4a.985.985 0 0 1-1.373 0l-2.228-2.183z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M3 7.41c0-1.313 0-1.97.202-2.492a3 3 0 0 1 1.716-1.716C5.44 3 6.097 3 7.41 3h9.18c1.313 0 1.97 0 2.492.202a3 3 0 0 1 1.716 1.716C21 5.44 21 6.097 21 7.41v9.18c0 1.313 0 1.97-.202 2.492a3 3 0 0 1-1.716 1.716C18.56 21 17.903 21 16.59 21H7.41c-1.313 0-1.97 0-2.492-.202a3 3 0 0 1-1.716-1.716C3 18.56 3 17.903 3 16.59zM7.41 5h9.18c.682 0 1.1.001 1.415.021.266.017.348.043.356.046a1 1 0 0 1 .572.572c-.004-.008.027.058.046.356.02.315.021.733.021 1.415v9.18c0 .682-.001 1.1-.021 1.415-.017.266-.043.348-.046.356a1 1 0 0 1-.572.572s-.073.028-.356.046c-.315.02-.733.021-1.415.021H7.41c-.682 0-1.1-.001-1.415-.021-.266-.017-.348-.043-.356-.046a1 1 0 0 1-.572-.572c.004.008-.027-.058-.046-.356A25 25 0 0 1 5 16.59V7.41c0-.682.001-1.1.021-1.415.02-.298.05-.364.046-.356a1 1 0 0 1 .572-.572c-.008.004.058-.027.356-.046C6.31 5.001 6.728 5 7.41 5",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }