import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useSimpleAction, useToast } from '@/composables';
import type { ConverterTableItem } from '@/features/Converter/types';
import { ConverterService } from '@/features/Converter/services';
import { useLanguagesStore } from '@/stores/languages.store';

interface ConvertersState {
  converters: ConverterTableItem[];
}

export const useConvertersPage = defineStore('convertersPage', () => {
  const state = ref<ConvertersState>({
    converters: [],
  });
  const toast = useToast();

  const languagesStore = useLanguagesStore();
  const languages = computed(() => languagesStore.state);
  const languageId = computed(() => (languages?.value ? languages?.value![0].id : 0));

  const { isLoading: isConvertersListLoading, action: fetchConvertersList } = useSimpleAction<any>(
    async ({ paginationStore, filtersStore }) => {
      try {
        const params = {
          page: paginationStore.state.page,
          itemsPerPage: paginationStore.state.limit,
          'filter[search]': filtersStore.state.searchQuery,
          sort: filtersStore.state.sort,
          languageId: languageId.value,
          pagination: true,
        };

        if (!filtersStore.state.searchQuery) {
          delete params['filter[search]'];
        }
        const { items, limit, total, page } = await ConverterService.converters(params);

        state.value.converters = items;
        paginationStore.setState({ page, limit, total });
      } catch (err) {
        toast.errorTemporary({
          id: 'ERROR_CONVERTORS_LOAD_ERROR',
          message: 'Unable to load converters',
        });
      }
    },
  );

  return {
    isConvertersListLoading,
    fetchConvertersList,
    state,
  };
});
