import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "17",
  fill: "none",
  viewBox: "0 0 24 17"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M11.64 7.327c-.165.024-.33-.067-.375-.227l-.323-1.155a.284.284 0 0 1 .211-.36c2.778-.562 5.487 1.903 5.028 4.809l1.268.2a.2.2 0 0 1 .087.359l-2.255 1.64a.5.5 0 0 1-.698-.11l-1.64-2.255a.2.2 0 0 1 .192-.315l1.268.2c.257-1.625-1.212-3.012-2.764-2.786m.721 4.813c.164-.024.33.067.375.227l.323 1.156a.284.284 0 0 1-.211.359c-2.778.562-5.487-1.903-5.029-4.808l-1.268-.2a.2.2 0 0 1-.086-.36l2.255-1.64a.5.5 0 0 1 .698.11l1.64 2.255a.2.2 0 0 1-.192.315l-1.269-.2c-.256 1.625 1.213 3.013 2.764 2.786"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12.5 0a7.5 7.5 0 0 0-7.083 5.028A6 6 0 0 0 6 17h13.5a4.5 4.5 0 0 0 .482-8.974A7.494 7.494 0 0 0 12.5 0M6.958 7.03C7.605 4.288 9.43 2 12.5 2A5.5 5.5 0 0 1 18 7.5c0 .854-.152 2.088-.152 2.088s2.549.278 3.415 1.14A2.5 2.5 0 0 1 19.5 15H6a4 4 0 0 1-4-4.001c0-2.86 2.513-4.33 4.958-3.97",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }