import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M16.604 6.445A7.52 7.52 0 0 0 10 2.5a7.5 7.5 0 0 0-7.048 4.93.32.32 0 0 0 .284.426l.93.065a.53.53 0 0 0 .515-.319A5.83 5.83 0 0 1 10 4.167a5.85 5.85 0 0 1 5.333 3.469l-.742-.355-1.238-.174a.417.417 0 0 0-.47.354l-.117.825a.417.417 0 0 0 .355.471l4.538.64.698-4.952a.417.417 0 0 0-.355-.47l-.825-.117a.417.417 0 0 0-.47.355l-.175 1.238zm-13.209 7.11A7.52 7.52 0 0 0 10 17.5a7.5 7.5 0 0 0 7.048-4.93.32.32 0 0 0-.285-.425l-.93-.066a.53.53 0 0 0-.514.319A5.83 5.83 0 0 1 10 15.833a5.85 5.85 0 0 1-5.333-3.469l.743.355 1.238.174a.417.417 0 0 0 .47-.354l.117-.825a.417.417 0 0 0-.355-.471l-4.538-.64-.698 4.952a.417.417 0 0 0 .354.47l.826.117a.417.417 0 0 0 .47-.355l.175-1.238z"
    }, null, -1)
  ])))
}
export default { render: render }