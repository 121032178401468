import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M2 3.667c0-.185.15-.334.333-.334H3c.184 0 .333.15.333.334v.666A.334.334 0 0 1 3 4.667h-.667A.333.333 0 0 1 2 4.333zm0 4c0-.185.15-.334.333-.334H3c.184 0 .333.15.333.334v.666A.334.334 0 0 1 3 8.667h-.667A.333.333 0 0 1 2 8.333zm1 3.666c.184 0 .333.15.333.334v.666a.334.334 0 0 1-.333.334h-.667A.333.333 0 0 1 2 12.333v-.666c0-.184.15-.334.333-.334zm1.667-7.666c0-.185.149-.334.333-.334h8.667c.184 0 .333.15.333.334v.666c0 .184-.15.334-.333.334H5a.333.333 0 0 1-.333-.334zm0 4c0-.185.149-.334.333-.334h8.667c.184 0 .333.15.333.334v.666c0 .184-.15.334-.333.334H5a.333.333 0 0 1-.333-.334zm9 3.666c.184 0 .333.15.333.334v.666c0 .184-.15.334-.333.334H5a.333.333 0 0 1-.333-.334v-.666c0-.184.149-.334.333-.334z"
    }, null, -1)
  ])))
}
export default { render: render }