import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "5",
  height: "5",
  fill: "currentColor",
  "aria-hidden": "true",
  viewBox: "0 0 16 3"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3M14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3" }, null, -1)
  ])))
}
export default { render: render }