import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ArtEditorDashboardTable, CoverDeadline, DASHBOARD_STATE_NAME, DashboardTab } from '@/features/Dashboard/types';
import type { Article } from '@/features/Articles/types';
import { DocumentStatus } from '@/types';
import { setLocalStorageItem } from '@/services/localStorage';

const ART_EDITOR_DASHBOARD_ITEMS_PER_PAGE = 50;

interface DashboardTable {
  items: Article[];
  itemsPerPage: number;
  loadedPages: number;
  total?: number;
  filters: Record<string, any>;
}

interface DashboardState {
  dashboardType: DashboardTab;
  isLoadingDashboardType: boolean;
  [ArtEditorDashboardTable['10MIN']]: DashboardTable;
  [ArtEditorDashboardTable['30MIN']]: DashboardTable;
  [ArtEditorDashboardTable['24HOURS']]: DashboardTable;
  [ArtEditorDashboardTable['PENDING_APPROVAL']]: DashboardTable;
  [ArtEditorDashboardTable['PUBLISHED']]: DashboardTable;
  [ArtEditorDashboardTable['ALL_ARTICLES']]: DashboardTable;
}

export const useDashboardStore = defineStore('useDashboardStore', () => {
  const state = ref<DashboardState>({
    dashboardType: DashboardTab.ARTICLES,
    isLoadingDashboardType: true,
    [ArtEditorDashboardTable['10MIN']]: {
      items: [],
      itemsPerPage: ART_EDITOR_DASHBOARD_ITEMS_PER_PAGE,
      loadedPages: 1,
      filters: {
        'filter[coverDeadline]': CoverDeadline['10M'],
        'filter[status]': [
          DocumentStatus.NEW,
          DocumentStatus.IN_PROGRESS,
          DocumentStatus.READY_FOR_ART_EDITOR,
          DocumentStatus.UNPUBLISHED,
          DocumentStatus.IMAGE_REJECTED,
          DocumentStatus.TEXT_REJECTED,
        ],
      },
    },
    [ArtEditorDashboardTable['30MIN']]: {
      items: [],
      itemsPerPage: ART_EDITOR_DASHBOARD_ITEMS_PER_PAGE,
      loadedPages: 1,
      filters: {
        'filter[coverDeadline]': CoverDeadline['30M'],
        'filter[status]': [
          DocumentStatus.NEW,
          DocumentStatus.IN_PROGRESS,
          DocumentStatus.READY_FOR_ART_EDITOR,
          DocumentStatus.UNPUBLISHED,
          DocumentStatus.IMAGE_REJECTED,
          DocumentStatus.TEXT_REJECTED,
        ],
      },
    },
    [ArtEditorDashboardTable['24HOURS']]: {
      items: [],
      itemsPerPage: ART_EDITOR_DASHBOARD_ITEMS_PER_PAGE,
      loadedPages: 1,
      filters: {
        'filter[coverDeadline]': CoverDeadline['24H'],
        'filter[status]': [
          DocumentStatus.NEW,
          DocumentStatus.IN_PROGRESS,
          DocumentStatus.READY_FOR_ART_EDITOR,
          DocumentStatus.UNPUBLISHED,
          DocumentStatus.IMAGE_REJECTED,
          DocumentStatus.TEXT_REJECTED,
        ],
      },
    },
    [ArtEditorDashboardTable['PENDING_APPROVAL']]: {
      items: [],
      itemsPerPage: ART_EDITOR_DASHBOARD_ITEMS_PER_PAGE,
      loadedPages: 1,
      filters: {
        'filter[status]': [DocumentStatus.READY_TO_REVIEW],
      },
    },
    [ArtEditorDashboardTable['PUBLISHED']]: {
      items: [],
      itemsPerPage: ART_EDITOR_DASHBOARD_ITEMS_PER_PAGE,
      loadedPages: 1,
      filters: {
        'filter[status]': [DocumentStatus.PUBLISHED],
      },
    },
    [ArtEditorDashboardTable['ALL_ARTICLES']]: {
      items: [],
      itemsPerPage: ART_EDITOR_DASHBOARD_ITEMS_PER_PAGE,
      loadedPages: 1,
    },
  });

  const setDashboardType = (value: DashboardTab): void => {
    state.value.dashboardType = value;
    state.value.isLoadingDashboardType = false;
    setLocalStorageItem<DashboardTab>(DASHBOARD_STATE_NAME, value);
  };

  const setDashboardArtEditorValues = ({ name, items, itemsPerPage = 0, total = 0 }) => {
    state.value[name].items = items;
    state.value[name].total = total;
  };

  const addDashboardArtEditorValues = ({ name, items, itemsPerPage = 0, total = 0 }) => {
    state.value[name].items = [...state.value[name].items, ...items];
    state.value[name].total = total;
  };

  return {
    state,
    setDashboardType,
    setDashboardArtEditorValues,
    addDashboardArtEditorValues,
  };
});
