import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M10 18.333a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 0 0 0 16.667m4.583-7.5c.23 0 .417-.186.417-.417v-.833a.417.417 0 0 0-.417-.417H5.417A.417.417 0 0 0 5 9.583v.833c0 .23.186.417.417.417z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }