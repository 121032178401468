<script setup lang="ts">
import ExclamationShapes from '@/assets/icons/exclamation-shapes.svg?component';
import ModalHolder from '@/components/ModalHolder.vue';
import { BUTTON_COLOR, BUTTON_TYPE, SIZES } from '@/types';
import Button from '@/components/Button.vue';

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'delete'): void;
  (event: 'close'): void;
}>();
</script>

<template>
  <ModalHolder
    :is-visible="props.isVisible"
    :size="SIZES.SMALL"
    data-testid="converter-update-publish-modal"
    @close="emits('close')"
    @wheel.prevent
  >
    <div class="flex flex-col items-center justify-center">
      <ExclamationShapes class="text-imperium-bg-5" />
      <div class="mt-4 text-imperium-fg-base font-normal text-center">
        Are you sure you want to delete all questions?
      </div>
      <div class="flex gap-2 my-4 items-center justify-center">
        <Button
          :color="BUTTON_COLOR.DEFAULT"
          :size="SIZES.MEDIUM"
          data-testid="converter-update-publish-button"
          :visual-type="BUTTON_TYPE.TERTIARY"
          @click="emits('close')"
        >
          Cancel
        </Button>
        <Button
          :size="SIZES.MEDIUM"
          :visual-type="BUTTON_TYPE.PRIMARY"
          :color="BUTTON_COLOR.RED"
          type="submit"
          data-testid="converter-update-publish-button"
          @click="emits('delete')"
        >
          Yes, delete
        </Button>
      </div>
    </div>
  </ModalHolder>
</template>

<style scoped lang="scss"></style>
