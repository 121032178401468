export const LABELS_RESOURCE = 'labels-resource';
export const LABEL_RESOURCE = 'label-resource';

export const LABEL_FIELD_NAME = 'title';
export const LABEL_FIELD_ID = 'id';
export const LABEL_FIELD_DESCRIPTION = 'description';

export const LABEL_TRANSLATE_FIELD_NAME = 'title';
export const LABEL_TRANSLATE_FIELD_ID = 'id';
export const LABEL_TRANSLATE_FIELD_BADGE = 'badge';
