import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M12.811 10a.5.5 0 0 1 .5.5v5h1a.5.5 0 0 1 .5.5v.5a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5V16a.5.5 0 0 1 .5-.5h1v-4h-1a.5.5 0 0 1-.5-.5v-.5a.5.5 0 0 1 .5-.5zM12 8.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10m0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }