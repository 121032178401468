<template>
  <ConverterPage :converter-id="converterId" />
</template>
<script setup lang="ts">
import ConverterPage from '@/features/Converter/components/ConverterPage/ConverterPage.vue';

import { useRoute } from 'vue-router';

const route = useRoute();
const converterId = Number(route.params?.id);
</script>
