import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { LinkAttributes, ModalLinkAttributes, SelectionDetails } from '@/features/TipTapEditor/types/link';

interface State {
  isModalVisible: boolean;
  selection?: SelectionDetails;
  modalLinkAttributes?: ModalLinkAttributes;
}

export const useLinkTipTapStore = defineStore('useLinkTipTapStore', () => {
  const state = ref<State>({
    isModalVisible: false,
    selection: null,
    modalLinkAttributes: null,
  });

  const openModal = () => {
    state.value.isModalVisible = true;
  };

  const closeModal = () => {
    state.value.isModalVisible = false;
    state.value.modalLinkAttributes = null;
  };

  const setSelection = (selection: SelectionDetails) => {
    state.value.selection = selection;
  };

  const cleanSelection = () => {
    state.value.selection = null;
  };

  const setLinkAttributes = (value: LinkAttributes) => {
    state.value.modalLinkAttributes = value;
  };

  const cleanLinkAttributes = () => {
    state.value.modalLinkAttributes = null;
  };

  return {
    state,
    openModal,
    closeModal,
    setSelection,
    setLinkAttributes,
    cleanSelection,
    cleanLinkAttributes,
  };
});
