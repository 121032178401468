import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m16.044 5.558-.21-.974v-1.25c0-.23.186-.417.416-.417h.833c.23 0 .417.186.417.416v5h-4.583a.417.417 0 0 1-.417-.416v-.833c0-.23.187-.417.417-.417h1.25l.784.247a5.833 5.833 0 1 0 .392 5.43.44.44 0 0 1 .44-.266l.877.088a.396.396 0 0 1 .34.532 7.5 7.5 0 1 1-.956-7.14"
    }, null, -1)
  ])))
}
export default { render: render }