import { Link } from '@tiptap/extension-link';
import { Plugin } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { useLinkTipTapStore } from '@/features/TipTapEditor/stores/link.store';
import { getLinkMark, getLinkRange } from '@/features/TipTapEditor/utils/link';

const CustomLink = Link.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      target: {
        default: '_blank',
        parseHTML: (element: HTMLElement) => element.getAttribute('target'),
        renderHTML: (attributes: Record<string, any>) => {
          return { target: attributes.target };
        },
      },
      rel: {
        default: 'nofollow noopener',
        parseHTML: (element: HTMLElement) => element.getAttribute('rel'),
        renderHTML: (attributes: Record<string, any>) => {
          return { rel: attributes.rel };
        },
      },
      title: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('title'),
        renderHTML: (attributes: Record<string, any>) => {
          return { title: attributes.title };
        },
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleClick: (view: EditorView, pos: number, event: MouseEvent) => {
            const linkStore = useLinkTipTapStore();

            const target = event.target as HTMLElement;
            if (target?.tagName === 'A') {
              event.preventDefault();

              const linkMark = getLinkMark(view, pos);
              if (linkMark) {
                const { linkStart, linkEnd, linkText } = getLinkRange(view, pos);
                updateLinkStore(target, linkStart, linkEnd, linkText, linkStore);

                linkStore.openModal();
              }

              return true;
            }
            return false;
          },
        },
      }),
    ];
  },
});

const updateLinkStore = (target: HTMLElement, linkStart: number, linkEnd: number, linkText: string, linkStore) => {
  linkStore.setSelection({
    selection: { from: linkStart, to: linkEnd },
    selectedText: linkText,
  });

  linkStore.setLinkAttributes({
    link: target.getAttribute('href') || '',
    isNofollow: target.getAttribute('rel') === 'nofollow noopener',
    isInNewTab: target.getAttribute('target') === '_blank',
    title: target.getAttribute('title') || '',
  });
};

export default CustomLink;
