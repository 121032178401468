<script setup lang="ts">
import CollapsableSection from '@/components/Collapsable/CollapsableSection.vue';
import { computed, ref } from 'vue';
import { SIZES } from '@/types';
import FormCheckbox from '@/components/FormCheckbox.vue';
import TiptapEditor from '@/features/TipTapEditor/TiptapEditor.vue';
import { useConverterPage } from '@/features/Converter/stores/converter.store';

const props = defineProps<{
  converterId: number;
}>();

const converterStore = useConverterPage();

const sectionTitle = computed<string>(() => {
  return converterStore.state.converter.cryptoSymbol
    ? `About ${converterStore.state.converter.cryptoSymbol}`
    : 'About section';
});

const initialText = computed(() => converterStore.state.converter.aboutDescription);
const isPublished = computed(() => converterStore.state.converter.isAboutPublished);

const sectionVisible = ref<boolean>(initialText.value ?? false);

const updateAbout = (value: string) => {
  onFieldUpdate(value, 'aboutDescription');
  converterStore.updateConverterData({ aboutDescription: value });
};

const updatePublish = (value: boolean) => {
  onFieldUpdate(value, 'isAboutPublished');
  converterStore.updateConverterData({ isAboutPublished: value });
};

const onFieldUpdate = (val: string | boolean, field: string) => {
  converterStore.onFieldUpdate(val, field, props.converterId);
};
</script>

<template>
  <CollapsableSection
    :title="sectionTitle"
    :content-exists="sectionVisible"
    button-label="About"
    @expand="sectionVisible = true"
  >
    <template #content>
      <p class="mb-2 text-sm text-imperium-fg-base font-semibold">General information about the coin</p>
      <div class="flex gap-4 flex-col">
        <TiptapEditor
          :init-text="initialText"
          @update="updateAbout"
        />
        <FormCheckbox
          name="Publish"
          :size="SIZES.SMALL"
          :model-value="isPublished"
          @update:model-value="updatePublish"
        >
          <span class="text-sm text text-imperium-fg-base">Published</span>
        </FormCheckbox>
      </div>
    </template>
  </CollapsableSection>
</template>
