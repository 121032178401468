import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "160",
  height: "160",
  fill: "none",
  viewBox: "0 0 160 160"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      "clip-path": "url(#a)",
      "clip-rule": "evenodd"
    }, [
      _createElementVNode("path", { d: "M159 160h1v-40h-1v39h-39v1zm1-159V0h-40v1h39v39h1zM1 0H0v40h1V1h39V0zM0 159v1h40v-1H1v-39H0z" })
    ], -1),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "a" }, [
        _createElementVNode("path", {
          fill: "#fff",
          d: "M0 0h160v160H0z"
        })
      ])
    ], -1)
  ])))
}
export default { render: render }