import type { ComputedRef } from 'vue';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/vue-query';
import type { CategoriesFetchRequest, CategoriesFetchResponse } from '@/features/Categories/types';
import { fetchCategories } from '@/features/Categories/api';
import { CATEGORIES_RESOURCE } from '@/features/Categories/constants';

export type OnSuccessCallback = (result: CategoriesFetchResponse) => void;

export const useFetchCategories = (
  params: ComputedRef<CategoriesFetchRequest> | unknown,
  onSuccess: OnSuccessCallback | null = null,
) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [CATEGORIES_RESOURCE, params], // params is plain object
    queryFn: async ({ queryKey, signal }) => {
      const payload: CategoriesFetchRequest = queryKey[1] as CategoriesFetchRequest;
      const result = await fetchCategories({ ...payload }, { signal });

      if (onSuccess) {
        onSuccess(result);
      }

      return result;
    },
    placeholderData: keepPreviousData,
  });

  return {
    data,
    isLoading,
    isFetching,
  };
};

export const useCategoriesInvalidation = () => {
  const queryClient = useQueryClient();

  const invalidateCategories = async () => {
    await queryClient.invalidateQueries({ queryKey: [CATEGORIES_RESOURCE] });
  };

  return {
    invalidateCategories,
  };
};
