import { type ComputedRef, type Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import type { BreadcrumbItem } from '@/features/Breadcrumbs/types';
import { useI18n } from 'vue-i18n';

const breadcrumbs = ref<BreadcrumbItem[]>([]);

export const useBreadcrumbStore = () => {
  const route = useRoute();
  const { t } = useI18n();

  const initBreadcrumbs = (): void => {
    const crumbs: BreadcrumbItem[] = [
      {
        name: 'home',
        title: t('breadcrumbs.home'),
        to: '/',
        active: route.fullPath === '/',
      },
    ];

    if (route.matched.length) {
      const mappedMatchedRoutes = route.matched
        .map((matchedRoute) => {
          return {
            name: matchedRoute.name?.toString() || '',
            title: t(`breadcrumbs.${matchedRoute.name?.toString()}` || ''),
            to: matchedRoute.path,
            active: matchedRoute.path === route.fullPath,
          };
        })
        .filter((crumb) => crumb.name);

      crumbs.push(...mappedMatchedRoutes);
    }

    breadcrumbs.value = crumbs;
  };

  initBreadcrumbs();

  const setBreadcrumbsLatestElement = (item: BreadcrumbItem): void => {
    breadcrumbs.value.splice(breadcrumbs.value.length - 1, 1, item);
  };

  const createBreadcrumbsWatcher = (title: Ref<string> | ComputedRef<string>, name: string) =>
    watch(
      title,
      (value: string | unknown) => {
        if (!value) return;

        setBreadcrumbsLatestElement({
          title: value,
          name: name,
        } as BreadcrumbItem);
      },
      {
        immediate: true,
      },
    );

  return { breadcrumbs, setBreadcrumbsLatestElement, createBreadcrumbsWatcher };
};
