import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M2 4.667C2 3.75 2.75 3 3.667 3H17a1.667 1.667 0 0 1 1.667 1.667v10c0 .5-.167.833-.5 1.166a.83.83 0 0 1-.75.5H3.667A1.667 1.667 0 0 1 2 14.667zm5.75 10 3.167-4.5-3.334-3.584a.833.833 0 0 0-1.25.084L3.667 10.5V4.667H17V13l-2.75-3.083A.833.833 0 0 0 13 10l-3.333 4.667z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }