import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M17 7c-.002-1.046-.022-1.617-.202-2.082a3 3 0 0 0-1.716-1.715C14.56 3 13.903 3 12.59 3H7.41c-1.313 0-1.97 0-2.492.203a3 3 0 0 0-1.716 1.715C3 5.44 3 6.098 3 7.41v5.18c0 1.314 0 1.97.202 2.493a3 3 0 0 0 1.716 1.715c.465.18 1.036.2 2.082.202.002 1.047.022 1.618.202 2.083a3 3 0 0 0 1.716 1.715c.522.203 1.179.203 2.492.203h5.18c1.313 0 1.97 0 2.492-.203a3 3 0 0 0 1.716-1.715C21 18.56 21 17.903 21 16.59v-5.18c0-1.314 0-1.97-.202-2.493a3 3 0 0 0-1.716-1.715c-.465-.18-1.036-.2-2.082-.202m-4.41-2H7.41c-.682 0-1.1.001-1.415.022-.298.019-.364.05-.356.046a1 1 0 0 0-.572.572c.004-.009-.027.057-.046.356C5.001 6.31 5 6.728 5 7.41v5.18c0 .683.001 1.1.021 1.415.02.298.05.365.046.356a1 1 0 0 0 .572.572c.008.003.09.03.356.046.246.016.556.02 1.005.021v-3.59c0-1.313 0-1.97.202-2.492a3 3 0 0 1 1.716-1.715C9.44 7 10.097 7 11.41 7H15a17 17 0 0 0-.021-1.004c-.02-.299-.05-.365-.046-.356a1 1 0 0 0-.572-.572c-.008-.003-.09-.03-.356-.046C13.69 5.002 13.272 5 12.59 5m-1.18 4h5.18c.682 0 1.1.001 1.415.022.266.017.348.043.356.046a1 1 0 0 1 .572.572c-.004-.009.027.057.046.356.02.314.021.732.021 1.415v5.18c0 .682-.001 1.1-.021 1.414-.017.267-.043.348-.046.356a1 1 0 0 1-.572.572s-.073.028-.356.046c-.315.02-.733.021-1.415.021h-5.18c-.682 0-1.1 0-1.415-.02a1.6 1.6 0 0 1-.356-.047 1 1 0 0 1-.572-.572c.004.009-.027-.058-.046-.356A25 25 0 0 1 9 16.59v-5.18c0-.682.001-1.1.021-1.414.02-.299.05-.365.046-.356a1 1 0 0 1 .572-.572c-.008.003.058-.027.356-.046.315-.02.733-.022 1.415-.022",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }