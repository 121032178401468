import 'vanillajs-datepicker/css/datepicker.css';
import '@/assets/toast.scss';
import '@/assets/main.scss';
import '@/assets/prosemirror.scss';
import 'eventsource/example/eventsource-polyfill';
import 'flowbite';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createSentryPiniaPlugin } from '@sentry/vue';

import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { VueQueryPlugin } from '@tanstack/vue-query';
import Toast from 'vue-toastification';

import App from './App.vue';

import { i18n, setI18nLanguage } from '@/i18n';
import { router } from '@/router';
import { initSentry } from '@/plugins/sentry';
import { sentryDsn } from './config';

import { initAuthErrorsHandling } from '@/features/Auth/errors';
import { toastConfig } from './plugins/toast';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(createSentryPiniaPlugin());
const app = createApp(App);

app.use(pinia);
app.use(i18n);
app.use(VueQueryPlugin);
app.use(Toast, toastConfig);

if (sentryDsn) {
  initSentry(app, router);
}

// XXX: For now only one language, but lang switcher can be added further
setI18nLanguage(i18n, 'en');

app.use(router);

router.isReady().then(() => {
  initAuthErrorsHandling(router);

  app.mount('#app');
});
