<template>
  <div
    v-if="editor"
    class="tiptap"
  >
    <tiptap-editor-menu :editor="editor" />

    <editor-content
      :editor="editor"
      class="border border-imperium-border-base border-t-0 resize-y overflow-auto relative"
    >
      <div
        id="resize-handle"
        class="absolute bottom-0 right-0 cursor-nwse-resize bg-transparent"
      >
        <ResizeIcon class="text-[#303D43] w-3 h-3" />
      </div>
    </editor-content>

    <link-menu-item :editor="editor" />
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import BulletList from '@tiptap/extension-bullet-list';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Paragraph from '@tiptap/extension-paragraph';
import CustomLink from '@/features/TipTapEditor/editorExtentions/linkExtention';
import TiptapEditorMenu from '@/features/TipTapEditor/components/TiptapEditorMenu.vue';
import { exportProseMirrorTextToXML } from '@/features/TipTapEditor/utils/exportToXML';
import { handleEnterDown } from '@/features/TipTapEditor/editorPlugins/handleEnterDown';
import LinkMenuItem from '@/features/TipTapEditor/components/InsertLink.vue';
import ResizeIcon from '@/assets/icons/editor/resize.svg?component';

const props = defineProps<{ initText: string }>();

const emits = defineEmits<{
  (event: 'update', value: string): void;
}>();

const editor = ref<Editor | null>(null);

const handleEditorUpdate = () => {
  if (editor.value) {
    const content = exportProseMirrorTextToXML(editor.value);
    emits('update', content);
  }
};

onMounted(() => {
  editor.value = new Editor({
    extensions: [
      Bold,
      BulletList,
      Italic,
      Strike,
      Underline,
      ListItem,
      OrderedList,
      StarterKit.configure({
        bulletList: false,
        orderedList: false,
        listItem: false,
      }),
      CustomLink.configure({
        openOnClick: true,
      }),
      Paragraph,
    ],
    editorProps: {
      attributes: {
        class: 'block-mode',
      },
      handleKeyDown: handleEnterDown,
    },
    content: props.initText,
    onUpdate: handleEditorUpdate,
  });
});

onBeforeUnmount(() => {
  if (editor.value) {
    editor.value.destroy();
  }
});
</script>

<style lang="scss" scoped>
.tiptap {
  :deep(a[href]) {
    @apply border-b-2 border-imperium-ds-primary-base no-underline cursor-pointer;
  }

  :deep(ol) {
    list-style-type: decimal;
    padding-left: 20px;
    margin: 0;
  }

  :deep(ul) {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
  }
}
</style>
