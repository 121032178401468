import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M16.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M22 5.243a3 3 0 0 0-.88-2.122l-.242-.242A3 3 0 0 0 18.757 2h-4.515a3 3 0 0 0-2.121.879l-8 8a3 3 0 0 0 0 4.242l4.757 4.758a3 3 0 0 0 4.243 0l8-8A3 3 0 0 0 22 9.757zM11.707 18.465l8-8A1 1 0 0 0 20 9.756V5.243a1 1 0 0 0-.293-.707l-.243-.243A1 1 0 0 0 18.757 4h-4.515a1 1 0 0 0-.707.293l-8 8a1 1 0 0 0 0 1.414l4.757 4.758a1 1 0 0 0 1.415 0",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }