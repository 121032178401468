import { ref, computed } from 'vue';
import { type SelectItem } from '@/components/FormSelect.vue';
import type { ArticleCategoriesFetchRequest } from '../types';

import { useFetchCategories } from '@/features/ArticleLayout/queries';

export const useCategories = () => {
  const categoriesPayload = ref<ArticleCategoriesFetchRequest>({ languageId: 1, 'filter[relevant]': true });
  const { data: categoryTranslates } = useFetchCategories(categoriesPayload);

  const categories = computed<SelectItem[]>(() => {
    if (!categoryTranslates.value?.items?.length) {
      return [];
    }

    return categoryTranslates.value.items.map((categoryTranslate) => ({
      id: categoryTranslate.id,
      label: categoryTranslate.title,
    }));
  });

  return {
    categories,
  };
};
