import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M2 8.41c0-1.313 0-1.97.202-2.492a3 3 0 0 1 1.716-1.716C4.44 4 5.097 4 6.41 4h11.18c1.313 0 1.97 0 2.492.202a3 3 0 0 1 1.716 1.716C22 6.44 22 7.097 22 8.41v7.18c0 1.313 0 1.97-.202 2.492a3 3 0 0 1-1.716 1.716C19.56 20 18.903 20 17.59 20H6.41c-1.313 0-1.97 0-2.492-.202a3 3 0 0 1-1.716-1.716C2 17.56 2 16.903 2 15.59zM6.41 6h11.18c.682 0 1.1.001 1.415.021.266.017.348.043.356.046l.06.026-6.785 5.588a1 1 0 0 1-1.272 0L4.58 6.093l.06-.026c-.008.004.058-.027.356-.046C5.31 6.001 5.728 6 6.41 6M4 8.207v7.383c0 .682.001 1.1.021 1.415.02.298.05.364.046.356a1 1 0 0 0 .572.572c.008.003.09.029.356.046.315.02.733.021 1.415.021h11.18c.682 0 1.1-.001 1.415-.021.298-.02.364-.05.356-.046a1 1 0 0 0 .572-.572c.003-.008.029-.09.046-.356.02-.315.021-.733.021-1.415V8.207l-6.093 5.018a3 3 0 0 1-3.814 0z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }