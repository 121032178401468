import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M13 3.5a.5.5 0 0 0 .5.5h5.015l-7.904 7.904a.5.5 0 0 0 0 .707l.778.778a.5.5 0 0 0 .707 0L20 5.485V10.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V2h-8.5a.5.5 0 0 0-.5.5z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M7.41 3H9.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H7.41c-.682 0-1.1.001-1.415.021-.298.02-.364.05-.356.046a1 1 0 0 0-.572.572c.004-.008-.027.058-.046.356C5.001 6.31 5 6.728 5 7.41v9.18c0 .682.001 1.1.021 1.415.02.298.05.364.046.356a1 1 0 0 0 .572.572c.008.003.09.029.356.046.315.02.733.021 1.415.021h9.18c.682 0 1.1-.001 1.415-.021.283-.018.357-.047.357-.047a1 1 0 0 0 .57-.571c.004-.008.03-.09.047-.356.02-.315.021-.733.021-1.415V14.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2.09c0 1.313 0 1.97-.202 2.492a3 3 0 0 1-1.716 1.716C18.56 21 17.903 21 16.59 21H7.41c-1.313 0-1.97 0-2.492-.202a3 3 0 0 1-1.716-1.716C3 18.56 3 17.903 3 16.59V7.41c0-1.313 0-1.97.202-2.492a3 3 0 0 1 1.716-1.716C5.44 3 6.097 3 7.41 3"
    }, null, -1)
  ])))
}
export default { render: render }