import { extractData, http } from '@/http';
import type { LabelsFetchRequest, LabelsFetchResponse, LabelFetchParam, LabelTranslate } from '@/features/Labels/types';

export const fetchLabels = (params: LabelsFetchRequest): Promise<LabelsFetchResponse> =>
  http.get<LabelsFetchResponse>('/badges', { params, useAuth: true }).then(extractData);

export const fetchLabel = ({ labelId }: LabelFetchParam) =>
  http
    .get<LabelTranslate>(`/badges/${labelId}`, {
      useAuth: true,
    })
    .then(extractData);

export const createLabel = (label: LabelTranslate) =>
  http
    .post<LabelTranslate>(`/categories`, label, {
      useAuth: true,
    })
    .then(extractData);

export const updateLabel = (labelId: string | number, label: LabelTranslate) =>
  http
    .patch<LabelTranslate>(`/categories/${labelId}`, label, {
      useAuth: true,
    })
    .then(extractData);
