<template>
  <Breadcrumbs :items="breadcrumbs as BreadcrumbItem[]" />
</template>
<script setup lang="ts">
import Breadcrumbs from '@/features/Breadcrumbs/components/ui/Breadcrumbs.vue';
import { useBreadcrumbStore } from '@/features/Breadcrumbs/store';
import { BreadcrumbItem } from '@/features/Breadcrumbs/types';

const { breadcrumbs } = useBreadcrumbStore();
</script>
