import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "21",
  fill: "none",
  viewBox: "0 0 20 21"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M7.708 11.333a1.042 1.042 0 1 1-2.083 0 1.042 1.042 0 0 1 2.083 0m3.334 0a1.042 1.042 0 1 1-2.084 0 1.042 1.042 0 0 1 2.084 0m3.333 0a1.042 1.042 0 1 1-2.083 0 1.042 1.042 0 0 1 2.083 0m-6.667 3.334a1.042 1.042 0 1 1-2.083 0 1.042 1.042 0 0 1 2.083 0M10 15.708a1.042 1.042 0 1 0 0-2.083 1.042 1.042 0 0 0 0 2.083m3.333 0a1.042 1.042 0 1 0 0-2.083 1.042 1.042 0 0 0 0 2.083"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M5.833 2.583c0-.23.187-.416.417-.416h.833c.23 0 .417.186.417.416v1.25h5v-1.25c0-.23.187-.416.417-.416h.833c.23 0 .417.186.417.416v1.25c.871.002 1.348.019 1.735.169a2.5 2.5 0 0 1 1.43 1.43c.168.435.168.982.168 2.076v7.65c0 1.095 0 1.642-.168 2.077a2.5 2.5 0 0 1-1.43 1.43c-.435.168-.982.168-2.077.168h-7.65c-1.094 0-1.642 0-2.077-.168a2.5 2.5 0 0 1-1.43-1.43C2.5 16.8 2.5 16.253 2.5 15.158v-7.65c0-1.094 0-1.641.168-2.077a2.5 2.5 0 0 1 1.43-1.43c.387-.15.864-.166 1.735-.167zM13.825 5.5h-7.65c-.569 0-.917 0-1.179.018-.249.016-.304.04-.297.038a.83.83 0 0 0-.476.477c.003-.008-.023.048-.039.296-.014.23-.017.527-.017.981.425-.143.97-.143 2.008-.143h7.65c1.038 0 1.583 0 2.008.143 0-.454-.002-.75-.017-.98-.016-.25-.042-.305-.039-.297a.83.83 0 0 0-.476-.477 1.3 1.3 0 0 0-.297-.038 21 21 0 0 0-1.179-.018m2.008 5.342c0-.569 0-.917-.017-1.18-.016-.248-.042-.303-.039-.296a.83.83 0 0 0-.476-.477 1.3 1.3 0 0 0-.297-.038 21 21 0 0 0-1.179-.018h-7.65c-.569 0-.917.001-1.179.018-.249.016-.304.041-.297.038a.83.83 0 0 0-.476.477c.003-.007-.023.048-.039.297-.017.262-.017.61-.017 1.179v4.316c0 .569 0 .917.017 1.18.016.248.042.303.039.296a.83.83 0 0 0 .476.476c.007.003.075.025.297.039.262.017.61.018 1.179.018h7.65c.569 0 .917-.001 1.179-.018.236-.015.297-.039.297-.039a.83.83 0 0 0 .476-.476c.003-.007.025-.075.039-.297.016-.262.017-.61.017-1.179z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }