import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "8",
  height: "6",
  fill: "none",
  viewBox: "0 0 8 6"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M7.194 4.805a.5.5 0 0 0 0-.707L4 .904.805 4.098a.5.5 0 0 0 0 .707l.39.39a.5.5 0 0 0 .706 0L4 3.094l2.098 2.1a.5.5 0 0 0 .707 0z"
    }, null, -1)
  ])))
}
export default { render: render }