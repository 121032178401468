import {
  type CategoryTranslateForm,
  type CategoryTranslateFormPayload,
  type CategoryTranslate,
} from '@/features/Categories/types';
import {
  CATEGORY_API_CREATE_FIELD_LABELS,
  CATEGORY_FIELD_BADGES,
  CATEGORY_FIELD_LABELS,
} from '@/features/Categories/constants';
import { DEFAULT_LANGUAGE_ID } from '@/constants/queries';

export const transformFormToCategoryPayload = (form: CategoryTranslateForm): CategoryTranslateFormPayload => {
  const formCopy: Record<string, unknown> = { ...form };

  if (formCopy[CATEGORY_FIELD_BADGES]) {
    formCopy[CATEGORY_API_CREATE_FIELD_LABELS] = formCopy[CATEGORY_FIELD_BADGES];
    delete formCopy[CATEGORY_FIELD_BADGES];
  }

  formCopy.languageId = DEFAULT_LANGUAGE_ID;

  return formCopy as unknown as CategoryTranslateFormPayload;
};

export const transformCategoryResponseToForm = (category: CategoryTranslate): CategoryTranslateForm => {
  const categoryCopy: Record<string, unknown> = { ...category };

  if (Array.isArray(categoryCopy[CATEGORY_FIELD_BADGES])) {
    categoryCopy[CATEGORY_FIELD_BADGES] = categoryCopy[CATEGORY_FIELD_BADGES].map(({ id }) => id) as number[];
  } else {
    categoryCopy[CATEGORY_FIELD_BADGES] = [];
  }

  return categoryCopy as unknown as CategoryTranslateForm; // Use unknown for casting
};
