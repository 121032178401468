import { computed } from 'vue';
import { useFetchUsers } from '@/features/Users/queries';
import { type UsersFetchPayload } from '@/features/Users/types';
import { mapUsersToSelectItem } from '@/features/Users/utils';
import { ROLES } from '@/types';
import { useFetchRoles } from '@/features/Roles/queries';

type UsersParams = {
  roles: Array<
    | ROLES.ROLE_WRITER
    | ROLES.ROLE_SENIOR_WRITER
    | ROLES.ROLE_EDITOR
    | ROLES.ROLE_COPY_EDITOR
    | ROLES.ROLE_ART_EDITOR
    | ROLES.ROLE_EDITOR_IN_CHIEF
    | ROLES.ROLE_SUPER_ADMIN
  >;
};

export const useUsersByRoles = (params: UsersParams) => {
  const { data: roles } = useFetchRoles();

  const rolesFilter = computed(() => {
    return (
      roles?.value?.items
        .filter((role) => {
          return params.roles.includes(role.slug);
        })
        .map((role) => role.id) || []
    );
  });

  const fetchUserParams = computed<UsersFetchPayload>(() => ({
    page: 1,
    itemsPerPage: 1000,
    filters: {
      roles: rolesFilter.value,
    },
  }));
  const { data: result } = useFetchUsers(fetchUserParams);

  const users = computed(() => {
    return mapUsersToSelectItem(result.value?.items) || [];
  });

  return {
    users,
  };
};

export const useCopyEditors = () =>
  useUsersByRoles({
    roles: [ROLES.ROLE_COPY_EDITOR],
  });

export const useEditors = () =>
  useUsersByRoles({
    roles: [ROLES.ROLE_EDITOR],
  });
