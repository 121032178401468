<script setup lang="ts">
import CollapsableSection from '@/components/Collapsable/CollapsableSection.vue';
import FAQElement from '@/components/FAQ/FAQElement.vue';
import { VueDraggable } from 'vue-draggable-plus';
import { nextTick, onMounted, ref, watch, watchEffect } from 'vue';
import { BUTTON_TYPE, SIZES, THEMES } from '@/types';
import Button from '@/components/Button.vue';
import TrashIcon from '@/assets/icons/trash.svg?component';
import Toast from '@/components/Toast.vue';
import { Faq } from '@/features/Converter/types/faq';
import { useFAQStore } from '@/features/Converter/stores/faqs.store';
import { debounce } from 'lodash';
import { FaqService } from '@/features/Converter/services/faq';
import DeleteFaqSectionModal from '@/features/Converter/components/modals/DeleteFaqSectionModal.vue';

const props = defineProps<{
  converterId: number;
}>();

const faqStore = useFAQStore();

const content = ref<Faq[] | null>(null);
const deleteFaqSectionModalVisible = ref<boolean>(false);

const deleteFaqSection = () => {
  const mappedFaqIds = content.value?.map((faq) => faq.id);
  faqStore.deleteFaq(mappedFaqIds);
  deleteFaqSectionModalVisible.value = false;
  content.value = [];
};

const removeFAQItem = (id: number) => {
  faqStore.deleteFaq([id]);
  faqStore.deleteFaqItemFromState(id);
};

const updateFAQOrder = () => {
  const payload = content.value?.map((faq, index) => {
    return {
      id: faq.id,
      priority: index + 1,
    };
  });
  faqStore.updateFaqsPriority(payload);
};

onMounted(() => {
  if (props.converterId) {
    faqStore.fetchFaq(props.converterId);
  }
});

const onFieldUpdate = (val: string | boolean, field: string, id: number) => {
  faqStore.onFieldUpdate(val, field, id);
};

watchEffect(() => {
  if (faqStore.state && faqStore.state.length) {
    content.value = faqStore.state.sort((a: Faq, b: Faq) => a.priority - b.priority);
  } else if (faqStore.state && !faqStore.state.length) {
    content.value = [];
  }
});
</script>

<template>
  <CollapsableSection
    title="FAQ section"
    button-label="Add Question"
    :content-exists="content && content.length"
    show-button-after-content-exists
    with-toast
    @expand="faqStore.createFaq(Number(props.converterId))"
  >
    <template #toast>
      <Toast
        v-if="content && content.length"
        id="publish-modal-disclaimer"
        class="!z-0"
        message="Make sure the FAQs are arranged in the order they should appear on the website. If you want to change the order, drag and drop questions."
        :theme="THEMES.WARNING"
        :is-closable="false"
        is-full-width
      />
    </template>
    <template #content>
      <VueDraggable
        v-model="content"
        :animation="150"
        handle=".handle"
        class="flex flex-col gap-4"
        @update="updateFAQOrder"
      >
        <FAQElement
          v-for="item in content"
          :key="item.id"
          v-model:request="item.request"
          v-model:response="item.response"
          v-model:isPublished="item.published"
          @update:request="(val) => onFieldUpdate(val, 'request', item.id)"
          @update:response="(val) => onFieldUpdate(val, 'response', item.id)"
          @update:is-published="(val) => onFieldUpdate(val, 'isPublished', item.id)"
          @delete="removeFAQItem(item.id)"
        />
      </VueDraggable>
    </template>
    <template #footer>
      <div
        v-if="content && content.length"
        class="flex gap-2 justify-end"
      >
        <Button
          :size="SIZES.MEDIUM"
          :visual-type="BUTTON_TYPE.TERTIARY"
          class="max-w-fit text-imperium-ds-red-strong"
          @click="deleteFaqSectionModalVisible = true"
        >
          <template #leftIcon> <TrashIcon class="cursor-pointer" /></template>
          Delete all questions
        </Button>
      </div>
    </template>
  </CollapsableSection>
  <Teleport
    v-if="deleteFaqSectionModalVisible"
    to="#modals"
  >
    <DeleteFaqSectionModal
      :is-visible="deleteFaqSectionModalVisible"
      @close="deleteFaqSectionModalVisible = false"
      @delete="deleteFaqSection"
    />
  </Teleport>
</template>
