import type {
  ConverterFAQCreateRequest,
  ConverterFAQCreateResponse,
  ConverterFAQDeleteRequest,
  ConverterFAQFetchRequest,
  ConverterFAQFetchResponse,
  ConverterFAQPatchRequest,
  ConverterFAQPatchResponse,
} from '@/features/Converter/types/api';
import { createFaq, deleteFaq, fetchFaqs, patchFaq, putFaq } from '@/features/Converter/api/faq';

export const FaqService = {
  fetchFaqs: (payload: ConverterFAQFetchRequest): Promise<ConverterFAQFetchResponse> => fetchFaqs(payload),
  createFaq: (payload: ConverterFAQCreateRequest): Promise<ConverterFAQCreateResponse> => createFaq(payload),
  patchFaq: (payload: ConverterFAQPatchRequest): Promise<ConverterFAQPatchResponse> => patchFaq(payload),
  deleteFaq: (payload: ConverterFAQDeleteRequest): Promise<ConverterFAQPatchResponse> => deleteFaq(payload),
  putFaq: (payload: ConverterFAQDeleteRequest): Promise<ConverterFAQPatchResponse> => putFaq(payload),
};
