export const CATEGORIES_RESOURCE = 'categories-resource';
export const CATEGORY_RESOURCE = 'category-resource';

export const CATEGORY_FIELD_NAME = 'title';
export const CATEGORY_FIELD_ID = 'id';
export const CATEGORY_FIELD_DESCRIPTION = 'description';
export const CATEGORY_FIELD_URL = 'url';
export const CATEGORY_FIELD_META_TITLE = 'metaTitle';
export const CATEGORY_FIELD_META_DESCRIPTION = 'metaDescription';
export const CATEGORY_FIELD_META_KEYWORDS = 'metaKeywords';
export const CATEGORY_FIELD_API_META_KEYWORDS = 'keywords';
export const CATEGORY_FIELD_REMOVE_CATEGORY = 'hasShortUrl';
export const CATEGORY_FIELD_SHOW_ON_WEBSITE = 'enabled';
export const CATEGORY_FIELD_SHOW_IN_METADATA = 'relevant';
export const CATEGORY_FIELD_BADGES = 'badges';
export const CATEGORY_FIELD_LABELS = 'labels';
export const CATEGORY_API_CREATE_FIELD_LABELS = 'labelIds';
export const CATEGORY_API_FIELD_LABELS = 'badgeTranslates';
