<script setup lang="ts">
import DraggingIcon from '@/assets/icons/dragging-dots.svg?component';
import TrashIcon from '@/assets/icons/trash.svg?component';
import FormInput from '@/components/FormInput.vue';
import FormTextarea from '@/components/FormTextarea.vue';
import FormCheckbox from '@/components/FormCheckbox.vue';
import { SIZES } from '@/types';

const props = defineProps<{
  request: string;
  response: string;
  isPublished: boolean;
}>();

const emits = defineEmits<{
  (event: 'delete'): void;
  (event: 'update:request', value: string): void;
  (event: 'update:response', value: string): void;
  (event: 'update:isPublished', value: boolean): void;
}>();
</script>

<template>
  <div class="p-4 gap-4 flex border border-dotted border-imperium-border-base">
    <DraggingIcon class="handle cursor-move mt-9" />
    <div class="flex flex-col w-full gap-4">
      <FormInput
        :model-value="props.request"
        @update:model-value="(value) => emits('update:request', value)"
      >
        <template #label>Question</template>
      </FormInput>
      <FormTextarea
        name="answer"
        :model-value="props.response"
        @update:model-value="(value) => emits('update:response', value)"
      >
        <template #label>Answer</template>
      </FormTextarea>

      <FormCheckbox
        name="Publish"
        :size="SIZES.SMALL"
        :model-value="props.isPublished"
        @update:model-value="(value) => emits('update:isPublished', value)"
      >
        <span class="text-sm text text-imperium-fg-base">Published</span>
      </FormCheckbox>
    </div>
    <TrashIcon
      class="text-imperium-ds-red-strong cursor-pointer mt-9"
      @click="emits('delete')"
    />
  </div>
</template>
