import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18",
  height: "18",
  fill: "none",
  viewBox: "0 0 18 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12.333 9a3.333 3.333 0 1 1-6.667 0 3.333 3.333 0 0 1 6.667 0m-1.667 0a1.667 1.667 0 1 1-3.333 0 1.667 1.667 0 0 1 3.333 0",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M10.41 17.333c.39 0 .728-.271.813-.652l.334-1.507 1.302.828c.33.21.76.163 1.037-.113l1.993-1.993a.83.83 0 0 0 .114-1.037l-.829-1.302 1.507-.335a.83.83 0 0 0 .652-.813V7.591a.83.83 0 0 0-.652-.814l-1.507-.335.828-1.302a.83.83 0 0 0-.113-1.036L13.896 2.11a.83.83 0 0 0-1.037-.114l-.8.51a.417.417 0 0 1-.63-.262l-.207-.926a.83.83 0 0 0-.813-.652H7.591a.83.83 0 0 0-.814.652l-.335 1.506-1.302-.828a.83.83 0 0 0-1.036.113L2.11 4.103a.83.83 0 0 0-.114 1.037l.829 1.302-1.507.335a.83.83 0 0 0-.652.814v2.818c0 .39.27.729.652.813l1.506.335-.828 1.302c-.21.33-.163.76.113 1.037l1.993 1.993a.83.83 0 0 0 1.037.114l1.302-.83.335 1.508a.83.83 0 0 0 .814.652zm-4.09-4.057a.44.44 0 0 1 .441-.012q.385.202.807.334a.44.44 0 0 1 .303.32l.316 1.422c.042.19.211.326.406.326h.813a.42.42 0 0 0 .407-.326l.316-1.422a.44.44 0 0 1 .303-.32q.42-.132.807-.334a.44.44 0 0 1 .44.012l1.23.782c.164.105.38.081.517-.057l.575-.575a.42.42 0 0 0 .057-.518l-.782-1.23a.44.44 0 0 1-.012-.44q.203-.384.334-.806a.44.44 0 0 1 .32-.303l1.422-.316a.42.42 0 0 0 .326-.407v-.813a.42.42 0 0 0-.326-.406l-1.422-.316a.44.44 0 0 1-.32-.303 5 5 0 0 0-.334-.807.44.44 0 0 1 .012-.44l.782-1.23A.42.42 0 0 0 14 4.574l-.575-.575a.42.42 0 0 0-.518-.057l-1.23.783a.44.44 0 0 1-.44.012 5 5 0 0 0-.806-.334.44.44 0 0 1-.303-.32L9.812 2.66a.42.42 0 0 0-.407-.326h-.813a.42.42 0 0 0-.406.326L7.87 4.081a.44.44 0 0 1-.303.32q-.421.132-.806.335a.44.44 0 0 1-.44-.012l-1.23-.783a.42.42 0 0 0-.519.057l-.575.575a.42.42 0 0 0-.057.518l.783 1.23a.44.44 0 0 1 .012.44 5 5 0 0 0-.334.807.44.44 0 0 1-.32.303l-1.423.316a.42.42 0 0 0-.326.406v.813c0 .196.136.365.326.407l1.422.316a.44.44 0 0 1 .32.303q.132.421.335.806a.44.44 0 0 1-.013.44l-.782 1.23a.42.42 0 0 0 .057.518l.575.575a.42.42 0 0 0 .518.057z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }