export interface Rate {
  cryptoSymbol: string; // BTC
  name: string; // Bitcoin
  slug: string; // bitcoin
  marketCap: string; // 1201194305348.83100948580200
  volume24h: string; // 1080471238.985037192702293396
  circulatingSupply: string; // 19760834.00
  totalSupply: string; //	19760834
  price?: string; // 60786.619904242453
  change24h?: number; // 0.116978681369
  change1h: number; //	0.02
  change7d?: number; // -7.43
  change1dFiat?: string; // 71.10738641276891200809958157
  open24h?: string; // 60693.225
  high24h?: string; // 61469.98
  low24h?: string; // 59841.1
  low52w?: string; // 26521.32
  high52w?: string; // 73915.3
  maxSupply?: string; // 2.100
  ytdReturn?: string; // 37.48
  athPrice?: string; // 73915.3
}

export enum CurrencyType {
  FIAT = 'fiat',
  CRYPTO = 'crypto',
}

export interface Currency {
  symbol: string;
  name: string;
  slug: string;
  type: CurrencyType;
}
