<template>
  <div>
    <Teleport to="#modals">
      <InsertLinkModal
        :is-visible="linkStore.state.isModalVisible"
        :data="linkData"
        @close="closeModal"
        @insert="applyLink"
        @reset="resetLink"
      />
    </Teleport>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { Editor } from '@tiptap/vue-3';
import type { LinkAttributes } from '@/features/TipTapEditor/types/link';
import InsertLinkModal from '@/features/TipTapEditor/components/modals/InsertLinkModal.vue';
import { useLinkTipTapStore } from '@/features/TipTapEditor/stores/link.store';

const props = defineProps<{ editor: Editor }>();

const linkStore = useLinkTipTapStore();

const linkData = computed(() => ({
  link: '',
  textField: '',
}));

const closeModal = () => {
  linkStore.closeModal();
};

const applyLink = ({ link, isInNewTab, title, isNofollow }: LinkAttributes) => {
  if (props.editor) {
    const attributes = { href: link, title: title ? title : link };

    attributes.target = isInNewTab ? '_blank' : '_self';
    attributes.rel = isNofollow ? 'nofollow noopener' : '';

    if (linkStore.state.selection?.selection) {
      const { from, to } = linkStore.state.selection.selection;

      props.editor.chain().focus().setTextSelection({ from, to }).extendMarkRange('link').setLink(attributes).run();
    }

    closeModal();
  }
};

const resetLink = () => {
  if (props.editor && linkStore.state.selection?.selection) {
    const { from, to } = linkStore.state.selection.selection;

    props.editor.chain().focus().setTextSelection({ from, to }).unsetLink().run();
  }

  closeModal();
};
</script>
