import { ref } from 'vue';
import { defineStore } from 'pinia';

import type { FiltersState } from '@/features/Converter/types/filters';
import { usePagination } from '@/features/Converter/stores/convertersPagination.store';
import { SortStatus } from '@/types';

export type State = FiltersState;

export const useConvertersFilters = defineStore('converterPageFilters', () => {
  const paginationStore = usePagination();

  const state = ref<Partial<State>>({
    sort: {
      id: SortStatus.DESC,
    },
    searchQuery: '',
  });

  const setSorts = (sort: Record<string, string>) => {
    state.value = { ...state.value, sort: { ...sort } } as FiltersState;
  };

  const setSearchQuery = (searchQuery: string) => {
    state.value = { ...state.value, searchQuery };
    paginationStore.setState({ page: 1 });
  };

  return {
    state,
    setSorts,
    setSearchQuery,
  };
});
