import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M4.85 14.667a3.518 3.518 0 0 1-2.488-6.005l1.924-1.924c.13-.13.341-.13.471 0l.524.523c.13.13.13.341 0 .472L3.358 9.656a2.11 2.11 0 1 0 2.985 2.985l1.923-1.923c.13-.13.341-.13.471 0l.524.524c.13.13.13.341 0 .471l-1.923 1.924a3.5 3.5 0 0 1-2.488 1.03m1.165-3.689h-.002l-.758-.758a.333.333 0 0 1 0-.471l4.503-4.504c.13-.13.342-.13.472 0l.523.524c.13.13.13.341 0 .471zm5.708-1.727a.334.334 0 0 1-.472 0l-.524-.523a.333.333 0 0 1 0-.471l1.923-1.924a2.11 2.11 0 1 0-2.984-2.985L7.742 5.272a.333.333 0 0 1-.471 0l-.524-.524a.333.333 0 0 1 0-.471l1.924-1.924a3.518 3.518 0 0 1 4.975 4.975z"
    }, null, -1)
  ])))
}
export default { render: render }