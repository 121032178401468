import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M9.667 7.7v-.067H10c1 0 2-.996 2-2.3C12 3.506 11 2 8 2H4.333A.333.333 0 0 0 4 2.333v11.334c0 .184.15.333.333.333h5.06c1.94 0 3.274-1.333 3.274-3.333C12.667 9 11.667 7.7 10 7.7zM6.333 6.667V4h2c1 0 1.467.61 1.467 1.333 0 .726-.133 1.334-1.336 1.334zm2.334 2c1.333 0 1.8.812 1.8 1.666 0 1.334-.8 1.667-1.8 1.667H6.333V8.667z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }