import type {
  ConverterFAQCreateRequest,
  ConverterFAQCreateResponse,
  ConverterFAQDeleteRequest,
  ConverterFAQFetchRequest,
  ConverterFAQFetchResponse,
  ConverterFAQPatchRequest,
  ConverterFAQPatchResponse,
  ConverterFAQPutRequest,
} from '@/features/Converter/types/api';
import { extractData, http } from '@/http';

export const fetchFaqs = (params?: ConverterFAQFetchRequest): Promise<ConverterFAQFetchResponse> => {
  return http.get<ConverterFAQFetchResponse>(`/faqs`, { params, useAuth: true }).then(extractData);
};

export const createFaq = (data?: ConverterFAQCreateRequest): Promise<ConverterFAQCreateResponse> => {
  return http.post<ConverterFAQCreateResponse>(`/faqs`, data).then(extractData);
};

export const patchFaq = (params?: ConverterFAQPatchRequest): Promise<ConverterFAQPatchResponse> => {
  return http.patch<ConverterFAQPatchResponse>(`/faqs/${params.id}`, params.data).then(extractData);
};

export const deleteFaq = (params?: ConverterFAQDeleteRequest): Promise<ConverterFAQPatchResponse> => {
  return http.delete<ConverterFAQPatchResponse>(`/faqs`, { data: { ids: params } }).then(extractData);
};
export const putFaq = (data?: ConverterFAQPutRequest[]): Promise<ConverterFAQPatchResponse> => {
  return http.put<ConverterFAQPatchResponse>(`/faqs`, data).then(extractData);
};
