import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M18.334 10a8.333 8.333 0 1 1-16.667 0 8.333 8.333 0 0 1 16.667 0M10 16.667c.13 0 .328-.062.589-.34.262-.28.533-.725.776-1.34.197-.499.366-1.084.497-1.737H8.138c.132.653.3 1.238.497 1.736.244.616.515 1.062.777 1.341.26.278.46.34.588.34M7.917 11.75h4.166a18 18 0 0 0 0-3.5H7.917A18 18 0 0 0 7.834 10c0 .609.029 1.195.083 1.75m.221-5c.132-.653.3-1.238.498-1.736.243-.616.514-1.062.776-1.34.26-.278.46-.34.588-.34.13 0 .328.062.589.34.262.278.533.724.776 1.34.197.498.366 1.083.497 1.736zm5.452 1.5a19.6 19.6 0 0 1 0 3.5h2.845a6.7 6.7 0 0 0 .232-1.75 6.7 6.7 0 0 0-.232-1.75zm2.233-1.5h-2.434a12 12 0 0 0-.629-2.287q-.135-.345-.294-.659a6.7 6.7 0 0 1 3.357 2.946m-9.212 0H4.178a6.7 6.7 0 0 1 3.357-2.946q-.159.315-.295.659a12 12 0 0 0-.629 2.287m-3.045 1.5H6.41a19.6 19.6 0 0 0 0 3.5H3.566A6.7 6.7 0 0 1 3.334 10c0-.605.08-1.192.232-1.75m9.194 7.287c.263-.665.476-1.438.63-2.287h2.432a6.7 6.7 0 0 1-3.356 2.946 8 8 0 0 0 .294-.659m-5.225.659a6.7 6.7 0 0 1-3.357-2.946h2.433c.154.85.367 1.622.63 2.287q.135.345.294.659",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }