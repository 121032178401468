import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M16.513 4.976c-1.427-1.38-3.94-1.751-5.819-1.336-2.225.493-4.344 2.154-4.344 4.483C6.35 9.4 7 10.5 8 10.9v.1H4.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5s-5.066.061-6.936 0-4.007-1.177-3.963-2.814C8.638 6.842 10.02 6.06 11.193 5.8c1.12-.248 2.848-.039 3.715.8.41.397.556.908.616 1.425.03.265.246.475.513.475h1.311a.473.473 0 0 0 .482-.5c-.086-1.136-.471-2.205-1.317-3.024M17.575 14H14.29c1.072.59 1.928 1.476 1.347 2.689-.906 1.892-4.743 2.205-6.285.959-.552-.445-.78-1.037-.88-1.688a.53.53 0 0 0-.515-.46h-1.31a.475.475 0 0 0-.482.524c.155 1.322.662 2.551 1.737 3.42 1.227.99 2.944 1.261 4.488 1.163 1.093-.07 2.134-.195 3.112-.742 1.73-.967 2.955-2.648 2.493-4.735a4.2 4.2 0 0 0-.42-1.13"
    }, null, -1)
  ])))
}
export default { render: render }