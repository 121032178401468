import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M4.332 2a.333.333 0 0 0-.333.333v5.744c0 .545 0 .917.064 1.24a3.33 3.33 0 0 0 2.619 2.619 7.4 7.4 0 0 0 2.634 0 3.33 3.33 0 0 0 2.619-2.619c.064-.323.064-.695.064-1.24V2.333A.333.333 0 0 0 11.665 2H11a.333.333 0 0 0-.334.333V8c0 .651-.003.88-.038 1.057a2 2 0 0 1-1.571 1.571 6 6 0 0 1-2.114 0A2 2 0 0 1 5.37 9.057C5.335 8.88 5.332 8.65 5.332 8V2.333A.333.333 0 0 0 4.999 2zm-.667 11.333a.333.333 0 0 0-.333.334v.666c0 .184.15.334.333.334h8.667c.184 0 .333-.15.333-.334v-.666a.333.333 0 0 0-.333-.334z"
    }, null, -1)
  ])))
}
export default { render: render }