<template>
  <div
    data-testid="article-layout"
    class="mb-1"
  >
    <div class="flex">
      <div class="flex w-full lg:items-center mb-4 flex-col lg:flex-row justify-between px-4">
        <div
          v-if="updatedAtVisible"
          class="flex items-center gap-2 mb-2"
        >
          <component
            :is="statusIconComponent"
            class="w-6 min-w-6 h-6 min-h-6 text-imperium-fg-muted block"
          />
          <p class="text-base text-[#10171B]">{{ updatedAtText }}</p>
        </div>
        <span
          v-else
          class="flex w-full lg:items-center mb-4 flex-col lg:flex-row justify-between px-4"
        />
        <ChangeStatusButtons
          :model-value="documentStatus"
          :published-at="publish"
          :has-post="hasPost"
          :can-be-republished="canBeRepublished"
          @publish="onDocumentPublish"
          @schedule="onDocumentSchedule"
          @update="onDocumentUpdate"
          @approve="onUpdateField"
          @update:model-value="onDocumentStatusChange"
        />
      </div>

      <div
        v-if="!isLargeDesktop"
        class="flex flex-col items-end"
      >
        <Button
          :color="BUTTON_COLOR.PRIMARY"
          :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
          :size="SIZES.SMALL"
          :visual-type="BUTTON_TYPE.SECONDARY"
          @click="onOpenSidebar"
        >
          <template #leftIcon>
            <SidebarIcon class="w-6 h-6 text-imperium-fg-muted" />
          </template>
        </Button>

        <ArticleStatus
          :status="documentStatus"
          :published-at="publish"
        />
      </div>
    </div>

    <div
      class="grid grid-flow-row lg:grid-flow-col auto-cols-fr lg:auto-cols-[63.5%_1fr] lg:max-w-[1166px] lg:mx-auto gap-4"
    >
      <div class="flex flex-col gap-4">
        <section class="bg-imperium-bg-sub-base p-4 rounded-2xl">
          <!--           Need to move search logic into ArticleAuthors component-->
          <FormSelect
            :attrs="authorAttrs"
            :is-errored="isArticleAuthorErrored"
            :model-value="author"
            class="max-w-[380px] w-full mb-4 sm:mb-1"
            name="author"
            placeholder="Select public author"
            :values="mappedAuthors"
            :menu-additional-info="limitMessage"
            :size="SIZES.SMALL"
            :is-loading="isAuthorsLoading"
            :selected-value="mappedAuthor"
            data-anchor="field-author"
            @search="updateSearchQuery"
            @update:model-value="(value) => onUpdateField('authorId', value)"
          >
            <template #icon>
              <AuthorScanIcon class="w-4 h-4 text-imperium-fg-muted" />
            </template>

            <template #error> Select public author.</template>
          </FormSelect>

          <ArticleTitleInput
            v-if="documentMetadataTitleView"
            :attrs="articleTitleAttrs"
            :is-disabled="!documentMetadataTitleEdit"
            :is-errored="isArticleTitleErrored"
            :max="MAX_TITLE_LENGTH"
            :model-value="articleTitle"
            class="mb-4"
            data-anchor="field-articleTitle"
            name="articleTitle"
            placeholder="Article title"
            type="text"
            @update:model-value="(value) => onUpdateField('title', value)"
          >
            <template #error> {{ errors.title }}</template>
          </ArticleTitleInput>

          <ArticleDescriptionArea
            :attrs="articleDescriptionAttrs"
            :is-errored="isArticleDekErrored"
            :max="MAX_DEK_LENGTH"
            :model-value="articleDescription"
            class="mb-4"
            data-anchor="field-dek"
            name="articleDescription"
            placeholder="Dek"
            @update:model-value="(value) => onUpdateField('dek', value)"
          >
            <template #error> {{ errors.dek }}</template>
          </ArticleDescriptionArea>

          <CoverImage
            v-if="documentMetadataCoverView"
            :is-disabled="!documentMetadataCoverEdit"
            :is-errored="isCoverArtErrored"
            :is-loading="isArticlePending"
            :model-value="coverArt"
            data-anchor="field-coverArt"
            @update:model-value="(value: number) => onUpdateField('coverArt', value)"
          >
            <template #error> Insert image as cover</template>
          </CoverImage>

          <CoverYoutubeUrl
            :is-loading="isArticlePending"
            :model-value="youtubeUrl"
            @update:model-value="(value: string) => onUpdateField('youtubeUrl', value)"
          />
        </section>
        <section
          v-if="isCollEditorAvailable && documentMetadataTextView"
          :class="{
            'after:absolute after:content-[` `] ': !documentMetadataTextEdit,
          }"
          class="bg-imperium-bg-sub-base p-5 rounded-2xl h-full relative"
        >
          <ColladitorHolder
            :is-disabled="!documentMetadataTextEdit"
            :document-id="route.params.id"
            :language-id="languageId"
            :text="articleData.fulltext"
            :user-id="user.id"
            :user-name="user.name"
          />
        </section>
      </div>

      <div
        :class="{
          'after:opacity-100 after:pointer-events-auto': !isLargeDesktop && isSidebarOpened,
        }"
        class="after:transition-opacity after:content-[\' \'] after:block after:lg:hidden after:absolute after:z-50 after:top-0 after:left-0 after:right-0 after:bottom-0 after:bg-imperium-bg-overlay after:opacity-0 after:pointer-events-none after:lg:pointer-events-auto"
      >
        <MetadataSidebar
          ref="sidebarRef"
          :article-url="articleUrl"
          :article-url-attrs="articleUrlAttrs"
          :has-post="hasPost"
          :assignment="assignment"
          :assignment-attrs="assignmentAttrs"
          :category="category"
          :category-attrs="categoryAttrs"
          :copy-editor="copyEditor"
          :copy-editor-attrs="copyEditorAttrs"
          :deadline="deadline"
          :deadline-attrs="deadlineAttrs"
          :cover-deadline="coverDeadline"
          :cover-deadline-attrs="coverDeadlineAttrs"
          :description="description"
          :description-attrs="descriptionAttrs"
          :editor="editor"
          :editor-attrs="editorAttrs"
          :embargo="embargo"
          :embargo-attrs="embargoAttrs"
          :is-assignment-errored="isAssignmentErrored"
          :is-breaking-news="isBreakingNews"
          :is-breaking-news-attrs="isBreakingNewsAttrs"
          :is-category-errored="isCategoryErrored"
          :is-exclude-from-rss="isExcludeFromRss"
          :is-exclude-from-rss-attrs="isExcludeFromRssAttrs"
          :is-hide-from-hot="isHideFromHot"
          :is-hide-from-hot-attrs="isHideFromHotAttrs"
          :is-hide-from-main-page="isHideFromMainPage"
          :is-hide-from-main-page-attrs="isHideFromMainPageAttrs"
          :is-label-errored="isLabelErrored"
          :is-opened="isSidebarOpened"
          :is-promo="isPromo"
          :is-promo-attrs="isPromoAttrs"
          :is-show-in-markets="isShowInMarkets"
          :is-show-in-markets-attrs="isShowInMarketsAttrs"
          :is-super-tags-errored="isSuperTagsErrored"
          :is-tags-errored="isTagsErrored"
          :is-twitter-post-errored="isTwitterPostErrored"
          :is-meta-description-errored="isMetaDescriptionErrored"
          :is-meta-keywords-errored="isMetaKeywordsErrored"
          :is-original-content="isOriginalContent"
          :keywords="keywords"
          :keywords-attrs="keywordsAttrs"
          :label="label"
          :label-attrs="labelAttrs"
          :needs-cover="needsCover"
          :needs-cover-attrs="needsCoverAttrs"
          :publish="publish"
          :publish-attrs="publishAttrs"
          :status="documentStatus"
          :super-tags="superTags"
          :super-tags-attrs="superTagsAttrs"
          :tags="tags"
          :tags-attrs="tagsAttrs"
          :twitter-exclude-channels="twitterExcludeChannels"
          :twitter-exclude-channels-attrs="twitterExcludeChannelsAttrs"
          :twitter-exclude-rss="twitterExcludeRss"
          :twitter-exclude-rss-attrs="twitterExcludeRssAttrs"
          :twitter-post="twitterPost"
          :twitter-post-attrs="twitterPostAttrs"
          :writer="writer"
          :writer-attrs="writerAttrs"
          :can-be-republished="canBeRepublished"
          @hide="onHideSidebar"
          @open="onOpenSidebar"
          @update-field="onUpdateField"
        />
      </div>
    </div>

    <ToTopButton v-if="isDesktop" />
  </div>
</template>

<script lang="ts" setup>
import { toTypedSchema } from '@vee-validate/zod';
import { onClickOutside } from '@vueuse/core';
import {
  computed,
  inject,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  provide,
  type Ref,
  ref,
  unref,
  watch,
  watchEffect,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { freezeBody, unfreezeBody } from '@/utils/dom';
import { cloneDeep, debounce } from 'lodash';
import { DOCUMENT_COVERART_FIELD, MAX_DEK_LENGTH, MAX_TITLE_LENGTH } from './constants';

import CloudIcon from '@/assets/icons/cloud.svg?component';
import CloudSaving from '@/assets/icons/cloud-saving.svg?component';
import CloudNoConnection from '@/assets/icons/cloud-no-connection.svg?component';
import AuthorScanIcon from '@/assets/icons/author-scan.svg?component';
import SidebarIcon from '@/assets/icons/sidebar.svg?component';

import Button from '@/components/Button.vue';
import FormSelect, { type SelectItem } from '@/components/FormSelect.vue';
import ColladitorHolder from '@/features/CollaborativeEditor/components/ColladitorHolder.vue';
import { type ValidationField, useFormData, useToast } from '@/composables';

import ArticleStatus from './components/ArticleStatus.vue';
import ArticleTitleInput from './components/ArticleTitleInput.vue';
import ArticleDescriptionArea from './components/ArticleDescriptionArea.vue';
import ChangeStatusButtons from './components/ChangeStatusButtons.vue';

import { BUTTON_COLOR, BUTTON_ON_SURFACE, BUTTON_TYPE, DocumentStatus, SIZES, SortStatus } from '@/types';
import { typedSchema } from './typedSchemas';
import { useFetchAuthorById, useFetchAuthors, useMutateDocument } from './queries';
import { type ArticleAuthorsFetchRequest, type DocumentFetchResponse } from './types';

import { useCurrentDocument, useDocumentStatus } from './composables';
import CoverImage from '@/features/ArticleLayout/components/CoverImage.vue';
import CoverYoutubeUrl from '@/features/ArticleLayout/components/CoverYoutubeUrl.vue';

import MetadataSidebar from './MetadataSidebar.vue';
import { useUsers } from '@/features/Users/composables';
import { useArticleUpdatedAt } from '@/stores/article.store';
import { useActiveArticleStore } from '@/stores/users.store';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useUserConditions } from '@/composables/useUserConditions';

import { useServerSideEvents } from '@/features/ArticleLayout/composables';
import { useUserFilters } from '@/features/Users/stores/filters.store';
import { combineTags } from '@/features/ArticleLayout/helpers/article';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';
import { MetadataErrors } from '@/features/ArticleLayout/constants/metadataErrors';
import ToTopButton from '@/features/ArticleLayout/components/ToTopButton.vue';
import { useLanguagesStore } from '@/stores/languages.store';
import { useBreadcrumbStore } from '@/features/Breadcrumbs/store';

const isLargeDesktop = inject<Ref<boolean>>('isLargeDesktop');

const ERROR_CODE_500_STARTS_WITH = '5';
const ERROR_CODE_400_STARTS_WITH = '4';

const toast = useToast();
const route = useRoute();
const router = useRouter();

const { createBreadcrumbsWatcher } = useBreadcrumbStore();

const isDesktop = inject('isDesktop');

const {
  documentMetadataTextView,
  documentMetadataTextEdit,
  documentMetadataTitleView,
  documentMetadataTitleEdit,
  documentMetadataCoverView,
  documentMetadataCoverEdit,
} = useUserPermissions();

const userStore = useUserStore();
const languagesStore = useLanguagesStore();

const useUsersAvatarStore = useActiveArticleStore();
const { mutateAsync: patchDocument } = useMutateDocument();
const { documentStatus, virtualUpdate: updateDocumentStatus } = useDocumentStatus();
const { users } = useUsers();

const user = computed(() => userStore.state);
const languages = computed(() => languagesStore.state);

// Need to move search logic into ArticleAuthors component
const filtersStore = useUserFilters();
const searchQuery = computed(() => filtersStore.state?.searchQuery || '');

const languageId = computed(() => (languages.value ? languages.value[0].id : 1));

const fetchAuthorsParams = computed<ArticleAuthorsFetchRequest>(() => ({
  languageId: 1,
  page: 1,
  pagination: !searchQuery.value,
  itemsPerPage: 20,
  // Temporary removed from query coz with popular order requests timing nearby 2,5 sec
  // 'order[popular]': !searchQuery.value ? SortStatus.DESC : null,
  'order[name]': SortStatus.ASC,
  'filter[search]': searchQuery.value,
}));

const limitMessage = computed<string>(() => {
  return !searchQuery.value ? 'Only 20 authors are displayed. Start typing a name or surname to see more.' : '';
});

const { data: authors, isLoading: isAuthorsLoading } = useFetchAuthors(fetchAuthorsParams);
const { data: articleData, isError: isArticleErrored, isPending: isArticlePending } = useCurrentDocument();

const articleStore = useArticleStore();

const { metadataCondition } = useUserConditions();

const isMobile = inject<boolean>('isMobile');

const cache = ref<Record<string, any>>({});
const updatedAtStore = useArticleUpdatedAt();
const pending = ref<boolean>(false);

const patchValues = ref<Record<string, any>>({});

const isOnline = ref(true);

const statusIconComponent = computed(() => {
  if (isOnline.value && updatedAtStore.connection && !updatedAtStore.isErrored)
    return pending.value ? CloudSaving : CloudIcon;

  return CloudNoConnection;
});

const updatedAtVisible = computed<boolean>(() => !!updatedAtStore.state);

const updatedAtText = computed<string>(() => {
  if (pending.value && isOnline.value && updatedAtStore.connection && !updatedAtStore.isErrored)
    return 'Saving changes';

  if (!pending.value && isOnline.value && updatedAtStore.connection && !unref(isMobile) && !updatedAtStore.isErrored)
    return `Changes saved on ${updatedAtStore.state}`;
  if (!pending.value && isOnline.value && updatedAtStore.connection && unref(isMobile) && !updatedAtStore.isErrored)
    return `Saved on ${updatedAtStore.state}`;

  if (updatedAtStore.isErrored && !pending.value && !unref(isMobile))
    return `Changes can't be saved, last saved on ${updatedAtStore.state}`;
  if (updatedAtStore.isErrored && !pending.value) return `Changes can't be saved`;

  if (!unref(isMobile)) return `No internet connection, last saved on ${updatedAtStore.state}`;
  return `Saved on ${updatedAtStore.state}`;
});

const isCollEditorAvailable = computed(() => {
  return user.value?.id && route.params.id && !isArticlePending.value;
});

const { defineField, errors, meta, validate, setValues, setFieldValue, setErrors, values } = useFormData({
  data: articleStore.state,
  validator: toTypedSchema(typedSchema),
});

const [author, authorAttrs] = defineField('authorId');
const [articleTitle, articleTitleAttrs]: ValidationField = defineField('title');
const [articleDescription, articleDescriptionAttrs]: ValidationField = defineField('dek');

const [articleUrl, articleUrlAttrs] = defineField('slug');
const [assignment, assignmentAttrs] = defineField('assignment');
const [needsCover, needsCoverAttrs] = defineField('needsOriginalArtwork');
const [writer, writerAttrs] = defineField('writerId');
const [editor, editorAttrs] = defineField('editorId');
const [copyEditor, copyEditorAttrs] = defineField('copyEditorId');
const [deadline, deadlineAttrs] = defineField('coverImageDeadLineAt');
const [publish, publishAttrs] = defineField('publishedAt');
const [coverDeadline, coverDeadlineAttrs] = defineField('coverDeadline');
const [embargo, embargoAttrs] = defineField('embargoUntil');

const [category, categoryAttrs] = defineField('categoryId');
const [label, labelAttrs] = defineField('badgeId');
const [superTags, superTagsAttrs] = defineField('superTags');
const [tags, tagsAttrs] = defineField('tags');

const [twitterPost, twitterPostAttrs] = defineField('twitterPost');
const [twitterExcludeRss, twitterExcludeRssAttrs] = defineField('twitterExcludeRss');
const [twitterExcludeChannels, twitterExcludeChannelsAttrs] = defineField('isExcludedFromTelegram');

const [keywords, keywordsAttrs] = defineField('seoMetaKeyWords');
const [description, descriptionAttrs] = defineField('seoMetaDescription');

const [isBreakingNews, isBreakingNewsAttrs] = defineField('isBreakingNews');
const [isShowInMarkets, isShowInMarketsAttrs] = defineField('isShowingInMarkets');
const [isExcludeFromRss, isExcludeFromRssAttrs] = defineField('excludeFromAllRss');
const [isHideFromHot, isHideFromHotAttrs] = defineField('hideFromHotStories');
const [isHideFromMainPage, isHideFromMainPageAttrs] = defineField('hideFromMainPage');
const [isPromo, isPromoAttrs] = defineField('isPromoPost');
const [isOriginalContent] = defineField('isOriginalContent');

const [hasPost] = defineField('hasPost');
const [needsProofreading] = defineField('needsProofreading');

const [canBeRepublished] = defineField('canBeRepublished');

const [coverArt] = defineField('coverArt');
const [youtubeUrl] = defineField('youtubeUrl');

const isArticleTitleErrored = computed(() => meta.value.touched && !!errors.value.title);
const isArticleDekErrored = computed(() => meta.value.touched && !!errors.value.dek);
const isArticleAuthorErrored = computed(() => meta.value.touched && !!errors.value.authorId);
const isAssignmentErrored = computed(() => meta.value.touched && !!errors.value.assignment);
const isCategoryErrored = computed(() => meta.value.touched && !!errors.value.categoryId);
const isLabelErrored = computed(() => meta.value.touched && !!errors.value.badgeId);
const isSuperTagsErrored = computed(() => meta.value.touched && !!errors.value.superTags);
const isTagsErrored = computed(() => meta.value.touched && !!errors.value.tags);
const isMetaKeywordsErrored = computed(() => meta.value.touched && !!errors.value.seoMetaKeyWords);
const isMetaDescriptionErrored = computed(() => meta.value.touched && !!errors.value.seoMetaDescription);

const isCoverArtErrored = computed(() => meta.value.touched && !!errors.value.coverArt);
const isTwitterPostErrored = computed(() => meta.value.touched && !!errors.value.twitterPost);

const isSidebarOpened = ref<boolean>(false);
const sidebarRef = ref<HTMLElement | null>(null);

const fetchAuthorByIdParams = computed(() => {
  if (!author.value) return;

  return {
    authorId: author.value,
    languageId: 1,
  };
});

const { data: selectedAuthorById } = useFetchAuthorById(fetchAuthorByIdParams);

const mappedAuthors = computed<SelectItem[]>(() => {
  if (!authors.value || !authors.value.items?.length) return [];

  return authors.value.items.map((author) => {
    return {
      id: author.author.id,
      label: author.name,
    };
  });
});

const mappedAuthor = computed(() => {
  if (!selectedAuthorById.value || !selectedAuthorById.value.author) return;

  return {
    id: selectedAuthorById.value.author.id,
    label: selectedAuthorById.value.name ?? selectedAuthorById.value.author.title,
  };
});

onClickOutside(sidebarRef, () => {
  if (!isSidebarOpened.value || isLargeDesktop?.value) {
    return;
  }

  onHideSidebar();
});

const onOpenSidebar = () => {
  isSidebarOpened.value = true;

  if (!isLargeDesktop?.value) {
    freezeBody();
  }
};

const onHideSidebar = () => {
  isSidebarOpened.value = false;

  if (!isLargeDesktop?.value) {
    unfreezeBody();
  }
};

const debouncedPatchDocumentWithFields = debounce(() => {
  patchDocumentWithFields(patchValues.value);
  patchValues.value = {};
}, 1000);

const onDocumentSubmitDebounced = (value: Record<string, any>) => {
  patchValues.value = { ...patchValues.value, ...value };
  // We need update article tags on the way
  if (
    'tags' in patchValues.value ||
    'superTags' in patchValues.value
    // || 'slug' in patchValues.value
  ) {
    patchDocumentWithFields(patchValues.value);
    patchValues.value = {};
  } else {
    debouncedPatchDocumentWithFields(value);
  }
};

const updateSearchQuery = (searchQuery: string) => {
  filtersStore.setSearchQuery(searchQuery);
};

const onUpdateField = (field: string, value: unknown) => {
  setFieldValue(field, value, false);

  if (isArticlePending.value) {
    return;
  }

  if (isArticleErrored.value) {
    return;
  }

  nextTick(() => {
    onDocumentSubmitDebounced({ [field]: value });
  });
  if (field === 'authorId') {
    filtersStore.setSearchQuery('');
  }
};

const setUpdatedAt = (date?: number | Date) => {
  if ((articleData && !articleData.value) || !articleData.value.updatedAt) return null;

  const updated = date ?? new Date(articleData.value.updatedAt);
  updatedAtStore.setArticleUpdatedAt(
    new Intl.DateTimeFormat('en', {
      dateStyle: 'long',
      timeStyle: 'short',
    }).format(updated),
  );
};

watchEffect(() => {
  window.addEventListener('offline', () => {
    updatedAtStore.setConnection(false);
  });
  window.addEventListener('online', () => {
    updatedAtStore.setConnection(true);
  });
});

const patchDocumentWithFields = async (fields: Record<string, any> = {}, onErrorCallback = () => null) => {
  // We check just this for fields because their default values set is invoke false update of document
  if (fields.hasOwnProperty('publishedAt') && cache.value.publishedAt === fields.publishedAt) {
    delete fields.publishedAt;
  }

  if (fields.hasOwnProperty('embargoUntil') && cache.value.embargoUntil === fields.embargoUntil) {
    delete fields.embargoUntil;
  }

  if (fields.hasOwnProperty('slug') && cache.value.slug === fields.slug) {
    delete fields.slug;
  }

  if (
    fields.hasOwnProperty('coverImageDeadLineAt') &&
    cache.value.coverImageDeadLineAt === fields.coverImageDeadLineAt
  ) {
    delete fields.coverImageDeadLineAt;
  }

  cache.value = { ...values };

  const documentId = parseInt(route.params.id, 10);
  pending.value = true;

  if (!languageId.value) {
    return;
  }

  if (documentId) {
    try {
      if (updatedAtStore.isErrored) updatedAtStore.setErroredStatus(false);

      const patchValues: Record<string, any> = fields;

      if (fields.slug && fields.slug === 'article-title') {
        delete patchValues.slug;
      }

      if (fields.slug === null) {
        delete patchValues.slug;
      }

      if (fields.twitterPost) {
        patchValues.twitterLeadText = fields.twitterPost;
      }

      if (fields.tags || fields.superTags) {
        patchValues.tags = combineTags({ fields, values });

        if (patchValues.hasOwnProperty('superTags')) {
          delete patchValues.superTags;
        }
      }

      if (Object.keys(patchValues).length > 0) {
        const data = await patchDocument({
          documentId,
          language: languageId.value,
          values: patchValues,
        });

        articleStore.setArticleData(data);
        articleStore.setArticleAdditionalData(data, users);
        setUpdatedAt(new Date());
      }

      pending.value = false;
    } catch (error) {
      pending.value = false;
      if (error.status?.toString().startsWith(ERROR_CODE_400_STARTS_WITH)) updatedAtStore.setErroredStatus(true);
      toast.error({
        id: 'ERROR_UPDATE',
        title: 'Article wasn’t updated',
        message: error.data.errorMessage,
      });
      throw new Error();
    }
  }
};

const scrollToFirstErroredField = (errors: Record<string, string>): void => {
  const firstErroredField = Object.keys(errors)[0];

  if (firstErroredField) {
    const notInSidebar = ['dek', 'articleTitle', 'coverArt'];

    if (notInSidebar.includes(firstErroredField)) {
      document.querySelector(`[data-anchor="field-${firstErroredField}"]`)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    sidebarRef.value?.scrollToField(firstErroredField);
  }
};

const validateAndScroll = async () => {
  try {
    const { valid, errors: validationErrors } = await validate();

    if (!valid) {
      throw validationErrors;
    }
  } catch (errors: any) {
    setErrors(errors as Record<string, string>);
    scrollToFirstErroredField(errors);

    const error = new Error('All mandatory fields must be filled in to move the article to another status.');
    error.code = MetadataErrors.VALIDATION_ERROR;

    throw error;
  }
};

const onDocumentUpdate = async (isPublishedDateUpdate: boolean): Promise<void> => {
  try {
    await validateAndScroll();
    try {
      const values: Record<string, any> = {};
      if (isPublishedDateUpdate) {
        values.publishedAt = new Date().toISOString();
      }

      if (canBeRepublished.value && isPublishedDateUpdate) {
        values.republish = canBeRepublished.value;
      }

      if (documentStatus.value === DocumentStatus.PUBLISHED && hasPost) {
        values.status = DocumentStatus.PUBLISHED;
      }

      await patchDocumentWithFields(values);

      toast.success({
        id: 'DOCUMENT_UPDATED',
        message: 'Article was updated successfully.',
      });
    } catch {
      toast.error({
        id: 'ERROR_UPDATE',
        title: 'Article wasn’t updated',
        message: 'Error message indicating the reason for the failure.',
      });
    }
  } catch {
    //
  }
};

const onDocumentPublish = async (articleUrl: string) => {
  const { commit, rollback } = updateDocumentStatus(DocumentStatus.PUBLISHED);

  try {
    await validateAndScroll();
    commit();
    try {
      // temporary => waiting for articleStore
      hasPost.value = true;

      await patchDocumentWithFields({
        status: DocumentStatus.PUBLISHED,
        slug: articleStore.state.slug,
        publishedAt: new Date().toISOString(),
      });

      toast.success({
        id: 'DOCUMENT_PUBLISHED',
        message: 'Article was published successfully.',
      });
    } catch {
      toast.error({
        id: 'ERROR_PUBLISH',
        title: 'Article wasn’t published',
        message: 'Error message indicating the reason for the failure.',
      });
    }
  } catch {
    rollback();
  }
};

const onDocumentSchedule = async (articleUrl: string, embargoedUntil: string | null, publishedAt: string | null) => {
  const { commit, rollback } = updateDocumentStatus(DocumentStatus.PUBLISHED);

  try {
    await validateAndScroll();
    commit();

    const requestParams: Record<string, any> = {
      status: DocumentStatus.PUBLISHED,

      slug: articleStore.state.slug,
      embargoedUntil,
      publishedAt: publishedAt ?? new Date().toISOString(),
    };

    try {
      await patchDocumentWithFields(requestParams);

      toast.success({
        id: 'DOCUMENT_SCHEDULED',
        message: 'Article was scheduled successfully.',
      });
    } catch {
      toast.error({
        id: 'ERROR_SCHEDULE',
        title: 'Article wasn’t scheduled',
        message: 'Error message indicating the reason for the failure',
      });
    }
  } catch {
    rollback();
  }
};

const onDocumentStatusChange = async (status: DocumentStatus) => {
  // XXX: need to update status first, because `validate` rely on it
  const { commit, rollback } = updateDocumentStatus(status);

  try {
    if (status !== DocumentStatus.IN_PROGRESS) {
      await validateAndScroll();
    }

    await patchDocumentWithFields({
      status,
    });

    if (status === DocumentStatus.UNPUBLISHED) {
      toast.success({
        id: 'DOCUMENT_UNPUBLISHED',
        message: 'Article was successfully unpublished',
      });
    }

    commit();
  } catch (e) {
    if (status === DocumentStatus.UNPUBLISHED || e?.code === MetadataErrors.VALIDATION_ERROR) {
      toast.error({
        id: 'ERROR_UNPUBLISH',
        message: 'All mandatory fields must be filled in to move the article to another status.',
      });
    } else {
      toast.error({
        id: 'ERROR_CHANGE_STATUS',
        message: 'Unable to change article status',
      });
    }

    rollback();
  }
};

const unwatchError = watch(
  isArticleErrored,
  (isArticleErroredValue) => {
    if (!isArticleErroredValue) {
      return;
    }

    router.push({
      name: 'articles',
    });

    setTimeout(() => {
      toast.errorTemporary({
        id: 'ERROR_DOCUMENT_LOAD',
        message: 'Requested article cannot be loaded',
      });
    }, 0);

    unwatchError();
  },
  { immediate: true },
);

const sanitizeAndUpdateFields = (rawValues: DocumentFetchResponse) => {
  articleStore.setArticleData(rawValues);
  setValues(articleStore.state);

  articleStore.setArticleAdditionalData(rawValues, users);
};

const unwatchData = watch(articleData, (articleDataValue) => {
  if (!articleDataValue) {
    return;
  }

  articleStore.cleanArticleData();
  articleStore.setArticleData(articleData);
  setValues(articleStore.state);
  cache.value = { ...articleDataValue };
  const { commit } = updateDocumentStatus(articleDataValue.status);
  commit();
  unwatchData();
});

watch([users, articleData], () => {
  if (users.value && articleData.value) {
    sanitizeAndUpdateFields(articleData.value);
  }
});

createBreadcrumbsWatcher(() => articleTitle.value, 'edit-article');

const { init, disconnect } = useServerSideEvents<DocumentFetchResponse>((data: DocumentFetchResponse) => {
  if (!data) {
    return;
  }
  const resultData = cloneDeep(data);

  if (DOCUMENT_COVERART_FIELD in data) {
    const isCoverArtChanged = data.coverArt?.id !== parseInt(values.coverArt, 10);
    resultData.coverArt = isCoverArtChanged ? (data.coverArt ?? null) : null;
  }

  if (resultData.status) {
    const { commit } = updateDocumentStatus(resultData.status);
    commit();
  }

  if (resultData.slug) {
    cache.value.slug = resultData.slug;
  }

  articleStore.setArticleData(resultData);
  setValues(articleStore.state, false);
});

onMounted(init);
onUnmounted(disconnect);

provide('articleTitle', articleTitle);

onMounted(() => {
  filtersStore.setSearchQuery('');

  articleUrl.value = articleUrl.value || articleTitle.value;
});

onBeforeUnmount(() => {
  articleStore.cleanArticleData();
  useUsersAvatarStore.clearStore();
});

watchEffect(() => {
  articleUrl.value = articleUrl.value || articleTitle.value;
});
</script>
