export const cyrillicRegExp = /[\u0400-\u04FF]/;

export const hasCyrillic = (text = '') => {
  return cyrillicRegExp.test(text);
};

export default (text = '') => {
  return text.replace(cyrillicRegExp, '');
};

export const blockCyrillicKeypress = (event: KeyboardEvent) => {
  const char = event.key;
  if (hasCyrillic(char)) {
    event.preventDefault();
  }
};
