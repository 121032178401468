import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "18",
  fill: "none",
  viewBox: "0 0 16 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M7.972 17.067C8 16.994 8 16.903 8 16.72v-.442c0-.182 0-.274-.028-.346a.42.42 0 0 0-.238-.238c-.073-.029-.164-.029-.346-.029H4.175c-.569 0-.917 0-1.179-.017-.248-.016-.304-.041-.297-.039a.83.83 0 0 1-.476-.476 1.3 1.3 0 0 1-.039-.297c-.017-.262-.017-.61-.017-1.179V4.342c0-.57 0-.917.017-1.18.016-.248.042-.303.039-.296a.83.83 0 0 1 .476-.477c-.007.003.049-.022.297-.038.262-.017.61-.018 1.179-.018h3.213c.182 0 .273 0 .346-.028a.42.42 0 0 0 .238-.238C8 1.994 8 1.903 8 1.72v-.442c0-.182 0-.274-.028-.346a.42.42 0 0 0-.238-.238C7.66.667 7.57.667 7.388.667H4.175c-1.094 0-1.642 0-2.077.168a2.5 2.5 0 0 0-1.43 1.43C.5 2.7.5 3.246.5 4.341v9.316c0 1.095 0 1.642.168 2.077a2.5 2.5 0 0 0 1.43 1.43c.435.168.983.168 2.077.168h3.213c.182 0 .273 0 .346-.028a.42.42 0 0 0 .238-.238"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M11.004 13.462a.42.42 0 0 1-.59 0l-.648-.645a.413.413 0 0 1 0-.587l1.81-1.8 1.216-.68-1.442.08H5.917a.416.416 0 0 1-.417-.415v-.83c0-.229.187-.415.417-.415h5.433l1.442.08-1.216-.681-1.81-1.8a.413.413 0 0 1 0-.586l.648-.645a.42.42 0 0 1 .59 0L15.5 9z"
    }, null, -1)
  ])))
}
export default { render: render }