import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M18.293 1.703a1 1 0 0 1 1.414 0l2.586 2.586a1 1 0 0 1 0 1.414l-9.707 9.707a2 2 0 0 1-1.414.586H8.5a.5.5 0 0 1-.5-.5v-2.672a2 2 0 0 1 .586-1.414zm-7.121 12.293H10v-1.172l5.707-5.707L16.88 8.29zm7.12-7.121-1.17-1.172L19 3.824l1.172 1.172z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M14.879 3H7.41c-1.313 0-1.97 0-2.492.202a3 3 0 0 0-1.716 1.716C3 5.44 3 6.097 3 7.41v9.18c0 1.313 0 1.97.202 2.492a3 3 0 0 0 1.716 1.716C5.44 21 6.097 21 7.41 21h9.18c1.313 0 1.97 0 2.492-.202a3 3 0 0 0 1.716-1.716C21 18.56 21 17.903 21 16.59V9.121l-2 2v5.469c0 .682-.001 1.1-.021 1.415-.017.266-.043.348-.046.356a1 1 0 0 1-.572.571s-.073.029-.356.047c-.315.02-.733.021-1.415.021H7.41c-.682 0-1.1-.001-1.415-.021-.266-.017-.348-.043-.356-.046a1 1 0 0 1-.572-.572c.004.008-.027-.058-.046-.356A25 25 0 0 1 5 16.59V7.41c0-.682.001-1.1.021-1.415.02-.298.05-.364.046-.356a1 1 0 0 1 .572-.572c-.008.004.058-.027.356-.046C6.31 5.001 6.728 5 7.41 5h5.469z"
    }, null, -1)
  ])))
}
export default { render: render }