<template>
  <div class="bg-imperium-bg-sub-base p-4 rounded-2xl">
    <h4 class="text-imperium-fg-strong font-semibold text-base">Basic Information section</h4>

    <form class="flex flex-col grow mt-4">
      <FormInput
        v-model="title"
        class="mb-4"
        placeholder="Enter web page title"
        :maxlength="255"
        @update:model-value="(value) => onFieldUpdate(value, 'title')"
      >
        <template #label> <span class="text-sm text-imperium-fg-base font-semibold"> Converter title </span> </template>
      </FormInput>

      <FormTextarea
        v-model="description"
        name="description"
        class="mb-2"
        :size="SIZES.MEDIUM"
        placeholder="Come up with description"
        min-height="112px"
        @update:model-value="(value) => onFieldUpdate(value, 'description')"
      >
        <template #label> <span class="text-sm text-imperium-fg-base font-semibold">Description</span> </template>
      </FormTextarea>
      <div class="flex gap-2">
        <FormSelect
          v-model="cryptoSymbol"
          name="cryptoSymbol"
          class="mb-2 w-full"
          placeholder="Select coin"
          :size="SIZES.MEDIUM"
          :values="cryptoCurrencies"
          :variant="VARIANTS.MUTED"
          @update:model-value="(value) => onFieldUpdate(value, 'cryptoSymbol')"
        >
          <template #label>
            <span class="text-sm text-imperium-fg-base font-semibold">Cryptocurrency</span>
          </template>
          <template #dropdownItem="{ item }">
            <div class="w-full py-2 ms-2 text-sm truncate font-medium text-gray-900 rounded dark:text-gray-300">
              {{ item.label }}
              <span class="text-imperium-fg-muted text-sm ml-2">{{ item.additionalInfo?.name }}</span>
            </div>
          </template>
        </FormSelect>

        <FormSelect
          v-model="fiatSymbol"
          name="fiatSymbol"
          class="mb-2 w-full"
          placeholder="Select fiat"
          :size="SIZES.MEDIUM"
          :values="fiatCurrencies"
          :variant="VARIANTS.MUTED"
          @update:model-value="(value) => onFieldUpdate(value, 'fiatSymbol')"
        >
          <template #label>
            <span class="text-sm text-imperium-fg-base font-semibold">Fiat Currency</span>
          </template>
          <template #dropdownItem="{ item }">
            <div class="w-full py-2 ms-2 text-sm truncate font-medium text-gray-900 rounded dark:text-gray-300">
              {{ item.label }}
              <span class="text-imperium-fg-muted text-sm ml-2">{{ item.additionalInfo?.name }}</span>
            </div>
          </template>
        </FormSelect>
      </div>
    </form>
  </div>
</template>
<script lang="ts" setup>
import { useConverterPage } from '@/features/Converter/stores/converter.store';
import FormInput from '@/components/FormInput.vue';
import { computed, onMounted, ref } from 'vue';
import { SIZES, VARIANTS } from '@/types';
import FormTextarea from '@/components/FormTextarea.vue';
import { useConverterCurrencies } from '@/features/Converter/stores/currencies.store';
import FormSelect from '@/components/FormSelect.vue';
import type { Currency } from '@/features/Converter/types/currencies';

const props = defineProps<{
  converterId?: number | null;
}>();

const converterStore = useConverterPage();
const currenciesStore = useConverterCurrencies();

const title = ref<string>(converterStore.state.converter.title ?? '');
const cryptoSymbol = ref<string>(converterStore.state.converter.cryptoSymbol ?? '');
const fiatSymbol = ref<string>(converterStore.state.converter.fiatSymbol ?? '');
const description = ref<string>(converterStore.state.converter.description ?? '');

const cryptoCurrencies = computed(() => {
  if (currenciesStore.state.cryptoCurrencies) {
    return currenciesStore.state.cryptoCurrencies.map((item: Currency) => ({
      id: item.symbol,
      label: item.symbol,
      additionalInfo: {
        name: item.name,
      },
    }));
  }
});
const fiatCurrencies = computed(() => {
  if (currenciesStore.state.fiatCurrencies) {
    return currenciesStore.state.fiatCurrencies.map((item: Currency) => ({
      id: item.symbol,
      label: item.symbol,
      additionalInfo: {
        name: item.name,
      },
    }));
  }
});

const onFieldUpdate = (val: string | boolean, field: string) => {
  converterStore.onFieldUpdate(val, field, props.converterId);
  converterStore.updateConverterData({ [field]: val });
};

onMounted(() => {
  currenciesStore.fetchCurrencies();
});
</script>
