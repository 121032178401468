import { computed } from 'vue';
import type { LinkEntityWithNestedLinks } from '@/types';
import { useUserPermissions } from '@/stores/user.store';
import {
  Articles,
  ChartPie,
  Media,
  RequestIcon,
  QuestionSign,
  Slack,
  Users,
  Lock,
  Label,
  Graph,
} from '@/features/Sidebar/config/icons';

const getValidatedRoutesByPermission = (links: LinkEntityWithNestedLinks[]): LinkEntityWithNestedLinks[] => {
  return links.filter((link): link is LinkEntityWithNestedLinks => link?.accessGranted !== false);
};

export function useSidebarRoutes() {
  const { mediaPageView, usersPageView, converterPageView, categoryPageView } = useUserPermissions();

  const SIDEBAR_LINKS: LinkEntityWithNestedLinks[] = [
    {
      to: '/',
      name: 'dashboard',
      meta: { icon: ChartPie },
    },
    {
      to: '/articles',
      name: 'articles',
      meta: { icon: Articles },
    },
    {
      to: '/media',
      name: 'media',
      accessGranted: mediaPageView,
      meta: { icon: Media },
    },
    {
      to: '/users',
      name: 'users',
      accessGranted: usersPageView,
      meta: { icon: Users },
    },
    {
      name: 'roles-permissions',
      accessGranted: usersPageView,
      meta: { icon: Lock },
      children: [
        { to: '/roles', name: 'roles', accessGranted: usersPageView, meta: {} },
        { to: '/permissions', name: 'permissions', accessGranted: usersPageView, meta: {} },
      ],
    },
    {
      name: 'categories-labels',
      accessGranted: categoryPageView,
      meta: { icon: Label },
      children: [
        {
          to: '/categories',
          name: 'categories',
          accessGranted: categoryPageView,
          meta: {},
        },
      ],
    },
    {
      name: 'price-indexes',
      meta: {
        icon: Graph,
      },
      accessGranted: converterPageView,
      children: [{ to: '/converters', name: 'converter', accessGranted: converterPageView, meta: {} }],
    },
  ];

  const SIDEBAR_LINKS_BOTTOM: LinkEntityWithNestedLinks[] = [
    {
      name: 'support',
      to: '',
      meta: { icon: QuestionSign, wrapperClass: 'mt-auto' },
      children: [
        {
          to: 'https://d41.ctgroup.io/',
          name: 'support-request',
          meta: { icon: RequestIcon, wrapperClass: 'mt-auto' },
          target: '_blank',
        },
        {
          to: 'https://ctmain.slack.com/archives/CTB93DQLU',
          name: 'support-slack',
          meta: { icon: Slack, wrapperClass: 'mt-auto' },
          target: '_blank',
        },
      ],
    },
  ];

  const routes = computed<LinkEntityWithNestedLinks[]>(() =>
    getValidatedRoutesByPermission(SIDEBAR_LINKS).map((sidebarLink) => ({
      ...sidebarLink,
    })),
  );

  const routesBottom = computed<LinkEntityWithNestedLinks[]>(() =>
    getValidatedRoutesByPermission(SIDEBAR_LINKS_BOTTOM).map((sidebarLink) => ({
      ...sidebarLink,
    })),
  );

  return { routes, routesBottom };
}
