import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M18.333 10a8.333 8.333 0 1 1-16.667 0 8.333 8.333 0 0 1 16.667 0m-3.786-2.927a.417.417 0 0 0-.125-.576l-.701-.45a.417.417 0 0 0-.576.124l-4.17 6.486-2.333-2.722a.417.417 0 0 0-.588-.046l-.633.543a.417.417 0 0 0-.045.587l2.868 3.346c.46.537 1.307.479 1.69-.116z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }