import { truncate } from 'lodash';
import noRussian, { hasCyrillic } from '@/helpers/no-russian';

type Params = {
  value: string;
  maxLength: number | null;
};

const sanitizeAndTruncate = ({ value, maxLength = null }: Params): string => {
  let sanitizedValue = hasCyrillic(value) && value.length > 1 ? noRussian(value) : value;

  if (maxLength) {
    sanitizedValue = truncate(sanitizedValue, { length: maxLength, omission: null });
  }

  return sanitizedValue;
};

export default sanitizeAndTruncate;
