import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M20.5 4a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-15a.5.5 0 0 1 .5-.5zm-9.603 1.646a.5.5 0 0 1 .708 0L18 12l-6.395 6.354a.5.5 0 0 1-.708 0l-.778-.774a.496.496 0 0 1 0-.704l3.172-3.159 1.459-.817-1.73.095H3.5a.5.5 0 0 1-.5-.497v-.996c0-.275.224-.497.5-.497h9.52l1.73.095-1.46-.817-3.171-3.16a.496.496 0 0 1 0-.703z"
    }, null, -1)
  ])))
}
export default { render: render }