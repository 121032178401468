import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M18.247 7.527a.5.5 0 0 0-.15-.691l-.841-.54a.5.5 0 0 0-.691.15l-5.891 9.062-3.362-3.845a.5.5 0 0 0-.705-.054l-.759.65a.5.5 0 0 0-.054.706l4.002 4.593a1.267 1.267 0 0 0 2.028-.14z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }