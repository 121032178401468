import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M3.362 10.11c0 .926-.756 1.681-1.681 1.681S0 11.036 0 10.111C0 9.185.756 8.43 1.68 8.43h1.682zm.846 0c0-.925.756-1.68 1.68-1.68.926 0 1.682.755 1.682 1.68v4.209c0 .925-.756 1.68-1.681 1.68s-1.681-.755-1.681-1.68zm1.681-6.748c-.925 0-1.681-.756-1.681-1.681S4.964 0 5.888 0C6.815 0 7.57.756 7.57 1.68v1.682zm.001.845c.924 0 1.68.756 1.68 1.68 0 .926-.756 1.682-1.68 1.682H1.68C.757 7.569 0 6.813 0 5.888s.756-1.681 1.68-1.681zm6.748 1.681c0-.925.755-1.681 1.68-1.681.926 0 1.681.756 1.681 1.68 0 .926-.755 1.682-1.68 1.682h-1.681zm-.847.002c0 .924-.755 1.68-1.68 1.68A1.685 1.685 0 0 1 8.43 5.89V1.68C8.43.757 9.185 0 10.11 0c.926 0 1.681.756 1.681 1.68zm-1.681 6.747c.926 0 1.681.755 1.681 1.68 0 .926-.755 1.681-1.68 1.681a1.684 1.684 0 0 1-1.681-1.68v-1.681zm0-.846c-.925 0-1.68-.755-1.68-1.68 0-.926.755-1.681 1.68-1.681h4.209c.925 0 1.68.755 1.68 1.68 0 .926-.755 1.681-1.68 1.681z"
    }, null, -1)
  ])))
}
export default { render: render }