<script setup lang="ts">
import CollapsableSection from '@/components/Collapsable/CollapsableSection.vue';
import FormInput from '@/components/FormInput.vue';
import FormTextarea from '@/components/FormTextarea.vue';
import { computed, ref, watchEffect } from 'vue';
import { useConverterPage } from '@/features/Converter/stores/converter.store';

const props = defineProps<{
  converterId: number;
}>();

const converterStore = useConverterPage();

const title = computed(() => converterStore.state.converter.seoTitle);
const description = computed(() => converterStore.state.converter.seoDescription);

const sectionVisible = ref<boolean>(!!(title.value || description.value) ?? false);
const updateTitle = (value: string) => {
  onFieldUpdate(value, 'seoTitle');
  converterStore.updateConverterData({ seoTitle: value });
};
const updateDescription = (value: string) => {
  onFieldUpdate(value, 'seoDescription');
  converterStore.updateConverterData({ seoDescription: value });
};

const onFieldUpdate = (val: string | boolean | number[], field: string) => {
  converterStore.onFieldUpdate(val, field, props.converterId);
};
</script>

<template>
  <CollapsableSection
    title="SEO"
    :content-exists="sectionVisible"
    button-label="SEO settings"
    subtitle="Improve search engine rankings and attract more targeted traffic."
    @expand="sectionVisible = true"
  >
    <template #content>
      <div class="flex flex-col gap-4">
        <FormInput
          :model-value="title"
          placeholder="Come up with a SEO-title"
          :maxlength="255"
          @update:model-value="(value) => updateTitle(value)"
        >
          <template #label>
            <span class="text-sm text-imperium-fg-base font-semibold"> SEO-title </span>
          </template>
          <template #help>A brief entry that represents the page's content and affects its search ranking.</template>
        </FormInput>

        <FormTextarea
          name=""
          :model-value="description"
          min-height="148px"
          class="grow shrink basis-0.5"
          placeholder="Come up with a SEO description"
          prevent-height-restriction
          :max="255"
          @update:model-value="(value) => updateDescription(value)"
        >
          <template #label>
            <span class="text-sm text-imperium-fg-base font-semibold"> SEO description </span>
          </template>
          <template #help>Include relevant information about the content.</template>
        </FormTextarea>
      </div>
    </template>
  </CollapsableSection>
</template>
