<template>
  <div class="relative">
    <div
      :class="{
        'flex space-x-2 mb-0': props.labelPosition === INPUT_LABEL_POSITION.LEFT,
      }"
    >
      <label
        v-if="isLabel && props.visualType !== INPUT_TYPE.PLAIN"
        class="w-fit"
        :class="
          labelClass({
            size: props.size,
            labelPosition: props.labelPosition,
            isDisabled: props.isDisabled,
            isErrored: props.isErrored,
            isSuccess: props.isSuccess,
          })
        "
        :style="{ 'min-width': props.labelWidth ? props.labelWidth : 'auto' }"
        :for="`input-${name}`"
      >
        <slot name="label" />
        <template v-if="props.isRequired">*</template>
      </label>
      <div
        :class="{
          'w-full': props.labelPosition === INPUT_LABEL_POSITION.LEFT,
        }"
        class="relative"
      >
        <div class="relative">
          <div
            v-if="isPrefix"
            class="absolute z-10 inset-y-0 flex items-center pointer-events-none"
            :class="{
              'left-3': props.size === SIZES.SMALL,
              'left-2.5': props.size === SIZES.MEDIUM,
            }"
          >
            <slot name="prefix" />
          </div>

          <input
            :id="`input-${name}`"
            ref="inputElement"
            v-model="inputValue"
            :data-testid="`input-${name}`"
            autocomplete="off"
            v-bind="props.attrs"
            :name="props.name"
            :disabled="isDisabled"
            :placeholder="props.placeholder"
            :type="props.type"
            :class="
              inputClass({
                size: props.size,
                rounded: props.rounded,
                visualType: props.visualType,
                isErrored: props.isErrored,
                isSuccess: props.isSuccess,
                isPrefix: isPrefix,
                isSuffix: isSuffix,
              })
            "
            :readonly="props.isReadonly"
            :required="props.isRequired"
            :maxlength="props.maxlength"
            @wheel="onWheel"
            @blur="onBlur"
            @click="emits('click')"
            @focus="onFocus"
            @input.prevent="onInputChange"
            @paste="onPaste"
          />
          <div
            class="absolute w-fit h-full z-10 inset-y-0 flex items-center"
            :class="{
              'right-3': props.size === SIZES.SMALL,
              'right-2.5': props.size === SIZES.MEDIUM,
            }"
          >
            <slot name="suffix" />
          </div>
        </div>
        <div
          v-if="isHelp"
          :data-testid="`input-${props.name}-help`"
          :class="
            labelClass({
              size: props.size,
              isInfo: true,
            })
          "
          class="mt-1"
        >
          <slot name="help" />
        </div>

        <div
          v-if="props.isErrored && isError"
          :data-testid="`input-${props.name}-error`"
          :class="
            labelClass({
              size: props.size,
              isErrored: true,
            })
          "
          class="mt-1"
        >
          <slot name="error" />
        </div>

        <div
          v-if="isSuccess"
          :data-testid="`input-${props.name}-success`"
          :class="
            labelClass({
              size: props.size,
              isSuccess: true,
            })
          "
          class="mt-1"
        >
          <slot name="success" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { INPUT_LABEL_POSITION, INPUT_ROUNDED, INPUT_TYPE, SIZES } from '@/types';
import { ref, useSlots, computed, watchEffect } from 'vue';
import { tv } from 'tailwind-variants';
import noRussian, { hasCyrillic } from '@/helpers/no-russian';
import { useFocus } from '@vueuse/core';

// add prefix and suffix slots

const props = withDefaults(
  defineProps<{
    modelValue: string;

    size?: SIZES;
    isDisabled?: boolean;
    rounded?: INPUT_ROUNDED;
    labelPosition?: INPUT_LABEL_POSITION;
    labelWidth?: string;
    isRequired?: boolean;
    isErrored?: boolean;
    isSuccess?: boolean;
    visualType?: INPUT_TYPE;
    // Use it instead you don't need spaces in input but need point instead
    pointSeparator?: boolean;
    maxlength?: number;

    isReadonly?: boolean;
    placeholder?: string;
    name?: string;
    type?: string;

    attrs?: Record<string, unknown>;
  }>(),
  {
    size: SIZES.MEDIUM,
    isDisabled: false,
    rounded: INPUT_ROUNDED.DEFAULT,
    labelPosition: INPUT_LABEL_POSITION.TOP,
    isErrored: false,
    isRequired: false,
    isSuccess: false,
    visualType: INPUT_TYPE.PRIMARY,

    isReadonly: false,
    type: 'text',
    attrs: () => ({}),
  },
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: string): void;
  (event: 'click'): void;
  (event: 'focus'): void;
  (event: 'blur'): void;
  (event: 'paste', e: ClipboardEvent): void;
}>();

const slots = useSlots();

const inputValue = ref<string>(props.modelValue);

const labelClass = tv({
  base: 'inline-block block input-meta-text-default',
  variants: {
    size: {
      [SIZES.SMALL]: 'input-meta-text-sm',
      [SIZES.MEDIUM]: 'input-meta-text-md',
    },
    isErrored: {
      true: 'input-meta-text-errored',
    },
    isSuccess: {
      true: 'input-meta-text-success',
    },
    isDisabled: {
      true: 'input-meta-text-disabled',
    },
    isInfo: {
      true: 'input-meta-helper-text',
    },
    labelPosition: {
      [INPUT_LABEL_POSITION.TOP]: 'mb-2',
      [INPUT_LABEL_POSITION.LEFT]: 'mt-2 text-right',
    },
  },
});

const inputClass = tv({
  base: 'block w-full text-ellipsis overscroll-none relative z-1',
  variants: {
    size: {
      [SIZES.SMALL]: 'input-sm',
      [SIZES.MEDIUM]: 'input-md',
    },
    rounded: {
      [INPUT_ROUNDED.DEFAULT]: 'input-rounded-default',
      [INPUT_ROUNDED.FULL]: 'input-rounded-full',
    },
    visualType: {
      [INPUT_TYPE.PRIMARY]: 'input-primary',
      [INPUT_TYPE.PLAIN]: 'input-plain',
      // For multiselect
      [INPUT_TYPE.INVISIBLE]: 'input-invisible',
    },
    isPrefix: {
      true: 'pl-10',
    },
  },

  compoundVariants: [
    {
      isSuffix: true,
      size: SIZES.MEDIUM,
      class: 'pr-10',
    },
    {
      isSuffix: true,
      size: SIZES.SMALL,
      class: 'pr-7',
    },
    {
      isErrored: true,
      visualType: INPUT_TYPE.PRIMARY,
      class: 'input-primary-errored',
    },
    {
      isSuccess: true,
      visualType: INPUT_TYPE.PRIMARY,
      class: 'input-primary-success',
    },
    {
      isErrored: true,
      visualType: INPUT_TYPE.PLAIN,
      class: 'input-plain-errored',
    },
    {
      isSuccess: true,
      visualType: INPUT_TYPE.PLAIN,
      class: 'input-plain-success',
    },
  ],
});

const inputElement = ref<HTMLInputElement | null>(null);

const { focused } = useFocus(inputElement);

const isLabel = computed(() => !!slots.label);
const isHelp = computed(() => !!slots.help);
const isError = computed(() => !!slots.error);
const isPrefix = computed(() => !!slots.prefix);
const isSuffix = computed(() => !!slots.suffix);

const onInputChange = (e: Event) => {
  if (hasCyrillic((e.target as HTMLInputElement).value) && (e.target as HTMLInputElement).value.length > 1) {
    inputValue.value = noRussian((e.target as HTMLInputElement).value);
  } else {
    const validValue = noRussian((e.target as HTMLInputElement).value);
    emits('update:modelValue', props.pointSeparator ? validValue.replace(' ', '.') : validValue);
    inputValue.value = props.pointSeparator ? validValue.replace(' ', '.') : validValue;
  }
};

watchEffect(() => {
  if (!focused.value) {
    inputValue.value = props.modelValue;
  }
});

const onFocus = () => {
  emits('focus');
};
const onBlur = () => {
  emits('blur');
};

const onPaste = (e: ClipboardEvent) => {
  if (!e.clipboardData) return;

  emits('paste', e);
};

const onWheel = (event: WheelEvent): void => {
  if (!focused.value && event.deltaX !== 0) event.preventDefault();
};

defineExpose({
  focus: () => inputElement.value?.focus(),
  click: () => inputElement.value?.click(),
  caretToEnd: () => {
    inputElement.value?.focus();
    setTimeout(() => {
      const valLength = inputElement?.value?.value?.length || 0;
      inputElement.value?.setSelectionRange(valLength, valLength);
    }, 0);
  },
});
</script>
