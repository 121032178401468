import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "8",
  height: "6",
  fill: "none",
  viewBox: "0 0 8 6"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M7.194 1.194a.5.5 0 0 1 0 .707L4 5.096.805 1.9a.5.5 0 0 1 0-.707l.39-.389a.5.5 0 0 1 .706 0L4 2.904 6.098.805a.5.5 0 0 1 .707 0z"
    }, null, -1)
  ])))
}
export default { render: render }