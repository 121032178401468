import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16m10-8c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m12.034 13.385 3.196 3.195a.5.5 0 0 0 .707 0l.71-.71a.5.5 0 0 0 0-.707l-3.197-3.194 3.134-3.132a.5.5 0 0 0 0-.707l-.71-.71a.5.5 0 0 0-.707 0l-3.133 3.132-3.201-3.199a.5.5 0 0 0-.707 0l-.71.71a.5.5 0 0 0 0 .707l3.2 3.199-3.262 3.261a.5.5 0 0 0 0 .707l.71.71a.5.5 0 0 0 .706 0z"
    }, null, -1)
  ])))
}
export default { render: render }