import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12 6c-2.6 0-5.027.809-6.818 1.895-.896.543-1.664 1.174-2.219 1.84C2.424 10.382 2 11.166 2 12c0 .805.373 1.58.886 2.239.523.673 1.256 1.31 2.134 1.859C6.778 17.197 9.212 18 12 18s5.222-.803 6.98-1.902c.878-.549 1.61-1.186 2.134-1.86.513-.658.886-1.433.886-2.238 0-.834-.424-1.618-.963-2.265-.555-.666-1.323-1.297-2.219-1.84A13.4 13.4 0 0 0 12 6m-8 6c0-.166.101-.507.5-.985.382-.459.964-.953 1.718-1.41A11.4 11.4 0 0 1 12 8c2.2 0 4.273.691 5.782 1.605.754.457 1.336.951 1.718 1.41.399.478.5.819.5.985 0 .195-.102.545-.464 1.011-.352.452-.894.94-1.616 1.391C16.478 15.303 14.412 16 12 16s-4.478-.697-5.92-1.598c-.722-.451-1.264-.94-1.616-1.39C4.102 12.544 4 12.194 4 12",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }